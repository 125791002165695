import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  CardFooter,
  Button,
  Table,
  Badge,
TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import {
  fetchStudentRequestTutor,
  fetchStudentRequestTutorHistory,
  approveStudentRequestTutor,
  disapproveStudentRequestTutor,
  updateModalUploadTutorVideoFile,
  editTutorRequest,
} from "redux/ManageTutorRequests/ManageTutorRequestsActions";
import UploadTutorVideoFileModal from "components/Modal/UploadTutorVideoFileModal";
import ManageStudentRequest from "components/Pages/ManageStudentRequest";
import ManageStudentRequestHistory from "components/Pages/ManageStudentRequestHistory";
import classnames from "classnames";
const ManageTutorRequests = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchStudentRequestTutor({ handle: props.userDetails.handle }));
    // dispatch(
    //   fetchStudentRequestTutorHistory({ handle: props.userDetails.handle })
    // );
  }, []);

  
  const [requestTutorHistory, setRequestTutorHistory] = useState([]);


  useEffect(() => {
    if (props.fetchStudentRequestTutorHistory.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }

    if (
      props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory
        .length
    ) {
      setRequestTutorHistory(
        props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory[0]
      );
    }
  }, [props.fetchStudentRequestTutorHistory]);

  const handleApproveRequestOnClick = (
    e,
    docId,
    coachTutorAvailabilityDocId,
    mtr
  ) => {
    e.preventDefault();

    dispatch(
      approveStudentRequestTutor({
        docId: docId,
        coachTutorAvailabilityDocId: coachTutorAvailabilityDocId,
        email: props.userDetails.email,
        mtr: mtr,
      })
    );
  };

  const handleDisapproveRequestOnClick = (
    e,
    docId,
    coachTutorAvailabilityDocId,
    studentHandle,
    mtr
  ) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disapprove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          disapproveStudentRequestTutor({
            docId: docId,
            coachTutorAvailabilityDocId: coachTutorAvailabilityDocId,
            studentHandle: studentHandle,
            mtr: mtr,
          })
        );
      }
    });
  };

  useEffect(() => {
    if (props.approveStudentRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (props.approveStudentRequestTutor.approvestudentrequesttutor.length) {
        Swal.fire({
          title: "Success!",
          text: "Successfully approved request!",
          icon: "success",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      } else if (
        typeof props.approveStudentRequestTutor.error !== "undefined"
      ) {
        Swal.fire({
          title: "Failed!",
          text: "Failed to approve request",
          icon: "error",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      }
    }
  }, [props.approveStudentRequestTutor]);

  useEffect(() => {
    if (props.disapproveStudentRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (
        props.disapproveStudentRequestTutor.disapprovestudentrequesttutor.length
      ) {
        Swal.fire({
          title: "Success!",
          text: "Successfully disapproved request!",
          icon: "success",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      } else if (
        typeof props.disapproveStudentRequestTutor.error !== "undefined"
      ) {
        Swal.fire({
          title: "Failed!",
          text: "Failed to disapprove request",
          icon: "error",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      }
    }
  }, [props.disapproveStudentRequestTutor]);

  const onClickEditTutorRequest = (e, meetingId) => {
    console.log(meetingId);
    e.preventDefault();
    dispatch(
      editTutorRequest({
        status: true,
        meetingId: meetingId,
      })
    );

    dispatch(
      updateModalUploadTutorVideoFile(
        !props.updateModalUploadTutorVideoFileStatus.status
      )
    );
  };

    const mainopt = ["Student Requests", "History", ];
    const [mainActiveTab, setMainActiveTab] = useState("Student Requests");

    const toggleMain = (tab) => {
        if (mainActiveTab !== tab) setMainActiveTab(tab);
    };

  return (
    <>
      <Header></Header>

      <Container className="mt--7" fluid>
        <Row className="mt-4">
            <Col>
                <Card className="shadow">
                <CardHeader className="border-1">
                    <div className="float-left">
                    <h5 className="h3 mb-0">Manage Student Tutor Requests</h5>
                    </div>
                </CardHeader>
                <CardBody>
                        <Row>
                  <Col className="mt-3 mb-4 d-flex justify-content-center">
                    <Nav fill pills>
                      {mainopt.length > 0
                        ? mainopt.map((c, i) => {
                            return (
                              <NavItem className="ml-1" key={i}>
                                <NavLink
                                  key={c}
                                  className={classnames({
                                    active: mainActiveTab === c,
                                  })}
                                  onClick={() => {
                                    toggleMain(c);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {c}
                                </NavLink>
                              </NavItem>
                            );
                          })
                        : ""}
                    </Nav>
                  </Col>
                </Row>
                <Row>
                    <Col lg="12" className="mt-3 p-2">
                        <TabContent activeTab={mainActiveTab}>
                        {
                        mainopt.map((mc, i) => {
                            return (
                                <TabPane tabId={mc} key={i}>
                                     {mc == "Student Requests" ? (
                                        <Row>
                                            <Col>
                                                <ManageStudentRequest  />
                                            </Col>
                                        </Row>
                                     ) :  <Row>
                                            <Col>
                                                <ManageStudentRequestHistory  />
                                            </Col>
                                        </Row>}


                                </TabPane>
                            )
                        })
                    }
                        </TabContent>
                    </Col>
                </Row>
                </CardBody>

                
                </Card>

            
            </Col>
        </Row>
      </Container>
      {/* <UploadTutorVideoFileModal
        modalUploadTutorVideoFile={modalUploadTutorVideoFile}
        toggleUploadTutorVideoFile={toggleUploadTutorVideoFile}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchStudentRequestTutor: state.fetchStudentRequestTutor,
    approveStudentRequestTutor: state.approveStudentRequestTutor,
    disapproveStudentRequestTutor: state.disapproveStudentRequestTutor,
    fetchStudentRequestTutorHistory: state.fetchStudentRequestTutorHistory,
    updateModalUploadTutorVideoFileStatus:
      state.updateModalUploadTutorVideoFileStatus,
    editTutorRequest: state.editTutorRequest,
  };
};

export default connect(mapStateToProps)(ManageTutorRequests);
