import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  Col,
  FormGroup,
  CardBody,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CustomInput,
  Alert,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  fetchAllUsers,
  fetchUserDetails,
  
} from "redux/ManageUsers/ManageUsersAction";
import { fetchLtpItem } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";
import ManageUsersModal from "components/Modal/ManageUsersModal";
import {fetchUniversities}  from "redux/Users/UsersActions";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import { customDropdown } from "util/reactCustomStyles";
import classnames from "classnames";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { app, db } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";


const ManageStudents = (props) => {
  	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
  	const [filter, setFilter] = useState([]);

	const [checkUsers, setCheckUsers] = useState([]);
	const [school, setSchool] = useState('');
	const [universities, setUniversities] = useState([])

	const subscriptionFilter = [
		{ value: "", label: "ALL" },
		{ value: "Free Account", label: "Free Account" },
		{ value: "PRO Member", label: "PRO Member" },
		{ value: "Ultimate Advising Student", label: "Ultimate Advising Student" },
	];

	useEffect(() => {
        setData(props.item)
        setFilter(props.item) 
		setLoading(false)
    },[props.item])
	
	const [proMembers, setProMembers] = useState([])
	useEffect(() => {
       dispatch(fetchLtpItem())
    }, [])
	
    useEffect(() => {
        if (props.fetchLtpItem.ltpitems.length) {
            setProMembers( props.fetchLtpItem.ltpitems);
        }
    }, [props.fetchLtpItem]);
    


	const handleViewUser = (e, userDetails) => {
		e.preventDefault();
		dispatch(getUserData({ user: userDetails }));
	};

	const [subscription, setSubscription] = useState("");
	function handleSubscriptionFilterOnChange(e) {
		setSubscription(e.value);
	}

   	const [getByCoach, setGetByCoach] = useState("");
  	const  handleGetByCoach = (e) => {
    	setGetByCoach(e.value);
  	}

	useEffect(() => {
		dispatch(
			fetchAllUsers({
				route: "",
				coachFilter: getByCoach,
			})
		);
		// dispatch(fetchCoaches({}));
	}, [getByCoach]);

  useEffect(() => {
    dispatch(
      fetchAllUsers({
        route: "",
        subscriptionFilter: subscription,
      })
    );
    dispatch(fetchCoaches({}));
  }, [subscription]);

//   const handleAssignedCoach = (selectedOption) => {
//     setAssignedCoach(selectedOption.value);
//     setValue("assignedCoach", selectedOption.value);
//   };

  const resetFilter = () => {
	dispatch(
      fetchAllUsers({
        route: "",
        subscriptionFilter: "",
      })
    );

	setSubscription("")
	setGetByCoach("")

  }

	const [coachOption, setCoachOption] = useState([]);
  	const [assignedcoach, setAssignedCoach] = useState("");

  useEffect(() => {
    if (props.fetchCoaches.fetchcoaches.length) {
		const coachesOption = props.fetchCoaches.fetchcoaches[0];
		coachesOption.unshift({ value: "", label: "Select Coach" });
	  	setCoachOption(coachesOption);
    }
  }, [props.fetchCoaches]);

	const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }

	const columns = [
		{
			name: "Name",
			// sortable: true,
			// wrap: true,
			width: '250px',
			selector: (row) => {
				return(
					<Media className="align-items-center">
						{
							typeof row.profilePicture != "undefined" ? (
							<div className="card-profile-div-sm card-profile-div-md">
								<div className="card-profile-image">
									<div
									className="profile-picture-sm profile-picture-md"
									style={{
										width: "38px",
										height: "38px",
										backgroundImage:
											'url("https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/' +
											row.profilePicture +
											'")',
									}}
									>
										
									</div>
								</div>
							</div>
							) : 
							(
							<div className="rounded-circle profile-letter-all-users mr-2" 
							style={{
							width: "30px",
							height: "30px",
								fontSize: "16px"
								}}>
								{row.name !== undefined ? row.name.trim()[0].toUpperCase() : "1" }
							</div>
							)
						}
						<span className="mb-0 text-sm">{row.name}</span>
					</Media>
				)
			}
		},
		{
			name: "Email",
			sortable: true,
			selector: (row) => {
				return row.email
			}
		},
		{
			name: "Subscription",
			center: "true",
			sortable: true,
			selector: (row) =>   {
				return (
					row.ultimateAdvisingStudent == "true" || row.ultimateAdvisingStudent === true
					? "Ultimate Advising Student"
					: typeof row.subsId != "undefined"
					? "PRO Member"
					: "Free Account"
								
				)
			},
			
		},
		{
			name: "Coach",
			sortable: true,
			center: "true",
			selector: (row) => {
				return row.mycoach ?  row.mycoach.name : "N/A";
			}
		},
		{
			name: "Matriculated",
			sortable: true,
			center: "true",
			selector: (row) => {
				return  row.matriculated ? row.matriculated : 'N/A'
			}
		},
		{
			name: "Entry Date",
			sortable: true,
			selector: (row) => {
				return convertDate(row.createdAt);
			}
		},
		{
			name: "Action",
			cell: (row) => (
                <ManageUsersModal item={row}/>
			),
			center: "true"
		},
	];

	const tableHeaderstyle = {
		headCells: {
		style: {
			fontWeight: "bold",
			fontSize: "14px",
			// backgroundColor: "#ccc",
		},
		},
	};

	useEffect(() => {
		const result = data.filter((item) => {
			if(item){
				return (
					modifiedSearch(item.email).match(search.toLocaleLowerCase()) || 
					modifiedSearch(item.name).match(search.toLocaleLowerCase()) ||
					modifiedSearch(item.matriculated).match(search.toLocaleLowerCase()) ||
					modifiedSearch(item.mycoach).match(search.toLocaleLowerCase()) ||
					convertDate(item.createdAt).toLowerCase().match(search.toLocaleLowerCase()) 
				)
			}
		});
		
    	setFilter(result);
		
	}, [search]);

	const modifiedSearch = (item) =>  {
		if(item !== undefined){
			if(item.name !== undefined){
				return  item.name.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}


  return (
		<>
            <Row className="mb-2">
                <Col md="4" className="mb-2">
                    {/* <label
                        className="form-control-label"
                        htmlFor="filterBySubs"
                    >
                        Filter By Subscription
                    </label> */}
                    <Select
                    id="filterBySubs"
                    placeholder="Select Subscription"
                    options={subscriptionFilter}
                    styles={customDropdownWithBorder}
                    onChange={(e) => handleSubscriptionFilterOnChange(e)}
                    menuPortalTarget={document.body}
                    />
                </Col>
                <Col md="4">
                    {/* <label
                        className="form-control-label"
                        htmlFor="filterByCoach"
                    >
                        Filter By Coach
                    </label> */}
                    <FormGroup>
                        <Select
                        placeholder="Filter By Coach"
                        id="filterByCoach"
                        options={coachOption}
                        styles={customDropdown }
                        onChange={(e) => handleGetByCoach(e)}
                        menuPortalTarget={document.body}
                        formatOptionLabel={(v) => (
                            <div className="d-flex ">
                                {v.value ? (
                                v.image ? (
                                    <img
                                    className="rounded-circle  mr-2 "
                                    style={{ width: "25px", height: "25px" }}
                                    src={
                                        "https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/" +
                                        v.image
                                    }
                                    alt={v.label}
                                    />
                                ) : (
                                    <div
                                    className="rounded-circle profile-letter-all-users mr-2 "
                                    style={{ width: "25px", height: "25px", fontSize: '12px'}}
                                    >
                                    {v.label ? v.label.trim()[0] : ""}
                                    </div>
                                )
                                ) : (
                                ""
                                )}

                                <span>{v.label}</span>
                            </div>
                        )}
                        
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <label className="form-control-label"></label>
                    <Button onClick={resetFilter}>Reset Filter</Button>
                </Col>
            </Row>
        
            <Row>
                <Col lg="12">
                    <Card className="shadow">
                        <CardBody>
							<p className="float-right" >Total Count: {data.length}  </p>
                            <div className="float-right mb-2">
                             
                                {/* <Button
                                color="primary"
                                onClick={(e) => updateMultipleUsers(e)}
                                >
                                Update Multiple Users
                                </Button> */}
                            </div>
                            <CardTitle tag="h4" >MANAGE STUDENT USERS </CardTitle>
                            <CardSubtitle tag="h5" className="mb-2 text-muted">List of student users </CardSubtitle>
                            { 
                                loading ? 
                                    <div className="m-5">
                                        <Alert color="secondary" className="text-center">
                                            Collecting data.. Please wait while loading {" "}
                                            <Spinner size="sm" color="primary" />{" "}
                                        </Alert>
                                    </div>
                                : data.length ? 
                                        <DataTable
                                            progressPending={loading}
                                            customStyles={tableHeaderstyle}
                                            columns={columns}
                                            data={filter}
                                            pagination
                                            wrap
                                            // selectableRows
                                            // fixedHeader
                                            selectableRowsHighlight
                                            highlightOnHover
                                            subHeader
                                            subHeaderComponent={
                                            <input
                                                type="text"
                                                className="w-50 form-control"
                                                placeholder="Search a keyword..."
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            }
                                            subHeaderAlign="right"
                                        />
                                : 
                                <Alert color="primary">No data available .</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
               
		</>
	);
};

const mapStateToProps = (state) => {
  return {
    fetchAllUsers: state.fetchAllUsers,
    fetchUserDetails: state.fetchUserDetails,
    fetchCoaches: state.fetchCoaches,
    fetchUniversities : state.fetchUniversities,
	fetchLtpItem: state.fetchLtpItem,
  };
};

export default connect(mapStateToProps)(ManageStudents);
