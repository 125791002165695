import React, {useState,useRef, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import  Select  from 'react-select';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Input,
    Image,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    FormGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";
import Swal from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';
import { app, db } from "../../firebase";
import { getDocs, getDoc, updateDoc, deleteDoc, setDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
import { updateCommunityProMemberPost, deleteCommunityProMemberPost } from 'redux/Community/ProMemberAction';

const ProMemberActionPost = (props) => {
    console.log(props)
   
    const { register, getValues, unregister, watch, handleSubmit, setValue, formState: { errors } } = useForm();
    let toastId = useRef(null);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [fileItemsLimit, setFileItemsLimit] = useState(10);
    const [imagePreview, setImagePreview] = useState([]);
    const [deletedFiles, setdeletedFiles] = useState([]);
    const [category, setCategory] = useState(props.item.category);

    const catoptions = [
    { value: 'General', label: 'General' },
    { value: 'Classes', label: 'Classes' },
    { value: 'MCAT', label: 'MCAT' }
    ]
    //Item value
    const [item, setItem] = useState(props.item)
    const [files, setFiles] = useState([])

    //Dropdown Behaviour 
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

   
    //Modal Behaviour 
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    //Post Input
    const [postContent, setpostContent] = useState(item.textContent);

    const handleChange = event => {
        setpostContent(event.target.value);
    };

    //On Click Edit Post
    const onClickEditPost = async (e, postid) => {
        const docRef = doc(db, "communityPosts", postid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let postitem = docSnap.data();
            postitem['postid'] = postid
            setItem(postitem)
        } else {
        // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
        setFiles(props.item.fileContent)
        setCategory(props.item.category)
        setImagePreview([])
        setdeletedFiles([])

        e.preventDefault();
        setBackdrop(true);
        setModal(true)
    
    }
    const onClickUpdatePost = (e, postid) => {
     
        const formData = new FormData();
        formData.append('postid', postid);
        formData.append('textContent', postContent);
        formData.append('existingFiles', JSON.stringify(files));
        formData.append('deletedFiles', JSON.stringify(deletedFiles));
        formData.append('category', category);
        if (imagePreview.length) {
            for (let i = 0; i < imagePreview.length; i++){
                formData.append(`files[${i}]`, imagePreview[i]);
            }
        }

        dispatch(updateCommunityProMemberPost(formData))
        setModal(!modal)
    }

    //On Click Delete Post
    const onClickDeletePost = (e, postid) => {
    
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33'
        }).then((result) => {
           
            if (result.isConfirmed) {
                dispatch(deleteCommunityProMemberPost({postid: postid}))
            }
        }) 
    }

    const handleFileChange = (e) => { 
        console.log(e.target.files.length + files.length )
        if (e.target.files && e.target.files.length > 0) {
            
            const maxVideoSizeInMB = 20;
            const maxImageSizeInMB = 10;
            const allowedImgTypes = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'];
            const allowedVideoTypes = ['video/mp4'];
            if (e.target.files.length + files.length > fileItemsLimit) {
                Swal.fire({
                    title: 'Upload limit!',
                    text: ' Files cannot be more than '+fileItemsLimit+' items',
                    icon: 'error',
                    }
                )

            } else {
                let pushItem = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    let fileSizeInMB = e.target.files[i].size / (1024 * 1024)
                    if (allowedVideoTypes.includes(e.target.files[i].type)) {
                        if (fileSizeInMB > maxVideoSizeInMB) {
                            Swal.fire({
                                title: 'Maximum limit!',
                                text: e.target.files[i].name + ' File size exceeds the maximum limit of '+maxVideoSizeInMB+'MB',
                                icon: 'error',
                                }
                            )
                            return;
                        } else {
                            pushItem.push(e.target.files[i])
                        }

                    } else if (allowedImgTypes.includes(e.target.files[i].type)) {
                        if (fileSizeInMB > maxImageSizeInMB) {
                                Swal.fire({
                                    title: 'Maximum limit!',
                                    text: e.target.files[i].name + ' File size exceeds the maximum limit of '+maxImageSizeInMB+'MB',
                                    icon: 'error',
                                }
                            )
                            return;
                        } else {
                            pushItem.push(e.target.files[i])
                        }    
                    } else {
                        Swal.fire({
                            title: 'Invalid file!',
                            text: ' Upoad a valid file ',
                            icon: 'error',
                            }
                        )
                        return;
                    }
                }
                setImagePreview(pushItem)
                
            }
        }  
    }
    const clickInputFile = (e) => {
        e.preventDefault()
        fileInputRef.current.click();
    }

    const removeFile = (i) => {
        const newPreview = imagePreview.filter((item, index) => index !== i);
        setImagePreview(newPreview)
    }

    // useEffect(() => {
    //     if (fileInputRef) fileInputRef.current.value = null;
    // }, [imagePreview]);

    const [isHovering, setIsHovering] = useState(null);
     const [addedIsHovering, setAddedIsHovering] = useState(null);

    const addedHandleMouseOver = (i) => {
        setAddedIsHovering(i);
    };
    const addedHandleMouseOut = (i) => {
        setAddedIsHovering(null);
    };

    const handleMouseOver = (i) => {
        setIsHovering(i);
    };
    const handleMouseOut = (i) => {
        setIsHovering(null);
    };
    const deletefile = (i) => {
        const newFiles = files.filter((f, index) =>
            index !== i
        );
        setFiles(newFiles)

        //delete files
        const dFiles = files.filter((f, index) =>
            index === i
        );

        setdeletedFiles([...deletedFiles, ...dFiles])
    };

    const handleCategoryChange = (data) => {
        setCategory(data.value);
    };

    useEffect(() => {
        register("postInput");
        setValue("postInput")
    }, [dispatch])

    useEffect(() => {

        if(props.updateCommunityProMemberPost.loading){
            Swal.fire({
                allowOutsideClick : false,
                    didOpen: () => {
                    Swal.showLoading()
                }
            });  
        }
        else{
            if (props.updateCommunityProMemberPost.updatecommunitypromemberpost.length) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully updated!',
                    icon : 'success',
                    timer: 3000
                })
          }
          else if (typeof props.updateCommunityProMemberPost.error !== "undefined") {
            Swal.fire({
              title: 'Failed!',
              text: 'Something went wrong!',
              icon : 'error',
              timer: 3000
            })

          }
        }
    
    }, [props.updateCommunityProMemberPost])

     useEffect(() => {

        if(props.deleteCommunityProMemberPost.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{
            if (props.deleteCommunityProMemberPost.deletecommunitypromemberpost.length) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully deleted!',
                    icon : 'success',
                    timer: 3000
                })
          }
          else if (typeof props.deleteCommunityProMemberPost.error !== "undefined") {
            Swal.fire({
              title: 'Failed!',
              text: 'Something went wrong!',
              icon : 'error',
              timer: 3000
            })
          }
        }
    
    }, [props.deleteCommunityProMemberPost])

    return (
           
        <>                                 
        <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
            <DropdownToggle className=""  tag="span" style={ { cursor: 'pointer',  fontSize: '14px'}}>
                <i className="fa fa-ellipsis-h" aria-hidden="true" type="ellipsis" />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={e => onClickEditPost(e,props.postid)}>Edit</DropdownItem>
                <DropdownItem onClick={e => onClickDeletePost(e,props.postid)}>Delete</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        <Modal isOpen={modal} toggle={modalToggle}  backdrop={backdrop} size="lg">
                <ModalHeader toggle={modalToggle}>Edit Post #{props.postid}</ModalHeader>
                <ModalBody>
                    <div className="formCustom">
                        <TextareaAutosize 
                            className="inputCustom form-control"
                            placeholder='Write a post.'
                            value={postContent}
                            style={{
                                overflow: 'hidden',
                                resize: 'none'
                            }}
                            onChange={handleChange}
                        /> 
                        <div className="d-flex flex-row ml-1 p-1 justify-content-between align-items-center" >
                            <div className="uploadIconsContainer pt-1">
                              
                            </div>
                            <div className="submitCommentSection mr-1">
                               
                            </div>
                        </div>
                        <input
                            type="file"
                            accept="video/*,image/*"
                            multiple
                            onChange={(event) => handleFileChange(event)}
                            ref={fileInputRef}
                            style={{ display: "none" }}
                        
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-1 mt-2" style={{ overflowY: 'auto' }}>
                        {
                            imagePreview ?
                                imagePreview.length + files.length >= fileItemsLimit ? ''
                                : 
                                <div className="d-flex  align-items-center justify-content-center  rounded d-block "
                                    style={{ backgroundColor: '#ddd', border: '1px solid #ddd', height: '150px', whiteSpace: 'nowrap'}}
                                >
                                    <Button block color="primary" className="m-6 block " onClick={(event) => clickInputFile(event)}>+ Add File </Button>
                                </div>
                            : ''
                        }
                   
                        {
                            imagePreview ?
                                imagePreview.map((file, i) => {
                                
                                    return (
                                        <div style={{ position: 'relative' }} onMouseOver={e => addedHandleMouseOver(i)} key={i}>
                                             {
                                            addedIsHovering == i ? 
                                            <div div className="delete-img d-flex flex-row align-items-center justify-content-center m-2" onMouseOut={e => addedHandleMouseOut(i)} >
                                                <Button  color="secondary" className="dl-btn" onClick={e => removeFile(i)} >Delete</Button>
                                            </div>   
                                            :''
                                        }
                                        {/* <Button close outline  onClick={(event) => removeFile(i)}/> */}
                                            {file.type == 'video/mp4' ? 
                                                <video controls width="300" height="150" key={i}>
                                                <source
                                                    type={file.type}
                                                    src={URL.createObjectURL(file)}
                                                />
                                            </video>     
                                            :
                                            <img src={URL.createObjectURL(file)} key={i} alt="" height="150" width="250" className=" m-2 rounded d-block " style={{ overflowY: 'scroll' }} /> 
                                            
                                        }
                                        
                                        
                                    </div>
                                        
                                    )
                                })
                                    
                                : ''
                        }

                    {
                        files.length > 0
                            ?
                            files.map((v, i) =>
                                // console.log(v.file.mimeType)
                                <div  style={{ position: 'relative' }}
                                    onMouseOver={e => handleMouseOver(i)}
                                    key={i}
                                >
                                    {
                                        isHovering == i ? 
                                        <div div className="delete-img d-flex flex-row align-items-center justify-content-center m-2" onMouseOut={e => handleMouseOut(i)} >
                                            <Button  color="secondary" className="dl-btn" onClick={e => deletefile(i)} >Delete</Button>
                                        </div>   
                                        :''
                                    }
                                {
                                    v.file.mimeType == "video/mp4"
                                    ?
                                    <video width="300" height="150" controls className="m-2" >
                                        <source
                                            type={v.file.mimeType}
                                            src={'https://storage.googleapis.com/pre-dental-tracker.appspot.com/community-upload/' + v.name}
                                        />
                                    </video>
                                    :
                                    <img
                                        src={'https://storage.googleapis.com/pre-dental-tracker.appspot.com/community-upload/' + v.name}
                                        alt={i}
                                        width="200" height="150"  className=" m-2 rounded d-block "
                                        style={{ border: '1px solid #ddd' }}
                                    />
                                }
                                
                            </div>
                        )
                        :''
                    }
                    </div>
                  
                </ModalBody>
                <ModalFooter className="justify-content-between">
                <Col sm="4" className="pl-0">
                    <Select 
                    className="float-left"
                    placeholder="Select a category" 
                    options={catoptions} 
                    onChange={handleCategoryChange}
                    value={catoptions.find((option) => option.value === category)}
                    
                        />
                </Col>
            
                <div>
                <Button color="secondary" onClick={modalToggle}>
                    Cancel
                </Button>
                <Button   disabled={postContent || files.length ? false : true } color="primary" onClick={e => onClickUpdatePost(e,props.postid)}>
                    Save
                </Button>
                </div>
                
            </ModalFooter>
        </Modal>
        </>     
    )
      
}
const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
        updateCommunityProMemberPost: state.updateCommunityProMemberPost,
        deleteCommunityProMemberPost: state.deleteCommunityProMemberPost
        
    }
}
export default connect(mapStateToProps)(ProMemberActionPost)