import { useState, useEffect } from "react";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
} from "reactstrap";


import { connect, useDispatch } from "react-redux";

import { fetchWorkActivity } from 'redux/WorkActivities/WorkActivitiesActions';

const GoalHoursWidget = (props) => {

    const dispatch = useDispatch()

    const [clinicalGoal, setClinicalGoal] = useState(0)
    const [shadowGoal, setShadowGoal] = useState(0)
    const [volunteerGoal, setVolunteerGoal] = useState(0)

    useEffect(() => {
        if(props.userDetails.userId){
        const data = {
            route : "",
            userId: props.userDetails.userId
        }
       
        dispatch(fetchWorkActivity(data))
        
        setClinicalGoal(parseInt(props.userDetails.clinicalGoal))
        setShadowGoal(parseInt(props.userDetails.shadowGoal))
        setVolunteerGoal(parseInt(props.userDetails.volunteerGoal))
      }
        
    }, [props.userDetails])

    useEffect(() => {
      if(props.updateUserProfile.userprofile.length){
        setClinicalGoal(parseInt(props.updateUserProfile.userprofile[0].clinicalHours))
        setShadowGoal(parseInt(props.updateUserProfile.userprofile[0].shadowHours))
        setVolunteerGoal(parseInt(props.updateUserProfile.userprofile[0].volunteerHours))
      }
      

    }, [props.updateUserProfile])
    
    const [clinicalCompletedHours, setClinicalCompletedHours] = useState(0)
    const [shadowCompletedHours, setShadowCompletedHours] = useState(0)
    const [volunteerCompletedHours, setVolunteerCompletedHours] = useState(0)

    useEffect(() => {
        if(props.fetchWorkActivities.workactivities.length){
            let clinicalCompletedHoursVal = 0;
            let shadowCompletedHoursVal = 0;
            let volunteerCompletedHoursVal = 0;

            setClinicalCompletedHours(clinicalCompletedHoursVal)
            setShadowCompletedHours(shadowCompletedHoursVal)
            setVolunteerCompletedHours(volunteerCompletedHoursVal)

            props.fetchWorkActivities.workactivities.map((value, index) => {
                if(typeof value.workType != "undefined"){
                  if(value.workType == "Clinical"){
                    clinicalCompletedHoursVal += parseFloat(value.completedHours)
                    
                    setClinicalCompletedHours(clinicalCompletedHoursVal);
                    if(value.repeated){
                        value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {
                          
                            const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                            
                            setClinicalCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
                        })
                    }

                //     // if(value.anticipated){
                //     //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {

                //     //         clinicalCompletedHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
                //     //         setClinicalCompletedHours((prevTotal) => prevTotal + clinicalCompletedHoursVal);
                //     //     })
                //     // }

                }
                else if(value.workType == "Shadowing"){
                    shadowCompletedHoursVal += parseFloat(value.completedHours)
                    setShadowCompletedHours(shadowCompletedHoursVal);
                    if(value.repeated){
                        value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {

                              const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                              setShadowCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
                        })
                    }

                //     // if(value.anticipated){
                //     //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {

                //     //         shadowCompletedHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
                //     //         setShadowCompletedHours((prevTotal) => prevTotal + shadowCompletedHoursVal);
                //     //     })
                //     // }

                    
                }
                else if(value.workType == "Volunteering"){
                    volunteerCompletedHoursVal += parseFloat(value.completedHours)
                    setVolunteerCompletedHours(volunteerCompletedHoursVal);
                    if(value.repeated){
                        value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {

                              const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                              setVolunteerCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
                        })
                    }

                //     // if(value.anticipated){
                //     //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {

                //     //         volunteerCompletedHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
                //     //         setVolunteerCompletedHours((prevTotal) => prevTotal + volunteerCompletedHoursVal);
                //     //     })
                //     // }

                    
                }

                if(value.experienceType == "Community Service/Volunteer - Medical/Clinical"){
                  volunteerCompletedHoursVal += parseFloat(value.completedHours)
                    setVolunteerCompletedHours(volunteerCompletedHoursVal);
                    if(value.repeated){
                        value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {

                              const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                              setVolunteerCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
                        })
                    }

                //     // if(value.anticipated){
                //     //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {

                //     //         volunteerCompletedHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
                //     //         setVolunteerCompletedHours((prevTotal) => prevTotal + volunteerCompletedHoursVal);
                //     //     })
                //     // }
                }


                }


                if(typeof value.experienceType != "undefined"){
                  if(value.experienceType.includes("Shadowing")){
                      shadowCompletedHoursVal += parseFloat(value.experienceDetailsAverageWeeklyHours) * parseFloat(value.experienceDetailsNumberOfWeeks)
                      
                      setShadowCompletedHours(shadowCompletedHoursVal);
  
      
                  }
                }
    
                if(typeof value.experienceType != "undefined"){
                  
                    if(value.experienceType.includes("Volunteer")){
                        volunteerCompletedHoursVal += parseFloat(value.experienceDetailsAverageWeeklyHours) * parseFloat(value.experienceDetailsNumberOfWeeks)
                        
                        setVolunteerCompletedHours(volunteerCompletedHoursVal);
    
        
                  }
                }

            })

            
        }

       

    }, [props.fetchWorkActivities])
    
    const getCompletedHours = (partialName, data) => {

      let sum = 0;
      for (const key in data) {
        if (key.includes('CompletedHours')) {
            sum += parseInt(data[key]);
        }
      }

      return sum
    }
    return (
    <>
        <Card className="shadow">
            <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                    EXPERIENCE STATISTICS
                    </h6>
                    <h2 className="mb-0">Hours</h2>
                </div>
                </Row>
            </CardHeader>
            <CardBody>
            
                <div className="chart">
                <Bar
                
                    data={{
                        // labels: ["Clinical", "Shadow", "Volunteer"],
                        labels: [ "Shadow", "Volunteer"],
                        datasets: [
                          {
                            label: "Goal Hours",
                            // data: [clinicalGoal, shadowGoal, volunteerGoal],
                            data: [ shadowGoal, volunteerGoal],
                            maxBarThickness: 10,
                            backgroundColor: '#fb6340',
                          }
                          ,{
                            label: "Completed Hours",
                            // data: [clinicalCompletedHours,  shadowCompletedHours, volunteerCompletedHours],
                            data: [ shadowCompletedHours, volunteerCompletedHours],
                            maxBarThickness: 10,
                            backgroundColor: "#2dce89"
                          
                          }
                        ]
                      }}
                    options={{
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                callback: function (value) {
                                  if (!(value % 10)) {
                                    //return '$' + value + 'k'
                                    return value;
                                  }
                                }
                              }
                            }
                          ]
                        },
                        tooltips: {
                          callbacks: {
                            label: function (item, data) {
                              var label = data.datasets[item.datasetIndex].label || "";
                              var yLabel = item.yLabel;
                              var content = "";
                              if (data.datasets.length > 1) {
                                content += label;
                              }
                              content += ": " +yLabel;
                              return content;
                            }
                          }
                        }
                      }}
                />
                </div>
            </CardBody>
        </Card>
    </>
  )
}

const mapStateToProps = state => {
    return {
        fetchWorkActivities : state.fetchWorkActivities,
        userDetails : state.loginAuth.userDetails,
        updateUserProfile : state.updateUserProfile,
    }
  }


export default connect(mapStateToProps)(GoalHoursWidget)