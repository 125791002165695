import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from "react-redux";
import BuyTutoringHoursForm from 'components/Payments/BuyTutoringHoursForm';
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
// const stripePromise = loadStripe('pk_test_51Jc7T3LrYzlL9Yv87MMJ6RYPDrp3btJth6fbaZJrykBY0ePHxiMIs9jdohnTcovMW75tepSVyTx1qqdHfW5C6G2n00Qa2b7ZuR');
const stripePromise = loadStripe('pk_live_51Jc7T3LrYzlL9Yv8XDJA2Bujh4SVz7zkyzfy2x7r50mtAzOVr9s6Sk42nQeQQr6nyzXpVU2B4CKFkBxssxfMpwPd00WGbakrxW');
const BuyTutoringHoursModal = (props) => {

    const dispatch = useDispatch();

    let modalBuyTutoringHours = props.modalBuyTutoringHoursModal;
    let toggleBuyTutoringHours = props.toggleBuyTutoringHoursModal;

    const toggleBuyTutoringHoursModal = () => {
        dispatch(buyTutoringHours(!props.modalBuyTutoringHoursModal));
    };

    const options = {
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {/*...*/}
        
    };

    return (
        <>
            <Modal className="modal-dialog-centered" size="lg" isOpen={modalBuyTutoringHours}  toggle={toggleBuyTutoringHoursModal}>
                <ModalHeader toggle={toggleBuyTutoringHoursModal}>
                   Get Tutoring Hours
                </ModalHeader >     
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        {/* <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Get Tutoring Hours</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                
                            </Col>
                            </Row>
                        </CardHeader> */}
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            
                            <div className="p-3">
                                <Row>
                                    <Col lg="12">

                                    <Elements stripe={stripePromise} options={options}>
                                        <BuyTutoringHoursForm></BuyTutoringHoursForm>
                                        
                                    </Elements>

                                        {/* <Badge color={status == "active" ? "success" : "default"} className='mb-2'>{status}</Badge>
                                        <h2>{textPlan}</h2>
                                        <h1 className='mb-2'>{textPrice}</h1>
                                        <p>{textPeriod} {periodEnd}</p>
                                        <button className={status == "active" ? "btn btn-danger" : "btn btn-primary"} onClick={e => status == "active" ? handleCancelSubscription(e) : handleResumeSubscription(e)}>{status == "active" ? "Cancel" : "Renew"} Subscription</button> */}
                                    </Col>
                                </Row>
                            </div>  
                          
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                   
                </ModalFooter>
                
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    buyTutoringHoursModalStatus: state.buyTutoringHoursModalStatus,
  };
};

export default BuyTutoringHoursModal