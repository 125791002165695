import React, { useEffect, useState } from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { connect, useDispatch } from 'react-redux';
import { createTutoringHoursPayment, createTutoringHoursPaymentReset } from 'redux/Payments/PaymentsActions';
import {
    Card,
    Container,
    Row,
    Col,
    CardBody,
    CardHeader
} from "reactstrap";
import Swal from 'sweetalert2';
import BillingDetailsForm from './BillingDetailsForm';
import { buyTutoringHours } from 'redux/Payments/PaymentsActions';
import { updateTutoringHoursAvailable } from 'redux/Payments/PaymentsActions';
const BuyTutoringHoursForm = (props) => {

    const [tutoringHoursPackage , setTutoringHoursPackage] = useState('predentalTrackerTutoringHoursPackage1')

    const handleSelectTutoringHoursPackage = (e,handleSelectTutoringHoursPackage) => {
        e.preventDefault();
        setTutoringHoursPackage(handleSelectTutoringHoursPackage)

    }

    const options = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true,
            
        },
        terms : {
            card:"never"
        },
        fields :{
            billingDetails :{
                name:"never"
            }
        }
    }

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const handleError = (error) => {
        setLoading(false);
        
    }

    const [selectedTutorHours, setSelectedTutorHours] = useState(1);

    const handleSubmit = async (event) => {
        event.preventDefault();

       
        
        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // setLoading(true);

        setLoading(true);

        // // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();

        if (submitError) {
            handleError(submitError);
            return;
        }

        let amount = 20000
        if(tutoringHoursPackage == "predentalTrackerTutoringHoursPackage1"){
            amount = 20000

            setSelectedTutorHours(1)
        }
        else if (tutoringHoursPackage == "predentalTrackerTutoringHoursPackage2"){
            amount = 75000
            setSelectedTutorHours(5)
        }
        else {
            amount = 260000
            setSelectedTutorHours(20)
        }

        dispatch(createTutoringHoursPayment({amount : amount}))
    }

    useEffect(async () => {
        if(props.createTutoringHoursPayment.createtutoringhourspayment.length){
            

            // // Trigger form validation and wallet collection
            const {error: submitError} = await elements.submit();

            if (submitError) {
                handleError(submitError);
                return;
            }
            
            const {client_secret: clientSecret} = await props.createTutoringHoursPayment.createtutoringhourspayment[0];

            // Confirm the PaymentIntent using the details collected by the Payment Element
            const {error} = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: 'https://example.com/order/123/complete',
                },
                redirect : "if_required"
            });


            if (error) {
                // This point is only reached if there's an immediate error when confirming the Intent.
                // Show the error to your customer (for example, "payment details incomplete").
                handleError(error);

                Swal.fire({
                    title:'Failed!',
                    text: error.message,
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(createTutoringHoursPaymentReset());
                    
                })

            } else {
                
                // Your customer is redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer is redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                Swal.fire({
                    title:'Success!',
                    text:'Purchase successful!',
                    icon :'success',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    dispatch(updateTutoringHoursAvailable({email : props.userDetails.email, hours : selectedTutorHours}))
                    dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
                    dispatch(createTutoringHoursPaymentReset());
                    // window.location.reload();

                })
            }
        }
    }, [props.createTutoringHoursPayment])

  return (
    <form onSubmit={handleSubmit}>

        <p className='h2 text-dark'>Price</p>
        
        <Row>
            <Col md="12 mb-3">
                <button className={tutoringHoursPackage == "predentalTrackerTutoringHoursPackage1" ? 'btn btn-info w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectTutoringHoursPackage(e,'predentalTrackerTutoringHoursPackage1')}>$200 for 1</button>
            </Col>
            <Col md="12 mb-3">
                <button className={tutoringHoursPackage == "predentalTrackerTutoringHoursPackage2" ? 'btn btn-warning w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectTutoringHoursPackage(e,'predentalTrackerTutoringHoursPackage2')}>$750 for 5 ($150/hr)</button>
            </Col>
            <Col md="12">
                <button className={tutoringHoursPackage == "predentalTrackerTutoringHoursPackage3" ? 'btn btn-danger w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectTutoringHoursPackage(e,'predentalTrackerTutoringHoursPackage3')}>$2600 for 20 ($130/hr)</button>
            </Col>
        </Row>
        <br/>
        
        <p className='h2 text-dark'>Billing</p>
        <BillingDetailsForm/>
        <br/>
        <p className='h2 text-dark'>Payment</p>
        <PaymentElement options={options}  />
        <br/>
        <button className='btn btn-primary' disabled={!stripe || loading}>Purchase</button>
        
        
    </form>
  )
}

const mapStateToProps = state => {
    return {
        createTutoringHoursPayment : state.createTutoringHoursPayment,
        userDetails : state.loginAuth.userDetails,
        buyTutoringHoursModalStatus : state.buyTutoringHoursModalStatus

    }
}

export default connect(mapStateToProps)(BuyTutoringHoursForm)