
import React , { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchMcatScore } from 'redux/McatScores/McatScoresActions';

import { Line, Bar } from 'react-chartjs-2';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { aamcCarsScore,aamcChemScore, aamcBioChemScore, aamcTotalScore,aamcPsychSocScore} from 'util/AamcScoreConversion';


const McatScoresWidget = (props) => {
  
  const dispatch = useDispatch();


  useEffect(() => {
    
    if(props.userDetails.userId){
    const data = {
        route : "",
        userId : props.userDetails.userId,
        handle:props.userDetails.handle
    }
    dispatch(fetchMcatScore(data));
  }
    
  }, [props.userDetails])

  const mcatscores = useSelector(state => state.fetchMcatScores.mcatscores);


  let generalChemistryData = [];
  let organicChemistryData = [];
  let biologyData = [];
  let patData = [];
  let readingComprehensionData = [];
  let quantitiveReasoningData = [];
  let totalScienceData = [];
  let academicAverageData = [];

  let datasetLabels = [];
  let totalData = []

  mcatscores.forEach(function(value){
    
    // chemPhysData.push(parseInt(aamcChemScore[value.chemPhys][0]));
    // carsData.push(parseInt(aamcCarsScore[value.cars][0]));
    // bioBiochemData.push(parseInt(aamcBioChemScore[value.bioBiochem][0]));
    // psychSocData.push(parseInt(aamcPsychSocScore[value.psychSoc][0]));
    generalChemistryData.push(parseInt(value.generalChemistry));
    organicChemistryData.push(parseInt(value.organicChemistry));
    biologyData.push(parseInt(value.biology));
    patData.push(parseInt(value.pat));
    readingComprehensionData.push(parseInt(value.readingComprehension));
    quantitiveReasoningData.push(parseInt(value.quantitiveReasoning));
    totalScienceData.push(parseInt(value.totalScience));
    academicAverageData.push(parseInt(value.academicAverage));

    datasetLabels.push(value.label);

   
    
    // const sum = parseInt(value.chemPhys) + parseInt(value.cars) + parseInt(value.bioBiochem) + parseInt(value.psychSoc); // Add more arrays if needed
    // totalData.push(sum);

    

  })
  
  return (
    < >
      
          <Card className="shadow">
            <CardHeader className='bg-transparent'>
              <Row className="align-items-center">
                <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      DAT STATISTICS
                    </h6>
                    <h2 className="mb-0">Scores</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
              
                <Line
                  
                  data={{
                    labels: datasetLabels,
                    
                    datasets: [{
                      label: "GENERAL CHEMISTRY",
                      data:generalChemistryData,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(255, 99, 132)',
                      
                    },{
                      label: "ORGANIC CHEMISTRY",
                      data:organicChemistryData,
                      borderColor: 'rgb(54, 162, 235)',
                      backgroundColor: 'rgba(54, 162, 235, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(54, 162, 235)',
                    },{
                      label: "BIOLOGY",
                      data:biologyData,
                      borderColor: 'rgb(153, 102, 255)',
                      backgroundColor: 'rgba(153, 102, 255, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(153, 102, 255)',
                      
                    },{
                      label: "PAT",
                      data:patData,
                      borderColor: 'rgb(255, 10, 64)',
                      backgroundColor: 'rgba(255, 10, 200, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(255, 10, 200)',
                    },{
                      label: "READING COMPREHENSION",
                      data:readingComprehensionData,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 200, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(255, 99, 200)',
                      
                    },{
                      label: "QUANTITIVE REASONING",
                      data:quantitiveReasoningData,
                      borderColor: 'rgb(54, 162, 235)',
                      backgroundColor: 'rgba(54, 162, 100, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(54, 162, 100)',
                    },{
                      label: "TOTAL SCIENCE",
                      data:totalScienceData,
                      borderColor: 'rgb(153, 102, 255)',
                      backgroundColor: 'rgba(153, 102, 100, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(153, 102, 100)',
                      
                    },{
                      label: "ACADEMIC AVERAGE",
                      data:academicAverageData,
                      borderColor: 'rgb(255, 159, 64)',
                      backgroundColor: 'rgba(255, 159, 180, 0)',
                      pointStyle: 'circle', 
                      pointRadius: 5, 
                      pointBackgroundColor: 'rgb(255, 159, 180)',
                    },
                    // {
                    //   label: "TOTAL",
                    //   data:totalData,
                    //   borderColor: 'rgb(0, 0, 0)',
                    //   backgroundColor: 'rgba(0, 0, 0, 0)',
                    //   pointStyle: 'circle', 
                    //   pointRadius: 5, 
                    //   pointBackgroundColor: 'rgb(0, 0, 0)',
                    //   hidden: true,
                    // }
                  ]}
                  }
                  
                  options={{
                    
                    tooltips: {
                      callbacks: {
                        afterLabel: function(tooltipItem, data) {
                          if (tooltipItem.datasetIndex === 3) {
                            
                            // return 'TOTAL : ' + totalData[tooltipItem.index];
                          }
                          
                        } 
                      }
                    },
                    responsive: true,
                    scales: {
                      myScale: {
                        type: 'logarithmic',
                        position: 'right', // `axis` is determined by the position as `'y'`,
                        
                      },
                      yAxes: [{
                        ticks: {
                            

                            max : 30,
                            min : 0,
                           
                            },
                            
                        }
                      ],
                      xAxes : [{
                          display:false,
                          
                      }]  ,
                      
                    },
                    clip: false
                  }
                  
                }

                  id="chart-sales"
                  className="chart-canvas"
                />
              </div>
            </CardBody>
          </Card>
       
          
        
    </>
  )
}



const mapStateToProps = state => {
  return {
    fetchMcatScores : state.fetchMcatScores,
    userDetails : state.loginAuth.userDetails,
  }
}

export default connect(mapStateToProps)(McatScoresWidget);