import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardFooter,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Button,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Spinner,
    Media

} from "reactstrap";
import { app, db } from "../../firebase";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { createStudentToLifetimePlan,createStudentToLifetimePlanReset, fetchLtpItem, deleteLtpItem } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
const LifeTimePlanUsers = (props) => {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);

    const [userView, setUserView ] = useState();

    const [checkUsers, setCheckUsers] = useState([]);
	const {
		register,
		getValues,
		unregister,
		watch,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

    useEffect(() => {
        if (props.fetchLtpItem.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            Swal.close();
            setLoading(false)
        }

        if (props.fetchLtpItem.ltpitems.length) {
            setData( props.fetchLtpItem.ltpitems);
            setFilter( props.fetchLtpItem.ltpitems);
        }
    }, [props.fetchLtpItem]);
    

    useEffect(() => {
		const fetchAllUsers = async () => {
		const querySnapshot = await getDocs(collection(db, "users"));
		let users = [];
		querySnapshot.forEach((doc) => {
			let v = doc.data();
			users.push({
                value: v.email,
                label: v.name,
                image: typeof v.profilePicture != "undefined" ? v.profilePicture : "",

			});
		});
		// users.unshift({ value: "", label: "Select Users" });
		setCheckUsers(users);
		};
		fetchAllUsers();
	}, []);

    const [selectedUsers, setselectedUsers] = useState([]);
    const selectUsers = (e) => {
		const values = [...e].map((opt) => opt.value);
		setselectedUsers(values);
		setValue("selectUsers", values);
	};

    //Modal Behaviour
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);
    
    const addUserLifeTimePlan = () => {
        setBackdrop(true);
        setModal(true);
    }

    const [modalView, setModalView] = useState(false);
    const modalViewToggle = () => setModalView(!modalView);
    const [backdropView, setBackdropView] = useState(true);

    const showUserInfo = (data) => {
        setUserView(data)
        setBackdropView(true);
        setModalView(true)
    }

    const onSubmitCreate = (data) => {
		dispatch(createStudentToLifetimePlan(data));
	};

    useEffect(() => {
      
        if(props.createStudentToLifetimePlan.loading){
            setModal(false);
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        }else{
            if(props.createStudentToLifetimePlan.addstudenttoplan.length){
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully added!',
                    icon : 'success',
                    timer: 3000
                }).then((result) => {
                    dispatch(createStudentToLifetimePlanReset());
                    dispatch(fetchLtpItem())
                })
            }else {
                Swal.fire({
                    title:'Failed!',
                    text:'Failed to additem!',
                    icon :'error',
                    timer: 3000
                })
            }
        }
       
    },[props.createStudentToLifetimePlan])

    //On Click Delete 
    const onClickDeleteItem = (data) => {
        console.log(data)
        Swal.fire({
            title: 'Remove from list',
            text: 'Do you want to remove '+ data.user.name + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteLtpItem({itemid: data.docId}))
               
            }
        }) 
    }



    useEffect(() => {

        if(props.deleteLtpItem.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }else{
            if (props.deleteLtpItem.deleteLtpItem.length) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully removed!',
                    icon : 'success',
                    timer: 3000 
                })
                dispatch(fetchLtpItem())
          }
          else if (typeof props.deleteLtpItem.error !== "undefined") {
            Swal.fire({
              title: 'Failed!',
              text: 'Something went wrong!',
              icon : 'error',
              timer: 3000
            })
          }
        }
    
    }, [props.deleteLtpItem])


    const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }

    
	useEffect(() => {
		const result = data.filter((item) => {
			if(item){
				return (
					item.email.match(search.toLocaleLowerCase()) || 
                    item.user.name.match(search.toLocaleLowerCase()) || 
					convertDate(item.createdAt).toLowerCase().match(search.toLocaleLowerCase()) 
				)
			}
		});
		
    	setFilter(result);
		
	}, [search]);

    const columns = [
		{
			name: "Name",
			sortable: true,
            width: '200px',
			selector: (row) => {
				return(
					<Media className="align-items-center">
						{
							typeof row.user.profilePicture != "undefined" ? (
							<div className="card-profile-div-sm card-profile-div-md">
								<div className="card-profile-image">
									<div
									className="profile-picture-sm profile-picture-md"
									style={{
										width: "38px",
										height: "38px",
										backgroundImage:
											'url("https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/' +
											row.user.profilePicture +
											'")',
									}}
									>
										
									</div>
								</div>
							</div>
							) : 
							(
							<div className="rounded-circle profile-letter-all-users mr-2" 
							style={{
							width: "30px",
							height: "30px",
								fontSize: "16px"
								}}>
								{row.user.name !== undefined ? row.user.name.trim()[0].toUpperCase() : "1" }
							</div>
							)
						}
						<span className="mb-0 text-sm">{row.user.name}</span>
					</Media>
				)
			}
		},
		{
			name: "Email",
			sortable: true,
            center: 'true',
			selector: (row) => {
				return row.email
			}
		},
        {
			name: "Status",
			sortable: true,
            center: 'true',
			selector: (row) => {
				return row.status 
			}
		},
		// {
		// 	name: "Entry Date",
		// 	sortable: true,
        //     center: 'true',
		// 	selector: (row) => {
		// 		return convertDate(row.createdAt);
		// 	}
		// },
		{
			name: "Action",
            center: "true",
			cell: (row) => ( 
                <>
                    <button  className="close mr-3 " aria-label="Edit" style={{opacity: '1'}}
                        onClick={(e) => showUserInfo(row)}
                    >
                        <span aria-hidden="true" className="fas fa-eye text-success"></span>
                    </button>
                    <button  className="close " aria-label="Delete" style={{opacity: '1'}} 
                        onClick={(e) => onClickDeleteItem(row)} 
                    >
                        <span aria-hidden="true" className="fas fa-times text-danger"></span>
                    </button>
                </>
                
			),
			
		},
	];

	const tableHeaderstyle = {
		headCells: {
		style: {
			fontWeight: "bold",
			fontSize: "14px",
			// backgroundColor: "#ccc",
		},
		},
	};


   return (
    <>
        
        <Card className="shadow">
            <CardHeader>
                <Button onClick={addUserLifeTimePlan} color="success" size="" className=" float-right"><i className="fas fa-plus" /> Add user</Button>
                <h4>Manage Pro Member Accounts</h4>
            </CardHeader>
            <CardBody> 
                { 
                    loading ? 
                        <div className="m-5">
                            <Alert color="secondary" className="text-center">
                                Collecting data.. Please wait while loading {" "}
                                <Spinner size="sm" color="primary" />{" "}
                            </Alert>
                        </div>
                    : data.length ? 
                            <DataTable
                                progressPending={loading}
                                customStyles={tableHeaderstyle}
                                columns={columns}
                                data={filter}
                                pagination
                                wrap
                                // selectableRows
                                // fixedHeader
                                selectableRowsHighlight
                                highlightOnHover
                                subHeader
                                subHeaderComponent={
                                <input
                                    type="text"
                                    className="w-50 form-control"
                                    placeholder="Search ..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                }
                                subHeaderAlign="right"
                            />
                    : 
                    <Alert color="primary">No data available .</Alert>
                }
            </CardBody>
        </Card>
        <Modal
            isOpen={modal}
            toggle={modalToggle}
            backdrop={backdrop}
            size="lg"
            centered
        >
            <ModalHeader toggle={modalToggle}>
            Add A Student
            </ModalHeader>
            <Form onSubmit={handleSubmit(onSubmitCreate)}>
                <ModalBody className="pt-1">
                    <Row>
                        <Col>
                            <FormGroup>
                                <Select
                                    isMulti
                                    name="selectusers"
                                    options={checkUsers}
                                    placeholder="Selec users"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={selectUsers}
                                    formatOptionLabel={(v) => (
                                    <div className="d-flex ">
                                        {
                                            v.value ? (
                                                v.image ? (
                                                    <img
                                                    className="rounded-circle  mr-2 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    src={
                                                        "https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/" +
                                                        v.image
                                                    }
                                                    alt={v.label}
                                                    />
                                                ) : (
                                                    <div
                                                    className="rounded-circle profile-letter-all-users mr-2 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    >
                                                    {v.label ? v.label.trim()[0] : ""}
                                                    </div>
                                                )
                                            ) : ("")
                                        }

                                        <span>{v.label + ' ('+v.value+')'}</span>
                                    </div>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        </Row>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={modalToggle} >
                Cancel
                </Button>
                <Button color="primary" >
                Save
                </Button>
                </ModalFooter>
            </Form>
        </Modal>

        <Modal
            isOpen={modalView}
            toggle={modalViewToggle}
            backdrop={backdropView}
            size="sm"
            centered
        >
       
            <ModalHeader toggle={modalViewToggle}>
                User Information                         
            </ModalHeader>
            <ModalBody className="pt-1">
                <Row>
                    <Col>
                        {
                            userView ?
                            <>
                            <Media className="align-items-center float-right" >
                                {
                                    typeof userView.user.profilePicture != "undefined" ? (
                                    <div className="card-profile-div-sm card-profile-div-md">
                                        <div className="card-profile-image">
                                            <div
                                            className="profile-picture-sm profile-picture-md"
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                backgroundImage:
                                                    'url("https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/' +
                                                    userView.user.profilePicture +
                                                    '")',
                                            }}
                                            >
                                                
                                            </div>
                                        </div>
                                    </div>
                                    ) : 
                                    (
                                    <div className="rounded-circle profile-letter-all-users mr-2" 
                                    style={{
                                    width: "50px",
                                    height: "50px",
                                       
                                        }}>
                                        {userView.user.name !== undefined ? userView.user.name.trim()[0].toUpperCase() : "1" }
                                    </div>
                                    )
                                }
                                
                            </Media>
                            <p><b>Name: </b> {userView.user.name}</p>
                            <p><b>Email: </b>  {userView.email}</p>
                            <p><b>Subscription: </b> {
                                userView.user.ultimateAdvisingStudent == "true" || userView.user.ultimateAdvisingStudent === true
                                ? "Ultimate Advising Student"
                                : typeof userView.user.subsId != "undefined"
                                ? "PRO Member"
                                : "Free Account"
								}
                            </p>
                                
                            </>
                                            
                            : ''
                                    
                        }
                    
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    createStudentToLifetimePlan : state.createStudentToLifetimePlan,
    fetchLtpItem: state.fetchLtpItem,
    deleteLtpItem: state.deleteLtpItem
  };
};

export default connect(mapStateToProps)(LifeTimePlanUsers);
