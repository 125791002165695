
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCH8-dqQYswJncNjl1nzi7uhfAP0ozQbRE",
  authDomain: "pre-dental-tracker.firebaseapp.com",
  projectId: "pre-dental-tracker",
  storageBucket: "pre-dental-tracker.appspot.com",
  messagingSenderId: "415175551732",
  appId: "1:415175551732:web:2d49516fac96753ee90ca1",
  measurementId: "G-0EHLQ747GN"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);




