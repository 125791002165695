import { 
    OPEN_ACHIEVEMENT_MODAL,
    CLOSE_ACHIEVEMENT_MODAL,
    CREATE_ACHIEVEMENT_REQUEST, 
    CREATE_ACHIEVEMENT_SUCCESS, 
    CREATE_ACHIEVEMENT_FAILURE,
    CREATE_ACHIEVEMENT_RESET,
    FETCH_ACHIEVEMENTS_REQUEST, 
    FETCH_ACHIEVEMENTS_SUCCESS, 
    FETCH_ACHIEVEMENTS_FAILURE,
    EDIT_ACHIEVEMENT,
    NEW_ACHIEVEMENT,
    UPDATE_ACHIEVEMENT_REQUEST, 
    UPDATE_ACHIEVEMENT_SUCCESS, 
    UPDATE_ACHIEVEMENT_FAILURE,
    UPDATE_ACHIEVEMENT_RESET,
    DELETE_ACHIEVEMENT_REQUEST,
    DELETE_ACHIEVEMENT_SUCCESS, 
    DELETE_ACHIEVEMENT_FAILURE,
    DELETE_ACHIEVEMENT_RESET,
    UPDATE_IMPORTANT_ACHIEVEMENT_REQUEST, 
    UPDATE_IMPORTANT_ACHIEVEMENT_SUCCESS, 
    UPDATE_IMPORTANT_ACHIEVEMENT_FAILURE,
    UPDATE_IMPORTANT_ACHIEVEMENT_RESET,
} from "./AchievementsTypes";

const initialCreateAchievementsState = {
    loading: false,
    achievement: [],
}

const initialUpdateAchievementsState = {
    loading: false,
    achievement: [],
}

const initialDeleteAchievementsState = {
    loading: false,
    achievement: [],
}

const initialFetchAchievementsState = {
    achievements : [],
    loading: false,

}

const initialModalAchievementsState = {
    status: false
}

const initialEditAchievementsState = {
    status: false,
    achievementId : ''
}

const initialNewAchievementsState = {
    status: false,
}

export const createAchievementsReducer = (state = initialCreateAchievementsState, action) => {
    switch (action.type) {
        case CREATE_ACHIEVEMENT_RESET:
            return {
                loading: false,
                achievement: [],
            }
        case CREATE_ACHIEVEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_ACHIEVEMENT_SUCCESS:
            return {
                loading: false,
                achievement: [action.payload]
            }
        case CREATE_ACHIEVEMENT_FAILURE:
            return {
                loading: false,
                achievement: [],
                error: action.payload
            }
        default: return state;
    }
}

export const fetchAchievementsReducer = (state = initialFetchAchievementsState, action) => {
    switch (action.type) {
        case FETCH_ACHIEVEMENTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ACHIEVEMENTS_SUCCESS:
            return {
                loading: false,
                achievements: action.payload
            }
        case FETCH_ACHIEVEMENTS_FAILURE:
            return {
                loading: false,
                achievements: [],
                error: action.payload
            }
        default: return state;
    }
}

export const updateAchievementsReducer = (state = initialUpdateAchievementsState, action) => {
    switch (action.type) {
        case UPDATE_ACHIEVEMENT_RESET:
            return {
                loading: false,
                achievement: [],
            }
        case UPDATE_ACHIEVEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_ACHIEVEMENT_SUCCESS:
            return {
                loading: false,
                achievement: [action.payload]
            }
        case UPDATE_ACHIEVEMENT_FAILURE:
            return {
                loading: false,
                achievement: [],
                error: action.payload
            }
        default: return state;
    }
}

export const editAchievementsReducer = (state = initialEditAchievementsState, action) => {
    switch (action.type) {
        case EDIT_ACHIEVEMENT:
            return {
                status: action.payload.status,
                achievementId: action.payload.achievementId
            }
        default: return state;
    }
}

export const newAchievementsReducer = (state = initialNewAchievementsState, action) => {
    switch (action.type) {
        case NEW_ACHIEVEMENT:
            return {
                status: action.payload.status,
            }
        default: return state;
    }
}

export const updateAchievementsModalStatusReducer = (state = initialModalAchievementsState, action) => {
    switch (action.type) {
        case OPEN_ACHIEVEMENT_MODAL:
            return {
                status : true
            }
        case CLOSE_ACHIEVEMENT_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}


export const deleteAchievementsReducer = (state = initialDeleteAchievementsState, action) => {
    switch (action.type) {
        case DELETE_ACHIEVEMENT_RESET:
            return {
                loading: false,
                achievement: [],
            }
        case DELETE_ACHIEVEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_ACHIEVEMENT_SUCCESS:
            return {
                loading: false,
                achievement: [action.payload]
            }
        case DELETE_ACHIEVEMENT_FAILURE:
            return {
                loading: false,
                achievement: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUpdateImportantAchievementsState = {
    loading: false,
    mostachievements: [],
}

export const updateImportantAchievementsReducer = (state = initialUpdateImportantAchievementsState, action) => {
    switch (action.type) {
        case UPDATE_IMPORTANT_ACHIEVEMENT_RESET:
            return {
                loading: false,
                mostachievements: [],
            }
        case UPDATE_IMPORTANT_ACHIEVEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_IMPORTANT_ACHIEVEMENT_SUCCESS:
            return {
                loading: false,
                mostachievements: [action.payload]
            }
        case UPDATE_IMPORTANT_ACHIEVEMENT_FAILURE:
            return {
                loading: false,
                mostachievements: [],
                error: action.payload
            }
        default: return state;
    }
}