import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
  } from "reactstrap";

import { fetchCourse } from 'redux/Courses/CoursesActions';
import { connect } from 'react-redux';

import { premedReqs } from 'util/DataList';

const PremedRequirements = (props) => {

    const dispatch = useDispatch()
    
    const [requirements, setRequirements] = useState({});
    
    useEffect(() => {
        if(props.userDetails.userId){
        const data = {
            route : "",
            userId : props.userDetails.userId,
            handle:props.userDetails.handle
        }
        
        dispatch(fetchCourse(data))

        premedReqs.map((value, index) => {
            if(value != "Class not a Pre-Dental Requirement" && value != "default"){
                setRequirements((prevRequirements) => ({
                    ...prevRequirements,
                    [value]: false
                }));
            }
            
        })
    }
        
    }, [props.userDetails])

    

    useEffect(() => {
        if(props.fetchCourses.courses.length){
            props.fetchCourses.courses.map((value, index) => {
                if(value.reqs.length){
                    value.reqs.map((reqsValue, reqsIndex) => {
                        if(reqsValue != "default" && reqsValue != "Class not a Pre-Dental Requirement"){
                            setRequirements((prevRequirements) => ({
                                ...prevRequirements,
                                [reqsValue]: true
                            }));
                        }
                    })
                }
               
            });
        }
    }, [props.fetchCourses])

  return (
    <>
        <Card className="shadow">
            <CardHeader className="border-0">
                <Row className="align-items-center">
                    <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                            PRE-DENTAL REQUIREMENTS
                        </h6>
                        <h2 className="mb-0">BASIC REQUIREMENTS</h2>
                    </div>
                </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
            <tbody>
                
                {
              
                Object.entries(requirements).map(([key, value], index) => {
                    
                    if (index % 2 === 0) {
                        return (
                        <tr key={index}>
                            <td>
                            {value ? (
                                <i className="fas fa-check text-success mr-3" />
                            ) : (
                                <i className="fas fa-circle text-danger mr-3" />
                            )}
                            {key}
                            </td>
                            {index + 1 < Object.entries(requirements).length && (
                            <td>
                                {Object.entries(requirements)[index + 1][1] ? (
                                <i className="fas fa-check text-success mr-3" />
                                ) : (
                                <i className="fas fa-circle text-danger mr-3" />
                                )}
                                {Object.entries(requirements)[index + 1][0]}
                            </td>
                            )}
                        </tr>
                        );
                    }
                    return null;
                })}
                
            </tbody>
            </Table>
        </Card>
    </>
  )
}
const mapStateToProps = state => {
    return {
        fetchCourses : state.fetchCourses,
        userDetails : state.loginAuth.userDetails,
    }
  }


export default connect(mapStateToProps)(PremedRequirements)