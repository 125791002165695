import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";

import {createTpcCourseCategory, createTpcCourseCategoryReset, updateTpcCourseCategory, updateTpcCourseCategoryReset} from 'redux/ManageTpcCourses/ManageTpcCoursesActions'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'

const AddTpcCourseCategoryModal = (props) => {

    let modalAddTpcCourseCategory = props.modalAddTpcCourseCategoryModal;
    let toggleAddTpcCourseCategory = props.toggleAddTpcCourseCategoryModal;


    const [categoryLists , setCategoryLists] = useState([])

    const dispatch = useDispatch();
    

    

    useEffect(() => {
        if(props.editCourseCategory){
            setCategoryName(props.categoryToUpdate.text)
        }
    }, [modalAddTpcCourseCategory])
    
    useEffect(() => {

        if(typeof props.courseData.category != "undefined") {
            
            setCategoryLists(props.courseData.category)
        }

        console.log(props.courseData)

    }, [])
    

    const [categoryName , setCategoryName] = useState([])

    const handleCreateCategory = (e) => {
        e.preventDefault()

        Swal.showLoading()

        const updatedCategoryLists = [...categoryLists, {id : categoryLists.length + 1, text: categoryName, lessons: []}];

        setCategoryLists(updatedCategoryLists)

        dispatch(createTpcCourseCategory({tpcCourseId : props.courseData.tpcCourseId ,updatedCategoryLists : updatedCategoryLists}))

    }

    useEffect(() => {
    
  
        if(props.createTpcCourseCategory.tpccourse.length && typeof props.createTpcCourseCategory.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully added TPC course category!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(createTpcCourseCategoryReset());
            toggleAddTpcCourseCategory()
          })
          
        }
      
        else if(typeof props.createTpcCourseCategory.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to add TPC course category!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(createTpcCourseCategoryReset());
            toggleAddTpcCourseCategory()
          })    
                
        }
      
    }, [props.createTpcCourseCategory])

    useEffect(() => {
    
  
        if(props.updateTpcCourseCategory.tpccourse.length && typeof props.updateTpcCourseCategory.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully updated TPC course category!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateTpcCourseCategoryReset());
            toggleAddTpcCourseCategory()
          })
          
        }
      
        else if(typeof props.updateTpcCourseCategory.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to update TPC course category!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateTpcCourseCategoryReset());
            toggleAddTpcCourseCategory()
          })    
                
        }
      
    }, [props.updateTpcCourseCategory])

    const handleUpdateCategory = (e) =>{

        e.preventDefault()


        // console.log(categoryLists.find(value => value.id == props.categoryToUpdate.id))
        Swal.showLoading()
        const categoryFindById = categoryLists.find(value => value.id == props.categoryToUpdate.id)

        
        categoryFindById.text = categoryName

        

        const updatedCategoryLists = categoryLists;

        setCategoryLists(updatedCategoryLists)
        // console.log(updatedCategoryLists)
        dispatch(updateTpcCourseCategory({tpcCourseId : props.courseData.tpcCourseId ,updatedCategoryLists : updatedCategoryLists}))

    }
  return (
    <div>
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddTpcCourseCategory}  toggle={toggleAddTpcCourseCategory}>
                    
            <ModalBody className='modal-body p-0'>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">{props.editCourseCategory ? "Edit" : "Add"} Category</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            
                        </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                        <h6 className="heading-small text-muted mb-4">
                            Category Information
                        </h6>
                        <div className="pl-lg-4">
                            <Row>

                                <Col lg="12">
                                    <FormGroup>

                                        <label
                                            className="form-control-label"
                                            
                                        >
                                            Name <span className='text-danger'>*</span>
                                        </label>
                                        <Input
                                            className="form-control-alternative"

                                            defaultValue={props.editCourseCategory ? categoryName : ""}
                                            
                                            onChange={e => setCategoryName(e.target.value)}
                                            type="text"
                                        />

                                    </FormGroup>
                                </Col>

                            </Row>
                        </div>

                 
                        
                        
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary"  onClick={toggleAddTpcCourseCategory}>
                    Cancel
                </Button>

                {props.editCourseCategory === false ? 
                    <Button color="primary" onClick={handleCreateCategory}>
                        Save
                    </Button>
                    :
                    <Button color="primary" onClick={handleUpdateCategory}>
                        Update
                    </Button>
                }
            </ModalFooter>
            
        </Modal>
    </div>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createTpcCourseCategory : state.createTpcCourseCategory,
        updateTpcCourseCategory : state.updateTpcCourseCategory
    }
      
}

export default connect(mapStateToProps)(AddTpcCourseCategoryModal)
