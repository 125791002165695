import React, { useState, useEffect } from "react";

import {
  Card,
  CardTitle,
  CardText,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Pagination,
  FormGroup,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Tooltip,
  Alert,
} from "reactstrap";
// import moment from 'moment';
import ReactDatetime from "react-datetime";
import { useDispatch } from "react-redux";
import { fetchAvailableSchedule } from "redux/ScheduleTutor/ScheduleTutorActions";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  createRequestTutor,
  createRequestTutorReset,
  fetchRequestTutor,
  cancelRequestTutor,
  cancelRequestTutorReset,
  fetchRequestTutorHistory,
  fetchCoaches,
  createRequestSchedTutor,
  createRequestSchedTutorReset,
} from "redux/ScheduleTutor/ScheduleTutorActions";
import { app, db } from "../../firebase";
import {
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
  or,
} from "firebase/firestore";
import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import Select from "react-select";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
const ScheduleItem = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [requestTutorHistory, setRequestTutorHistory] = useState([]);

   const convertTimeToUTC = (time) => {
        return moment(moment(time).utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

  useEffect(() => {
    dispatch(
        fetchRequestTutorHistory({ studentHandle: props.userDetails.handle })
      );
  }, []);


  useEffect(() => {
    if (props.fetchRequestTutorHistory.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }

    if (props.fetchRequestTutorHistory.fetchrequesttutorhistory.length) {
      const collect = [];
      props.fetchRequestTutorHistory.fetchrequesttutorhistory[0].map((v) => {
        if (v.status == props.status) {
          collect.push(v);
        }
      });

      setData(collect);
      setFilter(collect);
    }
  }, [props.fetchRequestTutorHistory.fetchrequesttutorhistory]);


const columns = [
    {
      name: "Coach",
      wrap: true,
      selector: (row) => row.coach.name,
     
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => {
         return moment(row.starttime).format('ll') != moment(row.endtime).format('ll') ? moment(row.starttime).format('ll')+" - "+moment(row.endtime).format('ll'):  moment(row.starttime).format('ll')
      },
    },
    {
      name: "Type",
      selector: (row) => {
        return  row.requestType.toUpperCase() 
      },
      wrap: true,
    },
     {
      name: "Duration",
      selector: (row) => {
        return  row.duration
      },
      wrap: true,
    },
     {
      name: "Time",
      selector: (row) => {
        return convertTimeToLocal(row.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(row.endtime).format("h:mm A")
      },
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <Badge color="" className="badge-dot mr-4">
          <i
            className={
              row.status == "pending"
                ? "bg-info"
                : row.status == "approved"
                ? "bg-success"
                : row.status == "disapproved"
                ? "bg-danger"
                : "bg-warning"
            }
          />
          {row.status}
        </Badge>
      ),
    },
  
  ];


  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        // backgroundColor: "#ccc",
      },
    },
  };

  const convertDate = (item) => {
    return moment(item).utcOffset(0, false).format("YYYY-MM-DD");
  };

  useEffect(() => {
   
    const result = data.filter((item) => {
      return (
        item.coach.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.requestType.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.duration.toLowerCase().match(search.toLocaleLowerCase()) ||
        convertDate(item.date).toLowerCase().match(search.toLocaleLowerCase()) ||
        item.starttime.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.endtime.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.status.toLowerCase().match(search.toLocaleLowerCase()) 
        
      );
    });
   
    setFilter(result);
  }, [search]);

 
  return (
    <Card className="shadow">
      <CardHeader className="border-0 h3">
        {props.status.toUpperCase()} REQUESTS
      </CardHeader>
      <CardBody>
        {data.length ? (
          <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            wrap
            // selectableRows
            // fixedHeader
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="w-50 form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign="right"
          />
        ) : (
          <Alert color="primary">No data available .</Alert>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchAvailableSchedule: state.fetchAvailableSchedule,
    createRequestTutor: state.createRequestTutor,
    createRequestSchedTutor: state.createRequestSchedTutor,
    fetchRequestTutor: state.fetchRequestTutor,
    cancelRequestTutor: state.cancelRequestTutor,
    fetchRequestTutorHistory: state.fetchRequestTutorHistory,
    buyTutoringHoursModalStatus: state.buyTutoringHoursModalStatus,
  };
};

export default connect(mapStateToProps)(ScheduleItem);
