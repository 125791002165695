import {
CREATE_LTP_REQUEST,
CREATE_LTP_SUCCESS,
CREATE_LTP_FAILURE,
CREATE_LTP_RESET,
FETCH_LTP_REQUEST,
FETCH_LTP_SUCCESS,
FETCH_LTP_FAILURE,
DELETE_LTP_REQUEST,
DELETE_LTP_SUCCESS,
DELETE_LTP_FAILURE,
DELETE_LTP_RESET,

} from "./ManageLifeTimePlanTypes";
import axios from "axios";


export const createStudentToLifetimePlanReset = () => {
    return {type: CREATE_LTP_RESET}
}

const createStudentToLifetimePlanRequest = () => {
    return {type: CREATE_LTP_REQUEST}
}
const createStudentToLifetimePlanSuccess = success => {
    return {type: CREATE_LTP_SUCCESS, payload: success}
}
const createStudentToLifetimePlanFailure = error => {
    return {type: CREATE_LTP_FAILURE, payload : error}
}
export const createStudentToLifetimePlan = (data) => {
    return (dispatch) => {
        dispatch(createStudentToLifetimePlanRequest());
        axios.post('/createStudentToLifetimePlan', data)
        .then(response => {
            const res = response.data
            dispatch(createStudentToLifetimePlanSuccess(res)) 
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createStudentToLifetimePlanFailure(errorMsg))
        }) 
    }
}

const fetchLtpItemRequest = () => {
    return {
        type: FETCH_LTP_REQUEST,
    }
}

const fetchLtpItemSuccess = success => {
    return {
        type: FETCH_LTP_SUCCESS,
        payload: success
    }
}

const fetchLtpItemFailure = error => {
    return {
        type: FETCH_LTP_FAILURE,
        payload : error
    }
}

export const fetchLtpItem = (data) => {
    return (dispatch) => {
        dispatch(fetchLtpItemRequest());
        axios.post('/fetchStudentToLifetimePlan' , data)
        .then(response => {
            const ltpItem = response.data
            dispatch(fetchLtpItemSuccess(ltpItem))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchLtpItemFailure(errorMsg))
        }) 
    }
}

export const deleteLtpItemReset = () => {
  return {
    type: DELETE_LTP_RESET,
  };
};

const deleteLtpItemRequest = () => {
  return {
    type: DELETE_LTP_REQUEST,
  };
};

const deleteLtpItemSuccess = (success) => {
  return {
    type: DELETE_LTP_SUCCESS,
    payload: success,
  };
};

const deleteLtpItemFailure = (error) => {
  return {
    type: DELETE_LTP_FAILURE,
    payload: error,
  };
};

export const deleteLtpItem = (data) => {
  return (dispatch) => {
    dispatch(deleteLtpItemRequest());
    axios
      .post("/deleteStudentFromLifetimePlan", data)
      .then((response) => {
        const deleteLtpItem = response.data;
        dispatch(deleteLtpItemSuccess(deleteLtpItem));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteLtpItemFailure(errorMsg));
      });
  };
};
