import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import { fetchMcatScore, updateMcatScoreModal, editMcatScore, deleteMcatScore, deleteMcatScoreReset } from 'redux/McatScores/McatScoresActions';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';

import { aamcCarsScore,aamcChemScore, aamcBioChemScore, aamcTotalScore,aamcPsychSocScore} from 'util/AamcScoreConversion';

const McatScoresTable = (props) => {

    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);


    useEffect(() => {
        
        const data = {
            route : "",
            userId : props.userDetails.userId,
            handle:props.userDetails.handle
        }
        
        dispatch(fetchMcatScore(data))
        
        
    }, [])
    
    useEffect(() => {
        
        setList(props.fetchMcatScores.mcatscores.slice(0, 5))
        
        
    }, [props.fetchMcatScores])

    if(props.deleteMcatScore.mcatscore.length && typeof props.deleteMcatScore.error === "undefined"){
        
        Swal.fire({
            title: 'Deleted!',
            text: 'Successfully deleted Mcat Score!',
            icon : 'success',
            timer: 3000      
          }
        )
        dispatch(deleteMcatScoreReset());
        
        
      }

    else if(typeof props.deleteMcatScore.error !== "undefined"){
        
        Swal.fire({
            title:'Failed!',
            text:'Failed to delete Mcat Score!',
            icon :'error',
            timer: 3000 
            }
        )            
        dispatch(deleteMcatScoreReset());
    }

    const onClickEditMcatScore = (e,mcatScoreId) => {
        e.preventDefault();
        dispatch(editMcatScore({
            status : true,
            mcatScoreId : mcatScoreId
        }));

        dispatch(updateMcatScoreModal(!props.updateMcatScoreModalStatus.status));
    }

    const onClickDeleteMcatScore = (e,mcatScoreId, userId, handle) => {
        e.preventDefault();
        

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMcatScore({
                    docId : mcatScoreId,
                    userId : userId,
                    handle : handle
                }));

            }
          })
    }

    const showNext = (e,item) => {
        e.preventDefault();
        
        if(list.length !== 0) {
            dispatch(fetchMcatScore({route:"Next" , item:item.item, userId:props.userDetails.userId, handle:props.userDetails.handle}))
            setList(props.fetchMcatScores.mcatscores)
            setPage(page + 1)
        } 
    };

    const showPrevious = ( e,item ) => {
        e.preventDefault();
        if(list.length !== 0) {
            dispatch(fetchMcatScore({route:"Previous", item:item.item, userId:props.userDetails.userId, handle:props.userDetails.handle}))
            setList(props.fetchMcatScores.mcatscores)
            setPage(page - 1)
        } 
    };
    
  return (
    <>
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
                <tr>
                    <th scope="col">NAME OF TEST</th>
                    <th scope="col">DATE</th>
                    <th scope="col">GENERAL CHEMISTRY</th>
                    <th scope="col">ORGANIC CHEMISTRY</th>
                    <th scope="col">BIOLOGY</th>
                    <th scope="col">PAT</th>
                    <th scope="col">READING COMPREHENSION</th>
                    <th scope="col">QUANTITIVE REASONING</th>
                    <th scope="col">TOTAL SCIENCE</th>
                    <th scope="col">ACADEMIC AVERAGE</th>
                    {/* <th scope="col">TOTAL</th> */}
                    <th scope="col">ACTION</th>
                </tr>
            </thead>
            <tbody>
                
                
                {
                    list.map((val) => {
                 
                    
                    return <tr key = {val.mcatScoreId}>
                            <td scope="row">
                                {val.label}
                            </td>
                            <td>
                                {val.date}
                            </td>
                            <td>
                               
                                {val.generalChemistry}
                            </td>
                            <td>
                                
                                {val.organicChemistry}
                            </td>
                            <td>
                                
                                {val.biology}
                            </td>
                            <td>
                                
                                {val.pat}
                            </td>
                            <td>
                                
                                {val.readingComprehension}
                            </td>
                            <td>
                                
                                {val.quantitiveReasoning}
                            </td>
                            <td>
                                
                                {val.totalScience}
                            </td>
                            <td>
                                
                                {val.academicAverage}
                            </td>

                            {/* <td>
                                
                                {parseInt(val.chemPhys) +  parseInt(val.cars) + parseInt(val.bioBiochem) + parseInt(val.psychSoc) }
                            </td>
                            */}
                            <td className="">

                                {props.adminUser.admin ?
                                    <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditMcatScore(e,val.mcatScoreId)}><i className="fa fa-eye"></i> View</a>
                                    :
                                    <>
                                        <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditMcatScore(e,val.mcatScoreId)}><i className="fa fa-pen"></i> Edit</a>
                                        <a href="#pablo" className="btn btn-sm btn-danger" onClick={(e) => onClickDeleteMcatScore(e,val.mcatScoreId,val.userId, val.handle)}><i className="fa fa-trash"></i> Delete</a>
                                    </>
                                }

                                {/* <UncontrolledDropdown>
                                <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => onClickEditMcatScore(e,val.mcatScoreId)}
                                    >
                                    Edit
                                    </DropdownItem>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => onClickDeleteMcatScore(e,val.mcatScoreId,val.userId, val.handle)}
                                    >
                                    Delete
                                    </DropdownItem>
                                </DropdownMenu>
                                </UncontrolledDropdown> */}
                            </td>
                        </tr>
                    })

                }
                    
                    
                
            </tbody>
        </Table>
        <CardFooter className="py-4">
            <nav aria-label="...">
                <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
                >
                <PaginationItem className={page === 1 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showPrevious(e,{ item: list[0] })}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {/* <PaginationItem className="active">
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    3
                    </PaginationLink>
                </PaginationItem> */}
                <PaginationItem className={list.length < 5 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showNext(e,{ item: list[list.length - 1] })}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav>
        </CardFooter>
    </>
  )
}

const mapStateToProps = state => {
    return {
        fetchMcatScores : state.fetchMcatScores,
        updateMcatScoreModalStatus : state.updateMcatScoreModalStatus,
        deleteMcatScore : state.deleteMcatScore,
        userDetails : state.loginAuth.userDetails,
        adminUser : state.adminUser
    }
  }

export default connect(mapStateToProps)(McatScoresTable)