import React from 'react'
import axios from 'axios'
import {getUserData} from 'redux/UserAuthentication/LoginActions'
import Admin from "layouts/Admin";
import Client from "layouts/Client";
// import ClientLayout from "layouts/Auth";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// const root = ReactDOM.createRoot(document.getElementById("root"));
import { Provider } from "react-redux";
import store from "redux/store";
import jwtDecode from 'jwt-decode'

import AuthRoute from 'util/AuthRoute';
import AdminRoute from "util/AdminRoute";
import ClientRoute from "util/ClientRoute";
import Login from 'views/pages/Login'
import {logoutUser} from 'redux/UserAuthentication/LoginActions'
import Register from 'views/pages/Register';
import ForgotPassword from 'views/pages/ForgotPassword';
import Swal from 'sweetalert2'
import { type } from 'jquery';
import { renewToken } from 'redux/UserAuthentication/LoginActions';
axios.defaults.baseURL = 'https://us-central1-pre-dental-tracker.cloudfunctions.net/api';
// axios.defaults.baseURL = 'http://localhost:5002/pre-dental-tracker/us-central1/api';
// const FBIdToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY5MDkzNDExNSwiZXhwIjoxNjkwOTM3NzE1LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay13NnNoOUB0cGNkYXNoYm9hcmQuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay13NnNoOUB0cGNkYXNoYm9hcmQuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiJVSVV5Y3ZGaHYxUnNLSTVLS0ZSek5tQk03VVoyIn0.huWm34b1x9m6Z7ouA6wnBiKJ-Z49UVacOSN6hje90wa3hHVNUgC0r3R879tbcCmiD7GyhGRnjQWH9sBNhjTrRdDAy5iZ0yGpjwA-v8EqZDAVExNqjb76tI8qWd-lmVy-2emH1N-o90l2Qy2etyxxCF6-kWVPWX39bpmjJRXddK2u0rkhFnrQtFjqimhyE-K_eRtsDNfV-kjW2zTwpeTKymmTO5UPMhnG87NoOlydkr0nPEFvPt7jCpN5x6rv_06MmMfrP4ceJsVHv2fnY5vD3PQMaGFfNtEkNATCezs0OpUqSJ-c-08scWgbdrkWJHgeJsTQ7f9korKlucCCKLpqPA"

// localStorage.setItem('FBIdToken', FBIdToken)
// axios.defaults.headers.common['Authorization'] = "Bearer "+ FBIdToken
// store.dispatch(getUserData({handle:"aringorstudent", onLogin:true}))

// const token = localStorage.FBIdToken
// if (token){
//   const decodedToken = jwtDecode(token)
//   // console.log(new Date(decodedToken.exp * 1000))
  
//   if (decodedToken.exp * 1000 < Date.now()){
//       // store.dispatch(refreshUser())
//       store.dispatch(logoutUser())    
//     //   window.location.reload()
//         if (!localStorage.getItem('tokenExpired')) {
//             localStorage.setItem('tokenExpired', 'true');
//             window.location.reload(); // Reload the page
//         }

//   } 
//   else {
//         localStorage.removeItem('tokenExpired');
//         axios.defaults.headers.common['Authorization']=token
//         store.dispatch(getUserData({onLogin:true}))
        
//   }
// }

let login = false;
let prompt = false;
const checkTokenExpiration = () => {

    let token = localStorage.FBIdToken;
    
    if (token) {
        const decodedToken = jwtDecode(token);

        if (decodedToken.exp * 1000 < Date.now()) {

            if(typeof token != "undefined"){

                if(!prompt){

                    Swal.fire({

                        title: 'Session Expired',
                        text: "Your session is expired",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Stay Connected',
                        cancelButtonText: 'Logout'

                    }).then((result) => {
                        
                        if (result.isConfirmed) {
    
                            console.log("stay connected")
                            store.dispatch(renewToken({token : localStorage.getItem('FBRefreshToken')}))

                            setTimeout(() => {
                                prompt = false;
                            }, 30000);

                            
                        }
                        else{
                            
                            store.dispatch(logoutUser())

                            if (!localStorage.getItem('tokenExpired')) {
                                localStorage.setItem('tokenExpired', 'true');
                               
                                window.location.reload(); // Reload the page
                            }
                        }
                    })
                    prompt = true;
                }

                localStorage.removeItem('FBIdToken');

            }

        } else {

            if(!login){

                localStorage.removeItem('tokenExpired');
                axios.defaults.headers.common['Authorization'] = token;
                store.dispatch(getUserData({ onLogin: true }));
                login = true

            }

        }
    }
};

// Check token expiration every minute (adjust the interval as needed)
setInterval(checkTokenExpiration, 1000);
// window.addEventListener('contextmenu', (e) => {
//     e.preventDefault();
// });
export class App extends React.Component {
    // componentDidMount() {
    //     const token = localStorage.FBIdToken;
    
    //     if (token) {
    //       const decodedToken = jwtDecode(token);
    
    //       if (decodedToken.exp * 1000 < Date.now()) {
    //         store.dispatch(logoutUser());
    //       } else {
    //         axios.defaults.headers.common['Authorization'] = token;
    //         store.dispatch(getUserData({ onLogin: true }));
    //       }
    //     }
        
    // }
    
    render() {
        return (
            <Provider store={store}>
                
                <BrowserRouter >
                <Switch>
                    
                    <Route exact path="/">
                        <Redirect to="/auth/login" />
                    </Route>

                    <AuthRoute 
                        exact path="/auth/login" 
                        component={Login} 
                    />
                    <AuthRoute 
                        exact path="/auth/register" 
                        component={Register} 
                    />
                    <AuthRoute 
                        exact path="/auth/forgot-password" 
                        component={ForgotPassword} 
                    />
                    {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
                    {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}
                    {/* <Redirect from="/" to="/admin/index" /> */}

                    <AdminRoute
                        path="/admin"
                        component={Admin} 
                    />

                    <ClientRoute
                        path="/client"
                        component={Client} 
                        
                    />
                    <Route path="*">
                        <div>
                            <h1>404 - Page Not Found</h1>
                            <p>The page you're looking for doesn't exist.</p>
                        </div>
                    </Route>

                </Switch>
                </BrowserRouter>
            </Provider>
        )
    }
}
export default App

