import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    Row,
    Button,
    Input,
    Col,
    FormGroup,
    CardBody,
    CardFooter,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
} from "reactstrap";
import Swal from 'sweetalert2'
import { createPracticeTestResult } from 'redux/DAT/DATActions';
import { useDispatch, connect } from 'react-redux';
const TestCard = (props) => {
    // const questions = [
    //     { id: 1, question: 'What is the capital of France?', options: ['Paris', 'London', 'Berlin', 'Madrid'], correct: 'Paris', explanation: 'Paris is the capital and most populous city of France.' },
    //     { id: 2, question: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correct: '4', explanation: '2 + 2 equals 4.' },
    //     { id: 3, question: 'What is the capital of Spain?', options: ['Madrid', 'Rome', 'Athens', 'Lisbon'], correct: 'Madrid', explanation: 'Madrid is the capital and largest city of Spain.' },
    // ];

    const dispatch = useDispatch();
    
    const questions = props.questionList

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [markedQuestions, setMarkedQuestions] = useState([]);
    // const [highlightedText, setHighlightedText] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [results, setResults] = useState(questions.map(q => ({
        id: q.id,
        // question: q.question,
        questionData:q,
        marked: false,
        completed: false,
        skipped: false,
        selectedOption: null,
        correct: null,
        checked: false,
        explanationVisible: false,
        highlights: []
    })));
    const [modal, setModal] = useState(false);

    const [elapsedTime, setElapsedTime] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const timerRef = useRef(null);

    const toggleModal = () => {
        
        setModal(!modal);
        
    };
    
    useEffect(() => { 

        setIsTimerRunning(!modal); 

    }, [modal]);


    useEffect(() => {
        
        if (isTimerRunning) {
            timerRef.current = setInterval(() => {
                setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
            }, 1000);
        } else if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        return () => clearInterval(timerRef.current);
    }, [isTimerRunning]);

    const handleNext = () => {
        const updatedResults = [...results];
        // const currentQuestion = updatedResults[currentQuestionIndex];

        // if (selectedOption) {
        //     currentQuestion.completed = true;
        //     currentQuestion.skipped = false;
        //     currentQuestion.selectedOption = selectedOption;
        //     currentQuestion.correct = selectedOption === questions[currentQuestionIndex].correct;
        // } else {
        //     currentQuestion.skipped = true;
        // }

        setResults(updatedResults);

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedOption(updatedResults[currentQuestionIndex + 1].selectedOption);
        } else {
            toggleModal();
            // console.log("Results:", updatedResults);
        }

        console.log(results)
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            const updatedResults = [...results];
            // const currentQuestion = updatedResults[currentQuestionIndex];

            // if (selectedOption) {
            //     currentQuestion.selectedOption = selectedOption;
            // }

            setResults(updatedResults);
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setSelectedOption(updatedResults[currentQuestionIndex - 1].selectedOption);
        }
    };

    const handleCheck = () => {
        const updatedResults = [...results];
        const currentQuestion = updatedResults[currentQuestionIndex];

        if (selectedOption) {
            currentQuestion.correct = selectedOption === questions[currentQuestionIndex].correct;
            currentQuestion.checked = true;
            currentQuestion.explanationVisible = true;
            currentQuestion.skipped = false;
            currentQuestion.completed = true;
            currentQuestion.selectedOption = selectedOption;
        }
        else{
            currentQuestion.skipped = true;
        }

        setResults(updatedResults);

        
    };

    const handleMark = () => {
        const updatedResults = [...results];
        const currentQuestion = updatedResults[currentQuestionIndex];

        if (!currentQuestion.marked) {
            currentQuestion.marked = true;
            setMarkedQuestions([...markedQuestions, currentQuestion.id]);
        } else {
            currentQuestion.marked = false;
            setMarkedQuestions(markedQuestions.filter(id => id !== currentQuestion.id));
        }

        setResults(updatedResults);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    

    useEffect(() => {
        

        const handleMouseUp = (event) => {
            const selection = window.getSelection();
            const text = selection.toString();
            if (text.length > 0) {
                setSelectedText(text);
                setPopupVisible(true);
            } else {
                setPopupVisible(false);
            }
        };

        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
        
    }, []);

    // const handleHighlight = () => {
    //     const currentQuestionId = questions[currentQuestionIndex].id;
    //     const highlights = highlightedText[currentQuestionId] || [];
    //     setHighlightedText({
    //         ...highlightedText,
    //         [currentQuestionId]: [...highlights, selectedText],
    //     });

    //     setPopupVisible(false);
    // };

    const handleHighlight = () => {
        const updatedResults = [...results];
        const currentQuestion = updatedResults[currentQuestionIndex];
        currentQuestion.highlights = [...currentQuestion.highlights, selectedText];
        setResults(updatedResults);
        setPopupVisible(false);
    };

    // const handleRemoveHighlight = (highlightToRemove) => {
    //     const currentQuestionId = questions[currentQuestionIndex].id;
    //     const highlights = highlightedText[currentQuestionId] || [];
    //     setHighlightedText({
    //         ...highlightedText,
    //         [currentQuestionId]: highlights.filter(highlight => highlight !== highlightToRemove),
    //     });
    // };

    const handleRemoveHighlight = (highlightToRemove) => {
        const updatedResults = [...results];
        const currentQuestion = updatedResults[currentQuestionIndex];
        currentQuestion.highlights = currentQuestion.highlights.filter(highlight => highlight !== highlightToRemove);
        setResults(updatedResults);
    };

    // const getHighlightedText = (text, highlights) => {
    //     if (!highlights || highlights.length === 0) return text;

    //     let parts = [];
    //     let lastIndex = 0;

    //     const sortedHighlights = highlights.sort((a, b) => text.indexOf(a) - text.indexOf(b));

    //     sortedHighlights.forEach((highlight, index) => {
    //         const startIndex = text.indexOf(highlight, lastIndex);
    //         if (startIndex !== -1) {
    //             if (startIndex > lastIndex) {
    //                 parts.push(text.substring(lastIndex, startIndex));
    //             }
    //             parts.push(
    //                 <span
    //                     key={index}
    //                     style={{ backgroundColor: 'yellow', cursor: 'pointer' }}
    //                     onClick={() => handleRemoveHighlight(highlight)}
    //                 >
    //                     {highlight}
    //                 </span>
    //             );
    //             lastIndex = startIndex + highlight.length;
    //         }
    //     });

    //     if (lastIndex < text.length) {
    //         parts.push(text.substring(lastIndex));
    //     }

    //     return parts;
    // };

    const getHighlightedText = (text, highlights) => {
        if (!highlights || highlights.length === 0) return text;

        let parts = [];
        let lastIndex = 0;

        const sortedHighlights = highlights.sort((a, b) => text.indexOf(a) - text.indexOf(b));

        sortedHighlights.forEach((highlight, index) => {
            const startIndex = text.indexOf(highlight, lastIndex);
            if (startIndex !== -1) {
                if (startIndex > lastIndex) {
                    parts.push(text.substring(lastIndex, startIndex));
                }
                parts.push(
                    <span
                        key={index}
                        style={{ backgroundColor: 'yellow', cursor: 'pointer' }}
                        onClick={() => handleRemoveHighlight(highlight)}
                    >
                        {highlight}
                    </span>
                );
                lastIndex = startIndex + highlight.length;
            }
        });

        if (lastIndex < text.length) {
            parts.push(text.substring(lastIndex));
        }

        return parts;
    };

    const handleQuestionClick = (index) => {
        
    

        setCurrentQuestionIndex(index);
        setSelectedOption(results[index].selectedOption);

        toggleModal();
        
    };

    const currentQuestionId = questions[currentQuestionIndex].id;
    // const highlight = highlightedText[currentQuestionId] || [];
    const currentQuestion = results[currentQuestionIndex];

    const handleEnd = () => {

        
        const endDateFormatted = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        const correctAnswers = results.filter(result => result.correct).length;
        const scoreFraction = `${correctAnswers}/${questions.length}`;
        const scorePercentage = (correctAnswers / questions.length) * 100;
        const markedQuestions = results.filter(result => result.marked).length;
        Swal.fire({
            title: "End exam and show results?",
            text: "You'll be able to review your exam results on the next page.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "End Exam",
            cancelButtonText: "Not Yet"
        }).then((result) => {
            if (result.isConfirmed) {

                console.log(formatTimeSpent(elapsedTime),endDateFormatted, scoreFraction);

                let data = {};
                data.timeSpent = formatTimeSpent(elapsedTime);
                data.date = endDateFormatted;
                data.score = scoreFraction;
                data.results = results;
                data.markedQuestions = `${markedQuestions}/${questions.length}`;
                data.handle = props.userDetails.handle;
                data.docId = props.docId;
                dispatch(createPracticeTestResult({data:data, datName: props.datName}))
                toggleModal();

                
                // Swal.fire({
                //     title: "Exam Ended",
                //     text: `Total time spent: ${formatTimeSpent(elapsedTime)}\nEnd date: ${endDateFormatted}\nScore: ${scoreFraction} (${scorePercentage.toFixed(2)}%)`,
                //     icon: "success"
                // });
            }
        });

    }

    const formatTimeSpent = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        const pad = (num) => String(num).padStart(2, '0');
    
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };
    return (
        <>
            <Button className="shadow" color="info" onClick={toggleModal}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back</Button>
            <Card className='mt-3'>
                <CardHeader className="font-weight-bolder text-md text-default">
                    {props.subjectName} - Practice Test #{props.docId.split("practiceTest")[1]}
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="12 text-center font-weight-600 text-warning"><i className="far fa-clock"></i> Time Spent: {formatTimeSpent(elapsedTime)}</Col>
                        <Col sm="12" className='text-center mb-3 mt-3'><h2 className='text-default'>Question {currentQuestionIndex + 1} of {questions.length}</h2></Col>
                        <Col sm="12">
                            {/* <p className="font-weight-300 text-default font-weight-500">
                                {getHighlightedText((questions[currentQuestionIndex].question.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].question}></img> : questions[currentQuestionIndex].question), highlight)}
                            </p> */}

                            <p className="font-weight-700 text-default">
                                {getHighlightedText((questions[currentQuestionIndex].question.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].question} alt="question" /> : questions[currentQuestionIndex].question), currentQuestion.highlights)}
                            </p>

                            {popupVisible && (
                                <Button color="warning" className='btn-sm' onClick={handleHighlight}>Highlight</Button>
                            )}

                            {questions[currentQuestionIndex].additionalQuestionImage ? <img src={questions[currentQuestionIndex].additionalQuestionImage}></img> : ""}
                        </Col>
                        <Col sm="12">
                            <FormGroup tag="fieldset">
                                {questions[currentQuestionIndex].options.map((option, index) => (
                                    <FormGroup check className='pt-2' key={index}>
                                        <Input
                                            name="radio1"
                                            type="radio"
                                            value={option}
                                            checked={selectedOption === option}
                                            onChange={handleOptionChange}
                                            disabled={currentQuestion.checked}
                                        />
                                        <Label check className='font-weight-bold text-default'>
                                            {String.fromCharCode(65 + index)}.&nbsp;

                                            {option.includes("https://storage.googleapis.com") ? <img src={option}></img> : option}
                                            
                                        </Label>
                                    </FormGroup>
                                ))}
                            </FormGroup>
                            {!currentQuestion.checked && <button className='btn btn-primary mb-3' onClick={handleCheck}>Check</button>}
                            {currentQuestion.checked && !currentQuestion.correct && (
                                <p className="text-danger font-weight-600">Incorrect! The correct answer is {questions[currentQuestionIndex].correct.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].correct}></img> : questions[currentQuestionIndex].correct}.</p>
                            )}
                            {currentQuestion.checked && currentQuestion.correct && <p className="text-success font-weight-600">Correct!</p>}
                            {currentQuestion.explanationVisible && (
                                <p className="text-default font-weight-400 font-italic">{questions[currentQuestionIndex].explanation.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].explanation}></img> : questions[currentQuestionIndex].explanation}</p>
                            )}
                            <h3 className='text-default mt-5'>Click on the `Next` button to continue.</h3>
                            
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className='text-center'>
                    <button className='btn btn-info' onClick={toggleModal}>Review</button>
                    <button className={markedQuestions.includes(currentQuestionId) ? 'btn btn-danger' : 'btn btn-primary'} onClick={handleMark}>
                        {markedQuestions.includes(currentQuestionId) ? "Marked" : "Mark"}
                    </button>
                    <button className='btn btn-warning' onClick={handlePrevious} disabled={currentQuestionIndex === 0}>Previous</button>
                    
                    <button className='btn btn-success' onClick={handleNext} >
                        {currentQuestionIndex === questions.length - 1 ? "Next" : "Next"}
                    </button>
                </CardFooter>
            </Card>

            <Modal size='lg' className="modal-dialog-centered" isOpen={modal} toggle={toggleModal} >
                <ModalHeader toggle={toggleModal}>Review Answers</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12">
                            <div className='overflow-auto' style={{maxHeight:"50vh"}}>
                                <Table className="align-items-center rounded" striped responsive>
                                    <thead className='bg-default text-white'>
                                        <tr>                                        
                                            <th>Name</th>
                                            <th className='text-center'>Marked</th>
                                            <th className='text-center'>Completed</th>
                                            <th className='text-center'>Correct</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.map((result, index) => (
                                            <tr key={index} onClick={() => handleQuestionClick(index)}>
                                                <td role="button" className='text-primary'><i className="fas fa-info-circle"></i> Question {index + 1}</td>
                                                <td className='text-center'>{result.marked ? <i className="fas fa-bookmark text-warning"></i> : <i className="far fa-bookmark"></i>}</td>
                                                <td className='text-center'>{result.completed ? <span className='text-success font-weight-bolder'>Yes</span> : "No"}</td>
                                                <td className='text-center'>{result.correct !== null ? result.correct ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i> : <i className="fas fa-window-minimize"></i>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={handleEnd}>End</Button>
                    <Button color="info" onClick={toggleModal}>Continue</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
        userDetails : state.loginAuth.userDetails,
    }
}

export default connect(mapStateToProps)(TestCard);


