import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form } from 'reactstrap';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Months from 'components/Options/Months';
import Years from 'components/Options/Years';
// import AchievementsTypes from 'components/Options/Achievements/AchievementsTypes';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2'
import { createAchievement, createAchievementReset, editAchievement, newAchievements,updateAchievement, updateAchievementReset } from 'redux/Achievements/AchievementsActions';

import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
import ReactDatetime from "react-datetime";

const AchievementsModal = (props) => {

    let toggleAddAchievements = props.toggleAddAchievements;
    let modalAddAchievements = props.modalAddAchievements;

    const dispatch = useDispatch();
    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const messageRequired = "This field is required.";
    const messageHoursValidation = "Hours must be between 0 and 99999";
    
    const [achievementId, setAchievementId] = useState('');


    const [years, setYears] = useState([]);

    const validationForStartDate = (value, inputEndDateName, inputStartDateName, sign) => {

        let endDate = new Date(Date.parse("1 " + months[getValues(inputEndDateName+"EndMonth") - 1 ] + " " + getValues(inputEndDateName+"EndYear")));
        let startDate =  new Date(Date.parse("1 " + months[getValues(inputStartDateName+"StartMonth") - 1 ] + " " + value));
        
        if(sign == "less-than"){
          return endDate <= startDate;
        }
        else{
          return endDate >= startDate;
        }
    }



    const achievementType = [
      {"label" : "Awards", "value" : "Awards"},
      {"label" : "Honors", "value" : "Honors"},
      {"label" : "Scholarships", "value" : "Scholarships"}
    ]

    const typeOfRecognitionArr = [
      {"label" : "Compensated", "value" : "Compensated"},
      {"label" : "Received Academic Credit", "value" : "Received Academic Credit"},
      {"label" : "Volunteer", "value" : "Volunteer"}
    ]

    useEffect(() => {
        
        register("inputAchievementType" , { required: messageRequired });
        register("inputAchievementName" , { required: messageRequired });
        register("inputAchievementNameOfOrg" );
        register("inputAchievementIssuedDate" );
        register("inputAchievementBriefDescription" );


    },[])    


    useEffect(() => {
      if(props.editAchievement.status){
          
        const achievementData  = findAchievement(props.fetchAchievements.achievements,props.editAchievement.achievementId);

        setAchievementId(props.editAchievement.achievementId);

        setValue("inputAchievementType", achievementData.achievementType);
        setValue("inputAchievementName", achievementData.achievementName);
        setValue("inputAchievementNameOfOrg", achievementData.achievementNameOfOrg);
        setValue("inputAchievementIssuedDate", achievementData.achievementIssuedDate);
        setValue("inputAchievementBriefDescription", achievementData.achievementBriefDescription);

        dispatch(editAchievement({
            status : false,
            achievementId : ""
        }));

      }

      

    },[props.editAchievement])
    
    useEffect(() => {
      if(props.newAchievements.status){
       
        
        setAchievementId('');

        setValue("inputAchievementType", "" );
        setValue("inputAchievementName", "" );
        setValue("inputAchievementNameOfOrg", "" );
        setValue("inputAchievementIssuedDate", "" );
        setValue("inputAchievementBriefDescription", "" );


        
        dispatch(newAchievements({status : false}));
      

      }
    }, [props.newAchievements])
    

    if(props.createAchievements.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.createAchievements.achievement.length && typeof props.createAchievements.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added new Achievement!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(createAchievementReset());
        
      }

      else if(typeof props.createAchievements.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to add new Achievement!',
            icon :'error',
            timer: 3000
            
          }
        )     
        dispatch(createAchievementReset());       
        
      }

    }


    if(props.updateAchievements.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.updateAchievements.achievement.length && typeof props.updateAchievements.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully updated Achievement!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(updateAchievementReset());
        
        
      }

      else if(typeof props.updateAchievements.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to update Achievement!',
            icon :'error',
            timer: 3000
          }
        )            

        dispatch(updateAchievementReset());
        
      }

      

    }


    const findAchievement = (arr,achievementId) => {
      
      return arr.find(x => x.achievementId === achievementId);
    }

    


    const onSubmit = (data) => {
      
      // data.preventDefault();
  
      
      // let inputExperienceDetailsTypeOfRecognitionArr = [];
      // data.inputExperienceDetailsTypeOfRecognition.map((value) => {
        
      //   inputExperienceDetailsTypeOfRecognitionArr.push(value.value)
      // })

      // data.inputExperienceDetailsTypeOfRecognition = inputExperienceDetailsTypeOfRecognitionArr

      // console.log(data.inputExperienceDetailsTypeOfRecognition)
      // return false
      
      console.log(data)

      data['userId'] = props.userDetails.userId;
      
      if(achievementId){
        data['docId'] = achievementId;
        
        
        dispatch(updateAchievement(data));
      }
      else{
        
        dispatch(createAchievement(data));
      }
      
    }


    const countWords = (str) => {
      str = str.trim();

      // Return 0 if the string is empty
      if (str === "") {
        return 0;
      }
      
      // Split the string into an array of words using whitespace as the delimiter
      const words = str.split(/\s+/);
      
      // Return the number of words
      return words.length;

    }


    return (
        
      <div>
        
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddAchievements} toggle={toggleAddAchievements} >
          <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{achievementId ? "Update" : "Add" } Achievements</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                
                  <h6 className="heading-small text-muted mb-4">
                    Achivement Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAchievementType"
                          >
                            Types <span className='text-danger'>*</span>
                          </label>

                          <Select 
                            placeholder='Select Experience Type'
                            options={achievementType} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputAchievementType", e.value)}
                            
                            value={achievementType.find((option) => option.value === watch("inputAchievementType"))}
                          />

                        { errors.inputAchievementType && <small className="text-danger">{errors.inputAchievementType.message}</small> }
                        </FormGroup>
                        
                      </Col>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAchievementName"
                          >
                            Name <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputAchievementName"
                            name="inputAchievementName"
                            value={watch("inputAchievementName")}
                            onChange={e => setValue("inputAchievementName", e.target.value)}
                            type="text"
                          />
                          { errors.inputAchievementName && <small className="text-danger">{errors.inputAchievementName.message}</small> }
                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAchievementNameOfOrg"
                          >
                            Name of Presenting Organization
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputAchievementNameOfOrg"
                            name="inputAchievementNameOfOrg"
                            value={watch("inputAchievementNameOfOrg")}
                            onChange={e => setValue("inputAchievementNameOfOrg", e.target.value)}
                            type="text"
                          />
                          { errors.inputAchievementNameOfOrg && <small className="text-danger">{errors.inputAchievementNameOfOrg.message}</small> }
                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAchievementIssuedDate"
                          >
                            Issued Date
                          </label>


                          <ReactDatetime value={watch("inputAchievementIssuedDate")}  onChange={e => setValue("inputAchievementIssuedDate", new Date(e.toJSON()).toLocaleDateString("en-US", { month: '2-digit', day: '2-digit', year: 'numeric' }))}  timeFormat={false} className="w-100" inputProps={{placeholder:"Select Date"}}/>

                          { errors.inputAchievementIssuedDate && <small className="text-danger">{errors.inputAchievementIssuedDate.message}</small> }

                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAchievementBriefDescription">
                            Brief description
                          </label>
                            <Input
                                id="inputAchievementBriefDescription"
                                name="inputAchievementBriefDescription"
                                value={watch("inputAchievementBriefDescription")}
                                placeholder=""
                                rows="5"
                                type="textarea"
                                onChange={e => setValue("inputAchievementBriefDescription", e.target.value)}
                            />
                            <div className='text-sm'>Character count : {(typeof getValues("inputAchievementBriefDescription") == "undefined")  ? "0" : getValues("inputAchievementBriefDescription").length} | Word Count : {countWords((typeof getValues("inputAchievementBriefDescription") == "undefined")  ? "" : getValues("inputAchievementBriefDescription"))}</div>
                            { errors.inputAchievementBriefDescription && <small className="text-danger">{errors.inputAchievementBriefDescription.message}</small> }   
                        </FormGroup>
                      </Col>


                    </Row>
                  </div>
                  
                  

              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            {props.adminUser.admin ? "" :
              <>
              <Button color="primary" type='submit'>
              {achievementId ? "Update" : "Save" } 
              </Button>{' '}
              <Button color="secondary" onClick={toggleAddAchievements}>
                Cancel
              </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
      </div>
    );

}

const mapStateToProps = state => {
  return {
    createAchievements : state.createAchievements,
    updateAchievements : state.updateAchievements,
    editAchievement : state.editAchievement,
    fetchAchievements : state.fetchAchievements,
    newAchievements : state.newAchievement,
    userDetails : state.loginAuth.userDetails,
    adminUser : state.adminUser

  }
}


export default connect(mapStateToProps)(AchievementsModal)