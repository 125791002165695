/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
Button,
CustomInput,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardTitle,
	CardFooter,
  Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Swal from 'sweetalert2'
// core components
import UserHeader from "components/Headers/UserHeader";
import '../../assets/css/profile.css'
import { useRef, useEffect, useState } from "react";
import { createProfilePicture, createProfilePictureReset, updateUserProfile, updateUserProfileReset, fetchUniversities, updateUserCommunitySettings, updateUserCommunitySettingsReset } from "redux/Users/UsersActions";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { CountryDropdown } from 'react-country-region-selector';
import { City, State, Country } from 'country-state-city';
import ForgotPasswordModal from "components/Modal/ForgotPasswordModal";
import { updateForgotPassword } from "redux/UserAuthentication/ForgotPasswordActions";
import { schoolYear } from "util/DataList";
import Select from 'react-select';
import { optionCountries } from "util/DataList";
import {customDropdown} from "util/reactCustomStyles";
import ManageSubscriptionModal from "components/Modal/ManageSubscriptionModal";
import BuyTutoringHoursModal from "components/Modal/BuyTutoringHoursModal";
import {manageSubscription, buyTutoringHours} from "redux/Payments/PaymentsActions"
import { useHistory } from 'react-router-dom';
import { studentsLifetimePlan } from "util/DataList";
import { fetchLtpItem } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";
const Profile = (props) => {
  
  const history = useHistory();

  const fileInputRef = useRef(null)

  const dispatch = useDispatch();

  const handleClickUpload = (e) => {
    e.preventDefault()
    fileInputRef.current.click();
  };

  const [profilePictureFilename, setProfilePictureFilename] = useState('')

  const [universities, setUniversities] = useState([])

  useEffect(() => {
      dispatch(fetchLtpItem())
  }, [])
        
  useEffect(() => {
      if (props.fetchLtpItem.ltpitems.length) {
          props.fetchLtpItem.ltpitems.forEach((item)=>{
              if(props.userDetails.email == item.email){
                  console.log(item.email)
                  setSubscribe('unlock')
              }
          })  
      }
  }, [props.fetchLtpItem, props.userDetails]);

  

  useEffect(() => {
    if(props.fetchUniversities.universities.length){
      let universitiesList = props.fetchUniversities.universities[0].universities.sort()

      universitiesList = [...new Set(universitiesList.map((value, index) => {return {value:value, label: value}}))];
      
      setUniversities(universitiesList)
    }
  }, [props.fetchUniversities.universities])

  useEffect(() => {
    dispatch(fetchUniversities({}));

    if(typeof props.userDetails.profilePicture != 'undefined'){
      
      setProfilePictureFilename(props.userDetails.profilePicture)
      
    }


    if(props.updateUserProfile.userprofile.length){

      setSelectedCountry(props.updateUserProfile.userprofile[0].selectedCountry)
      setSelectedCity(props.updateUserProfile.userprofile[0].selectedCity)
      setSelectedState(props.updateUserProfile.userprofile[0].selectedState)
      setPostalCode(props.updateUserProfile.userprofile[0].postalCode)
      setHomeAddress(props.updateUserProfile.userprofile[0].homeAddress)
      setFullName(props.updateUserProfile.userprofile[0].fullName)
      setShadowHours(props.updateUserProfile.userprofile[0].shadowHours)
      setClinicalHours(props.updateUserProfile.userprofile[0].clinicalHours)
      setMcatDate(props.updateUserProfile.userprofile[0].mcatDate)
      setVolunteerHours(props.updateUserProfile.userprofile[0].volunteerHours)

      setSchoolYear(props.updateUserProfile.userprofile[0].schoolYear)
      setUniversity(props.updateUserProfile.userprofile[0].university)

      setPhoneNumber(props.updateUserProfile.userprofile[0].phoneNumber)
    }
    else{
      
      if(typeof props.userDetails.selectedCountry != 'undefined'){
        setSelectedCountry(props.userDetails.selectedCountry)
        
      }
  
      if(typeof props.userDetails.selectedCity != 'undefined'){
        setSelectedCity(props.userDetails.selectedCity)
      }

      if(typeof props.userDetails.selectedState != 'undefined'){
        setSelectedState(props.userDetails.selectedState)
      }
  
      if(typeof props.userDetails.postalCode != 'undefined'){
        setPostalCode(props.userDetails.postalCode)
      }
  
      if(typeof props.userDetails.homeAddress != 'undefined'){
        setHomeAddress(props.userDetails.homeAddress)
      }

      if(typeof props.userDetails.phoneNumber != 'undefined'){
        setPhoneNumber(props.userDetails.phoneNumber)
      }
      
      if(typeof props.userDetails.schoolYear != 'undefined'){
        setSchoolYear(props.userDetails.schoolYear)
      }
  
      if(typeof props.userDetails.university != 'undefined'){
        setUniversity(props.userDetails.university)
      }

      setFullName(props.userDetails.name)
      setShadowHours(props.userDetails.shadowGoal)
      setVolunteerHours(props.userDetails.volunteerGoal)
      setClinicalHours(props.userDetails.clinicalGoal)
      setMcatDate(props.userDetails.mcatDate)

    }
    
    
    

  }, [])
  

  const [phoneNumber, setPhoneNumber] = useState('');

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [fullName, setFullName] = useState('');
  const [mcatDate, setMcatDate] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [shadowHours, setShadowHours] = useState('');
  const [clinicalHours, setClinicalHours] = useState('');
  const [volunteerHours, setVolunteerHours] = useState('');

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const [schoolYr, setSchoolYear] = useState('');
  const [university, setUniversity] = useState('');

  const handleCountryChange = (selectedOption) => {
    
    setSelectedCountry(selectedOption.value);
    setSelectedCity('');
   
    // if (selectedCountry) {
    //   const city = City.getCitiesOfCountry(selectedCountry);
    //   setCityList(city.map((state) => state.name))
    // }

  };


  useEffect(() => {
    if (selectedCountry) {
      const country = Country.getCountryByCode(selectedCountry);
      const state = State.getStatesOfCountry(country.isoCode);
      const states = [...new Set(state.map((state) => {return {value:state.isoCode, label: state.name}}))];
      
      setStateList(states)
    }
  }, [selectedCountry])

  useEffect(() => {
    
    if (selectedState) {
      const country = Country.getCountryByCode(selectedCountry);
      const city = City.getCitiesOfState(country.isoCode,selectedState);
      const cities = [...new Set(city.map((city) => {return {value:city.name, label: city.name}}))];
      setCityList(cities)
    }
  }, [selectedState])
  
  const handleStateChange = (selectedOption) => {

    setSelectedState(selectedOption.value);
  };

  const handleSchoolYearChange = (value) => {

    setSchoolYear(value);
  };


  const handleUniversityChange = (selectedOption) => {

    setUniversity(selectedOption.value);
  };


  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption.value);
  };

  const handleImageUpload = (e) => {
    const profilePicture = e.target.files[0];


    // Check the file size
    const fileSizeInMB = profilePicture.size / (1024 * 1024);
    const maxSizeInMB = 10;
    if (fileSizeInMB > maxSizeInMB) {
        Swal.fire({
            title: 'Failed!',
            text: 'File size exceeds the maximum limit of 10MB.',
            icon : 'error',
            timer: 3000
            
            }
        )
        return;
    }

    // Check if the file type is an image
    const allowedTypes = ['image/jpg', 'image/png', 'image/jpeg',];
    if (!allowedTypes.includes(profilePicture.type)) {
      
        Swal.fire({
            title: 'Failed!',
            text: 'Only image files are allowed.',
            icon: 'error',
            timer: 3000
        });
        return;
    }

    const formData = new FormData();
    formData.append('file', profilePicture);
    formData.append('handle', props.userDetails.handle);

    dispatch(createProfilePicture(formData))


  }

  useEffect(() => {
    if(props.createProfilePicture.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.createProfilePicture.profilepicture.length && typeof props.createProfilePicture.error === "undefined" && props.createProfilePicture.done){
          
        Swal.fire({
            title: 'Success!',
            text: 'Successfully save profile picture!',
            icon : 'success',
            timer: 3000      
          }
        ).then(()=>{
          setProfilePictureFilename(props.createProfilePicture.profilepicture[0].profilePicture);
        }).then(()=>{
          dispatch(createProfilePictureReset());
        })
        
        
      }
    
      else if(typeof props.createProfilePicture.error !== "undefined"  && props.createProfilePicture.done){
          
          Swal.fire({
              title:'Failed!',
              text:'Failed to save profile picture!',
              icon :'error',
              timer: 3000 
              }
          ).then(()=>{
            dispatch(createProfilePictureReset());
          })            
          
      }
    }
  }, [props.createProfilePicture])

  useEffect(() => {
    if(props.updateUserProfile.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.updateUserProfile.userprofile.length && typeof props.updateUserProfile.error === "undefined" && props.updateUserProfile.done){
          
        Swal.fire({
            title: 'Success!',
            text: 'Successfully update informations!',
            icon : 'success',
            timer: 3000      
          }
        ).then(()=>{
          
          setSelectedCountry(props.updateUserProfile.userprofile[0].selectedCountry)
          setSelectedCity(props.updateUserProfile.userprofile[0].selectedCity)
          setSelectedState(props.updateUserProfile.userprofile[0].selectedState)
          setPostalCode(props.updateUserProfile.userprofile[0].postalCode)
          setHomeAddress(props.updateUserProfile.userprofile[0].homeAddress)
          setFullName(props.updateUserProfile.userprofile[0].fullName)
          setShadowHours(props.updateUserProfile.userprofile[0].shadowHours)
          setClinicalHours(props.updateUserProfile.userprofile[0].clinicalHours)
          setVolunteerHours(props.updateUserProfile.userprofile[0].volunteerHours)
          setMcatDate(props.updateUserProfile.userprofile[0].mcatDate)

          setPhoneNumber(props.updateUserProfile.userprofile[0].phoneNumber)

        }).then(() => {

          dispatch(updateUserProfileReset());

        })
        

        
      }
    
      else if(typeof props.updateUserProfile.error !== "undefined" && props.updateUserProfile.done){
          
          Swal.fire({
              title:'Failed!',
              text:'Failed to update informations!',
              icon :'error',
              timer: 3000 
              }
          ).then(() => {

            dispatch(updateUserProfileReset());
  
          })
      }
    }
  }, [props.updateUserProfile])
	
	
	 useEffect(() => {
    if(props.updateUserCommunitySettings.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.updateUserCommunitySettings.updateusercommunitysettings.length && typeof props.updateUserCommunitySettings.error === "undefined" && props.updateUserCommunitySettings.done){
          
        Swal.fire({
            title: 'Success!',
            text: 'Successfully update informations!',
            icon : 'success',
            timer: 3000      
          }
        ).then(()=>{
			


        }).then(() => {

          dispatch(updateUserCommunitySettingsReset());

        })
        

        
      }
    
      else if(typeof props.updateUserCommunitySettings.error !== "undefined" && props.updateUserCommunitySettings.done){
          
          Swal.fire({
              title:'Failed!',
              text:'Failed to update informations!',
              icon :'error',
              timer: 3000 
              }
          ).then(() => {

            dispatch(updateUserCommunitySettingsReset());
  
          })
      }
    }
  }, [props.updateUserCommunitySettings])


  const handleClickUpdate = (e) => {
    e.preventDefault();
    
    if(fullName.length){
      const data = {
        handle : props.userDetails.handle,
        selectedCity : selectedCity,
        selectedCountry : selectedCountry,
        selectedState : selectedState,
        fullName  : fullName,
        mcatDate : mcatDate,
        homeAddress : homeAddress,
        postalCode : postalCode,
        shadowHours : shadowHours,
        clinicalHours : clinicalHours,
        volunteerHours : volunteerHours,
        schoolYear : schoolYr,
        university : university,
        phoneNumber : phoneNumber
  
      }
      
      dispatch(updateUserProfile(data))

    }
    

  }

  const modalForgotPassword = props.updateForgotPasswordModalStatus.status;
  const modalManageSubscription = props.manageSubscriptionModalStatus.status;
  const modalBuyTutoringHoursModal = props.buyTutoringHoursModalStatus.status;

  const toggleForgotPassword = () =>{
    dispatch(updateForgotPassword(!props.updateForgotPasswordModalStatus.status));
    
  } 

  const toggleManageSubscription = () =>{
    dispatch(manageSubscription(!props.manageSubscriptionModalStatus.status));
    
  } 
  
  const toggleBuyTutoringHoursModal = () =>{
    dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
    
  } 

  const [subscribe, setSubscribe] = useState('lock');

  const fetchSubscriptionDetails = () => {
    if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true" || studentsLifetimePlan.includes(props.userDetails.email)){

      setSubscribe('unlock')

    }
    else{   
      if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

      const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

      
      
          if(subsDetails.status == "active"){
              setSubscribe('unlock')
          }
          else{

              const date = new Date();
              const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
              
              if(subsDetails.cancel_at_period_end){
                  if(secondsSinceEpoch > subsDetails.canceled_at){
                      setSubscribe('lock')
                  }
                  else{
                      setSubscribe('unlock')
                  }
              }
              else{
                  setSubscribe('lock')
              }
          
          }

      

      }
      else{
          setSubscribe('lock')
      }
    }
        
  }

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [])

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [props.fetchDetailsSubscription])

	
	const [switched, setSwitch] = useState(false);	
  const [anonymousName, setAnonymousName] = useState('');
  const [alias, setAlias] = useState('');
	const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

	const toggleSetAnonymousName = () => {
    setBackdrop(true);
    setAnonymousName(alias)
    setModal(true)
	}

	useEffect(() => {
    if (props.updateUserCommunitySettings.updateusercommunitysettings.length) {
      if (typeof props.updateUserCommunitySettings.updateusercommunitysettings[0].anonymousPost && props.updateUserCommunitySettings.updateusercommunitysettings[0].anonymousPost === true) {
          console.log(props.updateUserCommunitySettings.updateusercommunitysettings)
        setSwitch(props.updateUserCommunitySettings.updateusercommunitysettings[0].anonymousPost)
      } else {
          console.log('not')
      }
    } else {
        if (typeof props.userDetails.anonymousPost !== "undefined" && props.userDetails.anonymousPost === true) {
            console.log(props.userDetails)
            setSwitch(props.userDetails.anonymousPost)
        } else {
            console.log('not2')
        }
    }
         
	}, [])

  useEffect(() => {
    if (props.updateUserCommunitySettings.updateusercommunitysettings.length) {
      if (typeof props.updateUserCommunitySettings.updateusercommunitysettings[0].anonymousName) {
          console.log(props.updateUserCommunitySettings.updateusercommunitysettings)
        setAnonymousName(props.updateUserCommunitySettings.updateusercommunitysettings[0].anonymousName)
        setAlias(props.updateUserCommunitySettings.updateusercommunitysettings[0].anonymousName)
      } else {
        setAnonymousName(fullName)
         setAlias(fullName)
          console.log('not')
      }
    } else {
        if (typeof props.userDetails.anonymousName !== "undefined" ) {
            console.log(props.userDetails)
          setAnonymousName(props.userDetails.anonymousName)
           setAlias(props.userDetails.anonymousName)
        } else {
      
            console.log('not2')
        }
    }

	
	}, [])

	const toggleASwitch = (v) => {
		setSwitch(v)
	}

  const onClickUpdateAnonymousName = () => {
    if (anonymousName) {
      const data = {
        anonymousPost: switched,
        anonymousName: anonymousName,
        handle: props.userDetails.handle
      }
     
      dispatch(updateUserCommunitySettings(data))
      setModal(!modal)
      setAlias(anonymousName)
    } else {
      
       Swal.fire({
        title: 'Required name field!',
        text: 'This field cannot be empty',
        icon : 'info',
       })
      
      
     
    }
		
    }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7 mb-5" fluid>
        <Row >
          <Col className="order-xl-2 mb-0 mb-xl-0" xl="4">
            <Row>
              <Col xl="12">
                <Card className="card-profile shadow">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                    
                    {
                    
                    profilePictureFilename ? 
                    <div className="card-profile-div shadow">
                        <div className="card-profile-image">
                          
                            <div className="profile-picture" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/` + profilePictureFilename+`)`}}></div>
                          
                        </div>
                      </div>
                    
                    : <div className="card-profile-image">
                        <a  onClick={(e) => e.preventDefault()}>
                          
                          <div className="rounded-circle profile-letter">{props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].fullName.trim()[0] : props.userDetails.name.trim()[0]}</div>
                        </a>
                      </div>
                      
                      }
                      
                      
                    </Col>
      
                  </Row>
                  <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    {/* <div className="d-flex justify-content-between">
                      <Button
                        className="mr-4"
                        color="info"
                        
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        Connect
                      </Button>
                      <Button
                        className="float-right"
                        color="default"
                        
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        Message
                      </Button>
                    </div> */}
                  </CardHeader>
                  <CardBody className="pt-0 pt-md-4">
            
                    <div className="text-center mt-md-6">
                      <h3>
                        My Profile Picture
                      </h3>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        JPG or PNG no larger than 10 MB
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <a  className={props.adminUser.admin ? "btn btn-primary disabled" : "btn btn-primary" } onClick={(e) => handleClickUpload(e)}>
                        Upload New Image
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12">
                <Card className="card-stats mb-4 mb-xl-0 mt-3">
                  <CardBody>
                    <Row>
                        <div className="col">
                          <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                          >
                              Premium Tutoring Hours Available
                          </CardTitle>
                          
                          {subscribe == "unlock" ? 
                            <>
                              <span className="h2 font-weight-bold mb-0 text-primary">
                                  { props.updateTutoringHoursAvailable.updatetutoringhoursavailable.length ? props.updateTutoringHoursAvailable.updatetutoringhoursavailable[0].tutorHours:  props.userDetails.tutorHours }
                                  
                              </span>
                              
                              <br/>
                              <a className={props.adminUser.admin ?"btn btn-primary mt-2 disabled" : "btn btn-primary mt-2"} onClick={(e) => {e.preventDefault(); toggleBuyTutoringHoursModal()}}>GET TUTORING HOURS</a>
                            </>
                          :
                          <a className={ props.adminUser.admin ? "btn btn-info mt-2 disabled" : "btn btn-info mt-2"} onClick={(e) => {e.preventDefault(); history.push('/client/plans')}}><span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO PRO</a>
                          }

                        </div>

                        <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-glasses"></i>
                        </div>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12">
                <Card className="card-stats mb-4 mb-xl-0 mt-3 shadow">
                  <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            UWorld Subscription
                        </CardTitle>
                        {props.userDetails.ultimateAdvisingStudent ? 
                          <span className={props.userDetails.subscriptions.includes('uworld') ? "h2 font-weight-bold mb-0 text-info" : "h2 font-weight-bold mb-0 text-danger"} >
                              {props.userDetails.subscriptions.includes('uworld') ? "CLAIMED" : "UNCLAIMED"}
                              
                          </span>
                          :
                          <a onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}} className={props.adminUser.admin ? "h4 mt-3 font-weight-bold mb-0 btn btn-danger disabled" : "h4 mt-3 font-weight-bold mb-0 btn btn-danger"}><span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO ULTIMATE ADVISING STUDENT</a>
                        }
                          </div>
                       
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-globe-americas"></i>
                        </div>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12">
                <Card className="card-stats mb-4 mb-xl-0 mt-3 shadow">
                  <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            AAMC Subscription
                        </CardTitle>
                        {props.userDetails.ultimateAdvisingStudent ? <span className={props.userDetails.subscriptions.includes('aamc') ? "h2 font-weight-bold mb-0 text-info" : "h2 font-weight-bold mb-0 text-danger"}>
                          {props.userDetails.subscriptions.includes('aamc') ? "CLAIMED" : "UNCLAIMED"}
                            
                        </span>
                         :
                         <a onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}} className={props.adminUser.admin ? "h4 mt-3 font-weight-bold mb-0 btn btn-danger disabled" : "h4 mt-3 font-weight-bold mb-0 btn btn-danger"}><span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO ULTIMATE ADVISING STUDENT</a>
                       }
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-globe-americas"></i>
                        </div>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12">
                <Card className="card-stats mb-4 mb-xl-0 mt-3 shadow">
                  <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-2"
                        >
                            Security SETTING
                        </CardTitle>
                        <a className={props.adminUser.admin ?"btn btn-primary mt-2 disabled" : "btn btn-primary mt-2"} onClick={(e) => {e.preventDefault(); toggleForgotPassword()}}>CHANGE PASSWORD</a>
                       
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-lock"></i>
                        </div>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
						  </Col>
						   <Col xl="12">
                <Card className="card-stats mb-4 mb-xl-0 mt-3 shadow">
                  <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-3"
                        >
                            Community Settings
                        </CardTitle>

						<FormGroup >
						<CustomInput 
							type="switch"
							id="exampleCustomSwitch1"
							name="customSwitch"
							checked={switched}
							onChange={(e) => toggleSetAnonymousName()}
					   		label="Turn on this switch to post as anonymous in the Community"
							// label={switched === true ? "Anonymous name will display when you post in the Community Page" : "Turn on this switch to post as anonymous in the Community"}
						/>
												  
						
						</FormGroup>
						<FormGroup >
							<label
								className="form-control-label"
								htmlFor="input-username"
							>
								Create an anonymous name to display in Community
							</label>
						<Input
              className="form-control-alternative"
              
              id="anonymous-name"
              placeholder={fullName}
              type="text" 
              defaultValue={alias}
							// onChange={(e) => setAnonymousName(e.target.value)}
							onClick={(e) => {e.preventDefault(); toggleSetAnonymousName()}}
							/> 
						</FormGroup>
					
                       
                        </div>
                        {/* <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-lock"></i>
                        </div>
                        </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              
              
             

              {
                props.userDetails.ultimateAdvisingStudent || studentsLifetimePlan.includes(props.userDetails.email) ? 
                "" :
                <Col xl="12">
                  <Card className="card-stats mb-4 mb-xl-0 mt-3 shadow">
                    <CardBody>
                      <Row>
                          <div className="col">
                          <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-2"
                          >
                              My Plan
                          </CardTitle>
                          
                          <>
                          {
                            subscribe == "unlock"
                            ?  <a className={ props.adminUser.admin ? "btn btn-primary mt-2 disabled" : "btn btn-primary mt-2"} onClick={(e) => {e.preventDefault(); toggleManageSubscription()}}>MANAGE SUBSCRIPTION</a> : 
                          (props.fetchDetailsSubscription.fetchdetailssubscription.length && props.fetchDetailsSubscription.fetchdetailssubscription[0].status != "incomplete") ? 
                          <a className={ props.adminUser.admin ? "btn btn-primary mt-2 disabled" : "btn btn-primary mt-2"} onClick={(e) => {e.preventDefault(); toggleManageSubscription()}}>MANAGE SUBSCRIPTION</a> : <a className={ props.adminUser.admin ? "btn btn-info mt-2 disabled" : "btn btn-info mt-2"} onClick={(e) => {e.preventDefault(); history.push('/client/plans')}}><span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO PRO</a>
                          }
                          </>
                          
                          </div>
                          <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-wallet"></i>
                          </div>
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                
              }
          
            </Row>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className=" shadow">
              <CardHeader className=" ">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    {/* <Row> */}
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="lucky.jesse"
                            id="input-username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      
                    {/* </Row> */}
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Full Name <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            defaultValue={ fullName }
                            onChange={(e) => setFullName(e.target.value)}
                          />
                          {!fullName.length ? <small className="text-danger">This field is required.</small> : "" } 
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control"
                            
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            defaultValue={ props.userDetails.email }
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            TPC ENTRY DATE
                          </label>
                          <Input
                            className="form-control"
                            type="date"
                            defaultValue={props.userDetails.tpcEntryDate}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            MCAT DATE
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={mcatDate}
                            onChange={(e) => setMcatDate(e.target.value)}
                            type="date"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            School Year
                          </label>
                          {/* <select
                              id="input-school-year"
                              className="form-control form-control-alternative"
                              value={schoolYr}
                              onChange={(e) => handleSchoolYearChange(e.target.value)}
                            >
                              <option value="">Select School Year</option>
                              {schoolYear.map((schoolyear, index) => (
                                <option key={schoolyear + index} value={schoolyear}>
                                  {schoolyear}
                                </option>
                              ))}
                          </select> */}

                          <Select 
                            placeholder="Select School Year" 
                            options={schoolYear} 
                            styles={customDropdown }  
                            onChange={(e) => handleSchoolYearChange(e.value)}
                            menuPortalTarget={document.body}
                            value={schoolYear.find((option) => option.value === schoolYr)}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            University
                          </label>
                          {/* <select
                              id="input-university"
                              className="form-control form-control-alternative"
                              value={university}
                              onChange={(e) => handleUniversityChange(e.target.value)}
                            >
                              <option value="">Select University</option>
                              {universities.map((university, index) => (
                                <option key={university + index} value={university}>
                                  {university}
                                </option>
                              ))}
                          </select> */}

                          <Select 
                            placeholder="Select University" 
                            options={universities} 
                            styles={customDropdown }  
                            onChange={handleUniversityChange}
                            menuPortalTarget={document.body}
                            value={universities.find((option) => option.value === university)}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="phone-number"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            
                            defaultValue={phoneNumber}
                            placeholder="Phone Number"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            
                            defaultValue={homeAddress}
                            placeholder="Home Address"
                            onChange={(e) => setHomeAddress(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            Country
                          </label>
                          {/* <CountryDropdown
                            priorityOptions={["US"]}
                            id="input-country"
                            className="form-control form-control-alternative"
                            value={selectedCountry}
                        
                            valueType="short"
                            onChange={(value) => handleCountryChange(value)}
                          /> */}

                          <Select 
                            placeholder="Select Country" 
                            options={optionCountries} 
                            styles={customDropdown }  
                            onChange={handleCountryChange}
                            menuPortalTarget={document.body}
                            value={optionCountries.find((option) => option.value === selectedCountry)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-city">
                            State
                          </label>
                          {/* <select
                              id="input-state"
                              className="form-control form-control-alternative"
                              value={selectedState}
                              onChange={(e) => handleStateChange(e.target.value)}
                            >
                             

                              <option value="">Select State</option>
                              {stateList.map((state, index) => (
                                <option key={state.name + index} value={state.isoCode}>
                                  {state.name}
                                </option>
                              ))}
                          </select> */}


                            <Select 
                              placeholder="Select State" 
                              options={stateList} 
                              styles={customDropdown }  
                              onChange={handleStateChange}
                              menuPortalTarget={document.body}
                              value={stateList.find((option) => option.value === selectedState)}
                            />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-city">
                            City
                          </label>
                          {/* <select
                              id="input-state"
                              className="form-control form-control-alternative"
                              value={selectedCity}
                              onChange={(e) => handleCityChange(e.target.value)}
                            >
                              <option value="">Select City</option>
                              {cityList.map((city, index) => (
                                <option key={city + index} value={city}>
                                  {city}
                                </option>
                              ))}
                          </select> */}
                          <Select 
                              placeholder="Select City" 
                              options={cityList} 
                              styles={customDropdown }  
                              onChange={handleCityChange}
                              menuPortalTarget={document.body}
                              value={cityList.find((option) => option.value === selectedCity)}
                            />
                        </FormGroup>
                      </Col>
          
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Postal code
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={postalCode}
                            placeholder="Postal code"
                            onChange={(e) => setPostalCode(e.target.value)}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Other information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                           SHADOW HOURS (GOAL)
                          </label>
                          <Input
                            className="form-control-alternative"
                            
                            defaultValue={shadowHours}
                            placeholder="0"
                            type="number"
                            onChange={(e) => setShadowHours(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                           CLINICAL HOURS (GOAL)
                          </label>
                          <Input
                            className="form-control-alternative"
                            
                            defaultValue={clinicalHours}
                            placeholder="0"
                            type="number"
                            onChange={(e) => setClinicalHours(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                           VOLUNTEER HOURS (GOAL)
                          </label>
                          <Input
                            className="form-control-alternative"
                            
                            defaultValue={volunteerHours}
                            placeholder="0"
                            type="number"
                            onChange={(e) => setVolunteerHours(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>


                </Form>
              </CardBody>
              <CardFooter className="text-right ">

                <Button color="primary" disabled = { props.adminUser.admin ? "true" : "" } type='submit' onClick={(e) => handleClickUpdate(e)}>
                      Update
                </Button>
       

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>

      <ForgotPasswordModal modalForgotPassword={modalForgotPassword} toggleForgotPassword={toggleForgotPassword} changePassword={true} email={props.userDetails.email}/> 
      <ManageSubscriptionModal modalManageSubscription={modalManageSubscription} toggleManageSubscription={toggleManageSubscription}/>   

		  <BuyTutoringHoursModal modalBuyTutoringHoursModal={modalBuyTutoringHoursModal} toggleBuyTutoringHoursModal={toggleBuyTutoringHoursModal} /> 

		 <Modal isOpen={modal} toggle={modalToggle}  backdrop={backdrop} size="lg">
                <ModalHeader toggle={modalToggle}>Community Settings</ModalHeader>
			  <ModalBody>
				<FormGroup >
					<CustomInput 
					type="switch"
					id="exampleCustomSwitch"
					name="customSwitch"
					checked={switched}
					onChange={(e) => toggleASwitch(e.target.checked)}					  
					label="Turn on this switch to post as anonymous in the Community" />
											
				
				</FormGroup>
				<FormGroup >
				<label
					className="form-control-label"
					htmlFor="input-username"
				>
					Create a anonymous name to display in Community
				</label>
				<Input
					className="form-control-alternative"
					
					id="anonymous-name"
					placeholder={fullName}
					type="text"
					value={anonymousName}
					onChange={(e) => setAnonymousName(e.target.value)}
					
					/> 
				</FormGroup>
                </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={modalToggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={e => onClickUpdateAnonymousName(e)}>
                    Save
                </Button>{' '}
                
            </ModalFooter>
        </Modal>
      
    
    </>
  );
};

const mapStateToProps = state  => {
  return {
    userDetails : state.loginAuth.userDetails,
    createProfilePicture : state.createProfilePicture,
    updateUserProfile: state.updateUserProfile,
    updateUserCommunitySettings : state.updateUserCommunitySettings,
    updateForgotPasswordModalStatus : state.updateForgotPasswordModalStatus,
    fetchUniversities : state.fetchUniversities,
    manageSubscriptionModalStatus : state.manageSubscriptionModalStatus,
    fetchDetailsSubscription : state.fetchDetailsSubscription,
    buyTutoringHoursModalStatus : state.buyTutoringHoursModalStatus,
    updateTutoringHoursAvailable : state.updateTutoringHoursAvailable,
    adminUser : state.adminUser,
    fetchLtpItem: state.fetchLtpItem,
  }
    
}

export default connect(mapStateToProps)(Profile);
