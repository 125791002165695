import React, {useEffect} from 'react'
import Header from 'components/Headers/Header'
import {useForm} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Form,
    Input,
    Col,
    FormGroup
} from "reactstrap";
import { covid19Impact, covid19ImpactReset, fetchCovid19Impact } from 'redux/Covid19Impact/Covid19ImpactActions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import Plans from './Plans';
const Covid19Impact = (props) => {

    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    
    useEffect(() => {
        register("inputCovid19Impact");
        dispatch(fetchCovid19Impact({handle: props.userDetails.handle}))
        
        const statement_val = typeof props.fetchCovid19Impact.covid19impact.statement !== "undefined" ? props.fetchCovid19Impact.covid19impact.statement : "";
        setValue("inputCovid19Impact", statement_val)
            

    }, [props.fetchCovid19Impact.covid19impact.statement,dispatch]);

    const onSubmit = (data) => {

        const userData = {
            handle: data.email,
            statement: data.inputCovid19Impact
        }

        dispatch(covid19Impact(userData));
        
    }

    if(props.covid19Impact.loading){
        Swal.fire({
          allowOutsideClick : false,
          didOpen: () => {
            Swal.showLoading()
            
          }
        });
    
      }
      else{
      
        
        if(props.covid19Impact.covid19impact.length && typeof props.covid19Impact.error === "undefined"){
          Swal.fire({
              title: 'Success!',
              text: 'Covid-19 impact essay successfully save!',
              icon : 'success',
              timer: 3000
              
            }
          )
    
          dispatch(covid19ImpactReset());
          
        }
    
        else if(typeof props.covid19Impact.error !== "undefined"){
    
         
            Swal.fire({
              title:'Failed!',
              text:'Something went wrong, Please try again!',
              icon :'error',
              timer: 3000
              
            })
          
              
            dispatch(covid19ImpactReset());       
          
        }
    
      }
      const countWords = (str) => {
        str = str.trim();
  
        // Return 0 if the string is empty
        if (str === "") {
          return 0;
        }
        
        // Split the string into an array of words using whitespace as the delimiter
        const words = str.split(/\s+/);
        
        // Return the number of words
        return words.length;
  
      }
    return (
        <>
            <Header></Header>
                <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <div className='float-left'>
                                        <h5 className="h3 mb-0">COVID-19 Impact</h5>
                                    </div>
                                    <div className='float-right'>
                                        <Button color="primary" disabled={props.adminUser.admin ? "true" : ""} type='submit'>Save</Button>
                                    </div>
                                </CardHeader>
                                <Row className="pl-4 pr-4">
                                    <Col lg='12'>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                              COVID-19 has broadly impacted the world in 2020. If you want, you can use this space to describe how COVID-19 has impacted your preparation for the application to dental school to up this point. Each dental school will determine how a response to this question will be considered as part of their admissions process. You may update the text at any time prior to submission. Once you have submitted, your response will be locked for editing.
                                                
                                            </label>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                              You might consider these questions as you formulate your response:
                                                
                                            </label>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                              <strong>Academic:</strong> Did your school move to offering only online coursework? Did you have to leave an academic program? were the grades for your coursework, especially prerequisites, affected? Was your Dental Admission Test (DAT) exam cancelled or delayed? Did you lose the ability to interact with professors or advisors?
                                                
                                            </label>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                              <strong>Professional:</strong> Was your job, paid or volunteer, affected? Did you lose a job or seek new opportunities? Was your shadowing experience shortened or modified? Were your financial responsibilities shifted or increased?
                                                
                                            </label><label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                              <strong>Personal:</strong> Did you have to move out of a house or dorm? Were you impacted by travel our of the country? Did you seek out volunteer opportunities that arose from the crisis? Did you help family members who were affected?
                                                
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="inputCovid19Impact"
                                                name="inputCovid19Impact"
                                                placeholder="Write a text here ..."
                                                rows="15"
                                                value={watch("inputCovid19Impact")}
                                                onChange={e => setValue("inputCovid19Impact", e.target.value)}
                                                type="textarea"
                                                maxLength="2500"
                                            />
                                            <div className='text-sm'>Character count : {(typeof getValues("inputCovid19Impact") == "undefined")  ? "0" : getValues("inputCovid19Impact").length}/2500 | Word Count : {countWords((typeof getValues("inputCovid19Impact") == "undefined")  ? "0" : getValues("inputCovid19Impact"))}</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </Form>
                    </div>
                </Row>
            </Container>
            <Plans></Plans>
        </>
    )
}

const mapStateToProps = state => {
    return {
        covid19Impact : state.covid19Impact,
        userDetails : state.loginAuth.userDetails,
        fetchCovid19Impact : state.fetchCovid19Impact,
        adminUser : state.adminUser
    }
}

export default connect(mapStateToProps)(Covid19Impact)