import React, {useState, useEffect} from 'react'
import Header from 'components/Headers/Header';
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    CardImg,
    Media
} from "reactstrap";
import Swal from 'sweetalert2'
import { connect, useDispatch } from 'react-redux';
import { fetchTpcCourses, deleteTpcCourse, deleteTpcCourseReset } from 'redux/ManageTpcCourses/ManageTpcCoursesActions'

import TpcCoursesLessons from 'components/Pages/TpcCoursesLessons';
import { studentsLifetimePlan } from "util/DataList";
import { useHistory } from 'react-router-dom';
const TpcCourses = (props) => {

    const [modalAddTpcCourseModal, setModalAddTpcCourseModal] = useState(false);
    const [tpcCoursesLists, setTpcCoursesLists] = useState([]);

    const history = useHistory();
    
    const toggleAddTpcCourseModal = () =>{
        
        setModalAddTpcCourseModal(!modalAddTpcCourseModal);
        
    } 

    const dispatch = useDispatch()
    
    useEffect(() => {

        dispatch(fetchTpcCourses({}))
        fetchSubscriptionDetails()
        
    }, [])
    
    useEffect(() => {
        
        if(props.fetchTpcCourses.tpccourses.length){
            

            setTpcCoursesLists(props.fetchTpcCourses.tpccourses)
            
        }
        
    }, [props.fetchTpcCourses ])
    


    const [startCourseData, setStartCourseData] = useState({});

    const handleStartCourse = (e, course) => {
        
        const lessonCount = typeof course.category != "undefined" ? course.category.reduce((totalPublishedLessons, category) =>
        totalPublishedLessons + (category.lessons && category.lessons.filter(lesson => lesson.visibility === "published").length),
            0
        ): 0
        
        if(lessonCount == 0){
            Swal.fire({
                title:'Sorry!',
                text:'This course currently has no lessons.',
                icon :'warning',
                timer: 3000
                
              }
            )
        }
        else{
            setStartCourseData(course)
        }
        
        
    }

    const handleBackToTpcCourses = (e) => {
        e.preventDefault()
        setStartCourseData({})
    }

    const [subscribe, setSubscribe] = useState('lock');
    const [ultimateSubscribe, setUltimateSubscribe] = useState('lock');
    const fetchSubscriptionDetails = () => {
        // if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true" || studentsLifetimePlan.includes(props.userDetails.email)){
        if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true"){
            // setSubscribe('unlock')
            setUltimateSubscribe('unlock')
        }
        else{   
            if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

                const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

                if(subsDetails.status == "active"){
                    setSubscribe('unlock')
                }
                else{

                    const date = new Date();
                    const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                    
                    if(subsDetails.cancel_at_period_end){
                        if(secondsSinceEpoch > subsDetails.canceled_at){
                            setSubscribe('lock')
                        }
                        else{
                            setSubscribe('unlock')
                        }
                    }
                    else{
                        setSubscribe('lock')
                    }
                
                }

            

            }
            else{
                setSubscribe('lock')
            }
        }
            
    }
    useEffect(() => {

        fetchSubscriptionDetails()
    
    }, [props.fetchDetailsSubscription])
    

    return (
        <>
            
            {Object.keys(startCourseData).length !== 0 ? <div className="header bg-gradient-info pb-5 pt-5 pt-md-8">
                <Container className="" fluid>
                    <Row>
                        <Col lg="8" md="11">
                            <h1 className="display-1 text-white mb-4 font-weight-bolder">{startCourseData.courseTitle}</h1>
                            
                            <Button className="shadow" color="default" onClick={(e) => handleBackToTpcCourses(e)}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back to TPC Courses</Button>
                            
                        </Col>
                    </Row>
                </Container>
            </div> : "" }
            {Object.keys(startCourseData).length === 0? <>
            <Header></Header>
            <Container className="mt--7" fluid>
                <Row className='mb-3'>
                    <Col>
                        <Card className="shadow bg-info rounded-pill">
                            <CardHeader className="border-0 bg-info rounded-pill">
                                {/* <div className='float-left'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        TPC Courses
                                    </h6>
                                    <h2 className="mb-0">Courses</h2>
                                </div> */}
                                <h1 className="display-4 text-white mb-0 text-uppercase font-italic font-weight-bolder text-center">TPC COURSES</h1>
                                {/* <div className='float-right'>
                                    <Button color="primary" onClick={toggleAddTpcCourseModal}>Create Course</Button>
                                </div> */}
                            </CardHeader>
                            {/* <CardBody>
                                
                                
                            </CardBody> */}
                        </Card>
                        
                    </Col> 
                </Row>

                <Row>
                    { 
                        !tpcCoursesLists.length ? 
                        <Col md="12 mt-3">
                            <Card className='shadow'>
                                <CardBody className='text-center'>
                                    <h2 className='mb-0'>No courses listed.</h2>
                                </CardBody>
                            </Card>
                        </Col>:
                        tpcCoursesLists.map((c, i) => {
                            return (
                                <Col lg="6" xl="4" md="12" key={i}  className='mb-3 '>
                                    <Card className='shadow '>
                                        {c.courseThumbnail != "" ? 
                                        
                                            <CardImg
                                                alt="..."
                                                src={`https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + c.courseThumbnail+``}
                                                
                                                top
                                                style={{height : "15em", objectFit: "fill", width:"100%"}}
                                            />
                                            
                                            :

                                            <CardImg
                                                alt="..."
                                           
                                                src={require("assets/img/brand/no-image.jpg")}
                                                top
                                                style={{height : "15em", objectFit: "fill", width:"100%"}}
                                            />

                                        }
                                        <CardBody>
                                            
                                            <CardTitle className='text-truncate text-center mb-0'><h2 className='mb-0 text-default font-weight-bolder'>{c.courseTitle}</h2></CardTitle>
                                            <div className='text-center mt-3'>
                                                <div className=' d-flex justify-content-center align-items-center'>
                                                    {/* {c.instructorHeadshot ? 
                    
                                                    <div className="card-profile-div-md">
                                                        <div className="card-profile-image">
                                                        
                                                            <div className="profile-picture-sm profile-picture-md" style={{backgroundImage: `url(https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + c.instructorHeadshot+`)`}}></div>
                                                        
                                                        </div>
                                                    </div>
                                                    
                                                    :

                                                    <div className="card-profile-image ">
                                                   
                                                        
                                                        <div className="rounded-circle profile-letter-sm-primary profile-picture-md ">{c.instructorName.trim()[0]}</div>
                                             
                                                    </div>} */}

                                                    <Media className="align-items-center">
                                                        {c.instructorHeadshot ? <div className="card-profile-div-sm card-profile-div-md">
                                                            <div className="card-profile-image">
                                                            
                                                                <div className="profile-picture-sm profile-letter-md profile-picture-md" style={{backgroundImage: `url(https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + c.instructorHeadshot+`)`}}></div>
                                                            
                                                            </div>
                                                        </div> :
                                                        <div className="card-profile-image ">
                                                            {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                                            
                                                            <div className="rounded-circle profile-letter-sm-primary profile-picture-md">{c.instructorName.trim()[0]}</div>
                                                            {/* </a> */}
                                                        </div>}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Media className="ml-2 d-block text-left">
                                                            {/* <span className="mb-0 text-sm font-weight-bold">
                                                            {c.instructorName}
                                                            </span> */}
                                                            <p className='mb-0 text-primary'>Instructor</p>
                                                            <h4 className='text-default font-weight-bolder'>{c.instructorName}</h4>
                                                        </Media>
                                                    </Media>
                                                </div>
                                                {/* <div className=''>
                                                    <p className='text-center  mb-0'>Instructor</p>
                                                    <h4 className='text-center text-info'>{c.instructorName}</h4>
                                                    
                                                </div> */}
                                            </div>
                                            <hr className='mb-3 mt-3'></hr>
                                            <Row >
                                                <div className='col-4 d-flex justify-content-center align-items-center'>
                                                    <div className='text-center'>
                                                    
                                                    <label className="font-weight-bold mb-0 text-warning">{typeof c.category != "undefined" ? c.category.reduce((totalPublishedLessons, category) =>
                                                        totalPublishedLessons + (category.lessons && category.lessons.filter(lesson => lesson.visibility === "published").length),
                                                        0
                                                    ) : "0"}</label>&nbsp;
                                                    <h4 className='mb-0'>Lessons</h4>
                                                    </div>
                                                </div>
                                                <div  className='text-center mb-2 col-8'>

                                                    {c.members == "free" || ultimateSubscribe == "unlock" || subscribe == "unlock"?
                                                    
                                                        <Button
                                                            color="default"
                                                            
                                                            className='w-100  font-weight-bolder'
                                                            onClick={e => handleStartCourse(e, c)}
                                                            >
                                                            <i className="fas fa-play-circle"></i> &nbsp;Start Course
                                                        </Button>
                                                    :
                                                        c.members == "ultimate" ? 

                                                        <Button
                                                            color="info"
                                                            
                                                            className={ props.adminUser.admin ? "w-100  font-weight-bolder disabled" : "w-100  font-weight-bolder"}
                                                            onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}}
                                                            >
                                                            <i className="fas fa-lock text-yellow"></i> &nbsp;Ultimate
                                                        </Button>

                                                        :

                                                        <Button
                                                            color="info"
                                                            
                                                            className={ props.adminUser.admin ? "w-100  font-weight-bolder disabled" : "w-100  font-weight-bolder"}
                                                            onClick={(e) => {e.preventDefault(); history.push('/client/plans')}}
                                                            >
                                                            <i className="fas fa-lock text-yellow"></i> &nbsp;Pro
                                                        </Button>
                                                    }
                                                </div>
                                                
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        }) 
                    }
                </Row>
            </Container>
            </>
            : <Container className="mt-4" fluid><TpcCoursesLessons courseData={startCourseData} ></TpcCoursesLessons></Container>}
            
        
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchTpcCourses : state.fetchTpcCourses,
        createTpcCourse : state.createTpcCourse,
        updateTpcCourse : state.updateTpcCourse,
        deleteTpcCourse : state.deleteTpcCourse,
        fetchDetailsSubscription : state.fetchDetailsSubscription,
        adminUser : state.adminUser
    }
      
}

export default connect(mapStateToProps)(TpcCourses)
