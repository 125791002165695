import React, { useState, useEffect } from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Form,
    Input,
    Col,
    FormGroup,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import Header from 'components/Headers/Header'
import Payment from './Payment';
import '../../assets/css/custom-modal.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect,useDispatch } from 'react-redux';
import { studentsLifetimePlan } from 'util/DataList';
import { fetchLtpItem } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";
const Plans = (props) => {
    const dispatch = useDispatch();
    const [plan , setPlan] = useState('')
    
    const [subscribe, setSubscribe] = useState('lock');

    const fetchSubscriptionDetails = () => {
        
        if(props.userDetails.ultimateAdvisingStudent === true || props.userDetails.ultimateAdvisingStudent == "true"  || studentsLifetimePlan.includes(props.userDetails.email)){

            setSubscribe('unlock')

        }
        else{
            if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

            const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

            
            
                if(subsDetails.status == "active"){
                    setSubscribe('unlock')
                }
                else{

                    const date = new Date();
                    const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                    
                    if(subsDetails.cancel_at_period_end){
                        if(secondsSinceEpoch > subsDetails.canceled_at){
                            setSubscribe('lock')
                        }
                        else{
                            setSubscribe('unlock')
                        }
                    }
                    else{
                        setSubscribe('lock')
                    }
                
                }

            

            }
            else{
                setSubscribe('lock')
            }
        }
    }

    const [proMember, setProMember] = useState("")
        useEffect(() => {
        dispatch(fetchLtpItem())
    }, [])
        
    useEffect(() => {
        if (props.fetchLtpItem.ltpitems.length) {
            props.fetchLtpItem.ltpitems.forEach((item)=>{
                if(props.userDetails.email == item.email){
                    console.log(item.email)
                    setSubscribe('unlock')
                }
            })  
        }
    }, [props.fetchLtpItem, props.userDetails]);

    // useEffect(() => {

    // fetchSubscriptionDetails()

    // }, [])

    useEffect(() => {

    fetchSubscriptionDetails()

    }, [props.fetchDetailsSubscription])

    return (    
    <>  
        {subscribe == "lock" ?
        <>
        
        
            <div className="custom-modal parent-of-modal">
            <div className="custom-modal-backdrop" >
            <div className="custom-modal-content">
            <Container fluid>
                 {plan == ""? 
                 <>
                <Row>
                    <div className="col">
                        <Row>
                            <Col lg="12" className='mt-3'>
                                <Card className='border-0 shadow '>
                                    <CardBody>
                                        <Row>
                                            <Col lg="12">
                                                <p className='display-3 mb-0 text-center text-dark'>Choose your Pre-Dental Tracker Plan</p>
                                                <p className='text-center h3 text-gray'>Empower your medical journey through tailored subscription plans crafted for aspiring doctors.</p>
                                            </Col>
                                        </Row>
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Row>
                <Row className='mt-4'>
                    <div className="col">
                        {/* <Card>
                            <Row className='p-1'>
                                <Col xs="4"><a className={plan == "predentalTrackerProPlanMonthly"  ? 'btn btn-info form-control' : 'btn btn-secondary text-gray form-control'} onClick={e => {e.preventDefault(); setPlan('predentalTrackerProPlanMonthly')}}>Monthly</a></Col>
                                <Col xs="4"><a className={plan == "predentalTrackerProPlanYearly"  ? 'btn btn-warning form-control' : 'btn btn-secondary text-gray form-control'} onClick={e => {e.preventDefault(); setPlan('predentalTrackerProPlanYearly')}}>Yearly</a></Col>
                                <Col xs="4"><a className={plan == "predentalTrackerProPlanLifetime"  ? 'btn btn-danger form-control' : 'btn btn-secondary text-gray form-control'} onClick={e => {e.preventDefault(); setPlan('predentalTrackerProPlanLifetime')}}>Lifetime</a></Col>
                            </Row>
                        </Card> */}
                        <Row>    
                            {/* <Col xl="4" lg="4" md="12" className='d-flex mt-3'>
                                <Card className={plan == "predentalTrackerProPlanMonthly"  ? 'border-0 shadow w-100 justify-content-center bg-info text-white' : 'border-0 shadow w-100 justify-content-center bg-transparent text-light'}>
                                    <CardHeader className='bg-transparent rounded border-0' >
                                        <p className="font-weight-bolder mb-0 text-center">Pro Plan Monthly</p>
                                        <p className="display-1 mb-0 text-center">$15</p>
                                        <p className='display-4 font-italic text-center'>per month</p>
                                    </CardHeader>
                                </Card>
                            </Col>
                            <Col xl="4" lg="4" md="12" className='d-flex mt-3'>
                                <Card className={plan == "predentalTrackerProPlanYearly"  ? 'border-0 shadow w-100 justify-content-center bg-warning text-white' : 'border-0 shadow w-100 justify-content-center bg-transparent text-light'}>
                                    <CardHeader className='bg-transparent rounded border-0'>
                                        <p className="font-weight-bolder mb-0 text-center">Pro Plan Yearly</p>
                                        <p className="display-1 mb-0 text-center">$150</p>
                                        <p className='display-4 font-italic text-center'>per year - $12.50/month</p>
                                    </CardHeader>
                                </Card>
                            </Col> 
                            <Col xl="4" lg="4" md="12" className='d-flex mt-3'>
                                <Card className={plan == "predentalTrackerProPlanLifetime"  ? 'border-0 shadow w-100 justify-content-center bg-danger text-white' : 'border-0 shadow w-100 justify-content-center bg-transparent text-light'}>
                                    <CardHeader className='bg-transparent rounded border-0'>
                                        <p className="font-weight-bolder mb-0 text-center">Pro Plan Lifetime</p>
                                        <p className="display-1 mb-0 text-center">$297</p>
                                        <p className='display-4 font-italic text-center mb-0'>one-time</p>
                                        <p className='h2 text-yellow text-center'>(limited to first 100 students)</p>
                                    </CardHeader>
                                </Card>
                            </Col>  */}
                            
                            {/* <Col xl="4" lg="6" md="12" className='d-flex mt-3'>
                                <Card className='border-0 shadow '>
                                    <CardHeader className='bg-info d-flex justify-content-center align-items-center' style={{minHeight:"250px"}}>
                                        <div>
                                            <h1 className="h2 mb-0 text-center text-white">Pro Plan Monthly</h1>
                                            <h2 className="display-2 mb-0 text-center text-white">$15</h2>
                                            <p className='display-4 font-italic text-center text-white'>per month</p>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="pl-2 pr-2 h-100">
                                            <Col lg='12' className='d-flex align-items-center flex-column h-100' style={{minHeight: "250px"}}>
                                            <h3 className="h3 mb-0 text-dark mb-3">Comprehensive package offering full access to all features and functionalities of the service, catering to users who want an all-inclusive experience with no limitations.</h3>
                                            <button className='btn btn-info mt-auto' disabled={props.adminUser.admin ? "true" : ""} onClick={e => {e.preventDefault(); setPlan('predentalTrackerProPlanMonthly')}}>Choose plan</button>
                                                
                                            </Col>
                                        </Row>
                                    </CardBody>

                                </Card>
                            </Col> */}
                            <Col  lg="6" md="12" className='d-flex mt-3'>
                                <Card className='border-0 shadow w-100'>
                                    <CardHeader className='bg-warning d-flex justify-content-center align-items-center' style={{minHeight:"250px"}}>
                                        <div>
                                            <h1 className="h2 mb-0 text-center text-white">Pro Plan Yearly</h1>
                                            <h2 className="display-2 mb-0 text-center text-white">$150</h2>
                                            <p className='display-4 font-italic text-center text-white mb-0'>per year</p>
                                            {/* <p className='h2 text-yellow text-center'>$12.50/month - save $30!</p> */}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="pl-2 pr-2 h-100">
                                            <Col lg='12' className='d-flex align-items-center flex-column h-100' style={{minHeight: "250px"}}>
                                            <h3 className="h3 mb-0 text-dark mb-3">Comprehensive package offering full access to all features and functionalities of the service, catering to users who want an all-inclusive experience with no limitations.</h3>
                                            <button className='btn btn-warning mt-auto' disabled={props.adminUser.admin ? "true" : ""} onClick={e => {e.preventDefault(); setPlan('predentalTrackerProPlanYearly')}}>Choose plan</button>
                                               
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    
                                </Card>
                            </Col>
                            <Col  lg="6" md="12" className='d-flex mt-3'>
                                <Card className='border-0 shadow w-100'>
                                    <CardHeader className='bg-red d-flex justify-content-center align-items-center' style={{minHeight:"250px"}}>
                                        <div>
                                            <h1 className="h2 mb-0 text-center text-white">Lifetime</h1>
                                            <h2 className="display-2 mb-0 text-center text-white">$297</h2>
                                            <p className='display-4 font-italic text-center text-white mb-0'>one-time</p>
                                            {/* <p className='h2 text-yellow text-center'>(limited to first 100 students)</p> */}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="pl-2 pr-2 h-100">
                                            <Col lg='12' className='d-flex align-items-center flex-column h-100' style={{minHeight: "250px"}}>
                                            <h3 className="h3 mb-0 text-dark mb-3">Unlock unlimited access to all features and services for a lifetime. Enjoy the convenience of a one-time payment while experiencing the full range of benefits without any time constraints.</h3>
                                            <button className='btn btn-danger mt-auto' disabled={props.adminUser.admin ? "true" : ""} onClick={e => {e.preventDefault(); setPlan('predentalTrackerProPlanLifetime')}}>Choose plan</button>
                                                
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Row>
                </>   
                : <Payment selectedPlan={plan}></Payment>} 
            </Container>
            </div>
            </div>
        </div>
    
        </>       
        : "" }             
    </>
  )
}

const mapStateToProps = state => {
    return {
      userDetails : state.loginAuth.userDetails,
      fetchDetailsSubscription : state.fetchDetailsSubscription,
      adminUser : state.adminUser,
      fetchLtpItem: state.fetchLtpItem,
    }
}

export default connect(mapStateToProps)(Plans)