import React, { useEffect, useState } from 'react'
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { useDispatch } from 'react-redux';
import { fetchAchievement, updateAchievementsModal, editAchievement, deleteAchievement, deleteAchievementReset, updateImportantAchievement } from 'redux/Achievements/AchievementsActions';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';

const AchievementsTable = (props) => {
    
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    const [mostImportant, setMostImportant] = useState(0);
    useEffect(() => {
        
        const data = {
            route : "",
            userId: props.userDetails.userId
        }
       
        dispatch(fetchAchievement(data))
        
        
    }, [])
    
    useEffect(() => {
        
        setList(props.fetchAchievements.achievements.sort((a, b) => a.isActive - b.isActive).slice(0, 5))
        
        
        let mostImportantCount = 0;
        props.fetchAchievements.achievements.map(value => {
            

            if(typeof value.mostImportant != "undefined"){
                if(value.mostImportant){
                    mostImportantCount++
                    
                }
            }

        })
        
        setMostImportant(mostImportantCount)


    }, [props.fetchAchievements])
        
    
    if(props.deleteAchievement.achievement.length && typeof props.deleteAchievement.error === "undefined"){
        
        Swal.fire({
            title: 'Deleted!',
            text: 'Successfully deleted Achievement!',
            icon : 'success',
            timer: 3000      
          }
        )
        dispatch(deleteAchievementReset());
        
        
      }

    else if(typeof props.deleteAchievement.error !== "undefined"){
        
        Swal.fire({
            title:'Failed!',
            text:'Failed to delete Achievement!',
            icon :'error',
            timer: 3000 
            }
        )            
        dispatch(deleteAchievementReset());
    }
    
    
    const onClickEditAchievement = (e,achievementId) => {
        e.preventDefault();
        dispatch(editAchievement({
            status : true,
            achievementId : achievementId
        }));

        dispatch(updateAchievementsModal(!props.updateAchievementModalStatus.status));
    }

    const onClickDeleteAchievement = (e,achievementId) => {
        e.preventDefault();
        

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAchievement({
                    docId : achievementId,
                    userId : props.userDetails.userId
                }));

            }
          })
    }
    
    const showNext = (e,item) => {
        e.preventDefault();
        
        if(list.length !== 0) {
            dispatch(fetchAchievement({route:"Next" , item:item.item, userId: props.userDetails.userId}))
            setList(props.fetchAchievements.achievements.sort((a, b) => a.isActive - b.isActive))
            setPage(page + 1)
        } 
    };

    const showPrevious = ( e,item ) => {
        e.preventDefault();
        if(list.length !== 0) {
            dispatch(fetchAchievement({route:"Previous", item:item.item, userId: props.userDetails.userId}))
            setList(props.fetchAchievements.achievements.sort((a, b) => a.isActive - b.isActive))
            setPage(page - 1)
        } 
    };

    const handleMostImportant = (e, achievementId, mostImportantVal) => {
        e.preventDefault();
        

        

        if(mostImportantVal){
            
            
            dispatch(updateImportantAchievement({docId : achievementId, mostImportant : mostImportantVal}))
        
        }
        else{
            
            if(mostImportant < 6){
                dispatch(updateImportantAchievement({docId : achievementId, mostImportant : mostImportantVal}))
            }
        }

    }

    useEffect(() => {
        
        setList(props.fetchAchievements.achievements.sort((a, b) => a.isActive - b.isActive).slice(0, 5))
        
        
    }, [props.fetchAchievements])

    useEffect(() => {
        
        const data = {
            route : "",
            userId: props.userDetails.userId
        }
       
        dispatch(fetchAchievement(data))
        
    }, [props.updateImportantAchievements])

    

    return (
    <>
        
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
                <tr>
                    
                    <th scope="col">MOST IMPORTANT ({mostImportant}/6)</th>
                    <th scope="col">NAME</th>
                    <th scope="col">PRESENTING ORGANIZATION</th>
                    <th scope="col">ISSUED DATE</th>
                    <th scope="col">ACTION</th>
                </tr>
            </thead>
            <tbody>
                
                
                {
                    list.map((val) => {
                                          
                    return <tr key = {val.achievementId}>
                            <td scope="row" className='text-center'>
                                <i class={val.mostImportant ? "fas fa-star text-xl text-yellow" : "fas fa-star text-xl"} role="button" onClick={e => handleMostImportant(e,val.achievementId, val.mostImportant)}></i> 
                            </td>
                            <td>
                                {val.achievementName}
                            </td>
                            <td>
                                {val.achievementNameOfOrg}
                            </td>
                            <td>
                                {val.achievementIssuedDate}
                            </td>
                            <td className="">

                                {props.adminUser.admin ?
                                    <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditAchievement(e,val.achievementId)}><i className="fa fa-eye"></i> View</a>
                                    :
                                    <>
                                        <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditAchievement(e,val.achievementId)}><i className="fa fa-pen"></i> Edit</a>
                                        <a href="#pablo" className="btn btn-sm btn-danger" onClick={(e) => onClickDeleteAchievement(e,val.achievementId)}><i className="fa fa-trash"></i> Delete</a>
                                    </>
                                }
                            </td>
                        </tr>
                    })

                }
                    
                    
                
            </tbody>
        </Table>
        <CardFooter className="py-4">
            <nav aria-label="...">
                <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
                >
                <PaginationItem className={page === 1 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showPrevious(e,{ item: list[0] })}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {/* <PaginationItem className="active">
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    3
                    </PaginationLink>
                </PaginationItem> */}
                <PaginationItem className={list.length < 5 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showNext(e,{ item: list[list.length - 1] })}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav>
        </CardFooter>
    </>
  )
}
const mapStateToProps = state => {
    return {
        fetchAchievements : state.fetchAchievements,
        updateAchievementModalStatus : state.updateAchievementsModalStatus,
        deleteAchievement : state.deleteAchievement,
        userDetails : state.loginAuth.userDetails,
        adminUser : state.adminUser,
        updateImportantAchievements : state.updateImportantAchievements
        
    }
  }


export default connect(mapStateToProps)(AchievementsTable)