import { SAVE_MANUAL_DEXTERITY_RESET, SAVE_MANUAL_DEXTERITY_REQUEST, SAVE_MANUAL_DEXTERITY_SUCCESS, SAVE_MANUAL_DEXTERITY_FAILURE, FETCH_MANUAL_DEXTERITY_FAILURE, FETCH_MANUAL_DEXTERITY_REQUEST, FETCH_MANUAL_DEXTERITY_SUCCESS } from './ManualDexterityTypes'

const initialManualDexterityState = {
    loading: false,
    manualdexterity: [],
}

const initialFetchManualDexterityState = {
    manualdexterity : [],
    loading: false,

}

export const manualDexterityReducer  = (state = initialManualDexterityState, action) => {
    switch (action.type) {
        case SAVE_MANUAL_DEXTERITY_RESET:
            return {
                loading: false,
                manualdexterity: [],
            }
        case SAVE_MANUAL_DEXTERITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SAVE_MANUAL_DEXTERITY_SUCCESS:
            return {
                loading: false,
                manualdexterity: [action.payload]
            }
        case SAVE_MANUAL_DEXTERITY_FAILURE:
            return {
                loading: false,
                manualdexterity: [],
                error: action.payload
            }
        default:
            return state;
    }
}


export const fetchManualDexterityReducer = (state = initialFetchManualDexterityState, action) => {
    switch (action.type) {
        case FETCH_MANUAL_DEXTERITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MANUAL_DEXTERITY_SUCCESS:
            return {
                loading: false,
                manualdexterity: action.payload
            }
        case FETCH_MANUAL_DEXTERITY_FAILURE:
            return {
                loading: false,
                manualdexterity: [],
                error: action.payload
            }
        default: return state;
    }
}