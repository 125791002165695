import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardFooter,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
    Table,
    Badge,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    UncontrolledTooltip,

} from "reactstrap";
import { app, db } from "../../firebase";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { createStudentToLifetimePlan } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";
import LifeTimePlanUsers from "components/Pages/LifeTimePlanUsers";
import {fetchUniversities}  from "redux/Users/UsersActions";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import {fetchAllUsers, updateMultiUsers,updateMultiUsersReset} from 'redux/ManageUsers/ManageUsersAction';

import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
const ManageUSerSettings = (props) => {
	const dispatch = useDispatch()

	const {
		register,
		getValues,
		unregister,
		watch,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const [modalUpdateMultiUsers, setModalUpdateMultiUsers] = useState(false);
  	const [selectedUsers, setselectedUsers] = useState([]);

	const [checkUsers, setCheckUsers] = useState([]);
	
	const selectUsers = (e) => {
	const values = [...e].map((opt) => opt.value);
		setselectedUsers(values);
		setValue("selectUsers", values);
	};

	const handleAssignedCoach = (selectedOption) => {
		setAssignedCoach(selectedOption.value);
		setValue("assignedCoach", selectedOption.value);
	};

  	const [coaches, setCoaches] = useState([]);
  	const [assignedcoach, setAssignedCoach] = useState("");
    
  	const updateMultipleUsers = () => {
		setValue("tutorHours", "");
		setValue("admin", "");
		setValue("coach", "");
		setValue("assignedCoach", "");
		setAssignedCoach("");
		setValue("selectUsers", "");
		setValue("ultimateAdvisingStudent", "");
		setModalUpdateMultiUsers(true);
	};

	const toggleUpdateMultiUsers = () => {
		setModalUpdateMultiUsers(!modalUpdateMultiUsers);
	};

	const onSubmitUpdate = (data) => {
		console.log(data)
		dispatch(updateMultiUsers(data));
	};

	useEffect(() => {
		const fetchAllUsers = async () => {
		const querySnapshot = await getDocs(collection(db, "users"));
		let users = [];
		querySnapshot.forEach((doc) => {
			let v = doc.data();
			users.push({
			value: v.handle,
			label: v.name,
			email: v.email,
			image: typeof v.profilePicture != "undefined" ? v.profilePicture : "",
			});
		});
			setCheckUsers(users);
		};
		fetchAllUsers();
	}, []);

	useEffect(() => {
		if (props.fetchCoaches.fetchcoaches.length) {
		const coachesArr = props.fetchCoaches.fetchcoaches[0];
		setCoaches(coachesArr);
		}
	}, [props.fetchCoaches]);

	 useEffect(() => {
        if (props.updateMultiUsers.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
			if(props.updateMultiUsers.multiusers.length){
				Swal.fire({
					title: 'Success!',
					text: 'Successfully updated users!',
					icon : 'success',
					timer: 3000      
				}).then(() => {
                    dispatch(updateMultiUsersReset());
                    dispatch(
                    fetchAllUsers({route: ""})
                    );
                });
			}
        }
    }, [props.updateMultiUsers]);
    



   return (
		<>
			<Row>
				<Col lg="4" className="p-2">
					<Card  className="shadow ">
						<CardBody>
							<Button
								className="mb-2"
                                color="primary"
                                onClick={(e) => updateMultipleUsers(e)}
                                >
								<i aria-hidden="true" className="fas fa-users mr-2"></i>
                                Update Multiple Users
                            </Button>
							<p>	Select multiple users and update their information at the same time.</p>
						</CardBody>
					</Card>
				</Col>
				<Col lg="8" className="p-2">
					<LifeTimePlanUsers />
				</Col>
			</Row>

			<Modal
				className="modal-dialog-centered"
				size="lg"
				isOpen={modalUpdateMultiUsers}
				toggle={toggleUpdateMultiUsers}
			>
				<ModalHeader toggle={toggleUpdateMultiUsers}>
					Update Multiple Users
				</ModalHeader>
				<Form onSubmit={handleSubmit(onSubmitUpdate)}>
					<ModalBody className="modal-body ">
						<Row>
							<Col>
								<FormGroup>
								<Select
									isMulti
									name="selectusers"
									options={checkUsers}
									className="basic-multi-select"
									classNamePrefix="select"
									placeholder="Select users..."
									onChange={selectUsers}
									formatOptionLabel={(v) => (
									<div className="d-flex ">
										{v.value ? (
										v.image ? (
											<img
											className="rounded-circle  mr-1 "
											style={{ width: "25px", height: "25px" }}
											src={
												"https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/" +
												v.image
											}
											alt={v.label}
											/>
										) : (
											<div
											className="rounded-circle profile-letter-all-users mr-1 "
											style={{ width: "25px", height: "25px" }}
											>
											{v.label ? v.label.trim()[0] : ""}
											</div>
										)
										) : (
										""
										)}

										<span>{v.label + '('+v.email+')'} </span>
									</div>
									)}
								/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<h6 className="heading-small text-muted">
								Select multiple users and update their information at the same time.
								</h6>
							</Col>
						</Row>
						<Row className="p-3">
							<Col lg="4">
								<FormGroup>
								<label
									className="form-control-label"
									htmlFor="ultimateAdvisingStudent"
								>
									Ultimate Advising Student
								</label>

								<select
									id="ultimateAdvisingStudent"
									name="ultimateAdvisingStudent"
									className="form-control form-control-alternative"
									onChange={(e) =>
									setValue("ultimateAdvisingStudent", e.target.value)
									}
								>
									<option value="false">No</option>
									<option value="true">Yes</option>
								</select>
								</FormGroup>
							</Col>
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="admin"
									>
										Set as Admin
									</label>
									<select
										id="admin"
										className="form-control form-control-alternative"
										value={watch("admin")}
										onChange={e => setValue("admin", e.target.value)}
										>
										<option value="false">No</option>
										<option value="true">Yes</option>
									</select>

								</FormGroup>
							</Col>
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="coach"
										>
										Set as Coach
									</label>
									<select
										id="coach"
										className="form-control form-control-alternative"
										value={watch("coach")}
										onChange={e => setValue("coach", e.target.value)}
										>
										<option value="false">No</option>
										<option value="true">Yes</option>
									</select>
								</FormGroup>
							</Col>
							<Col lg="6">
								<FormGroup>
									<label className="form-control-label" htmlFor="assignedCoach">
										Assigned Coach
									</label>
									<Select
										placeholder="Select Coach"
										options={coaches}
										// styles={customDropdown }
										id="assignedCoach"
										name="assignedCoach"
										onChange={handleAssignedCoach}
										value={coaches.find(
										(option) => option.value === assignedcoach
										)}
									/>
								</FormGroup>
							</Col>
							<Col lg="6">
								<FormGroup>
									<label className="form-control-label" htmlFor="tutorHours">
										Premium Tutoring Hours
									</label>
									<Input
										className="form-control-alternative is-invalid "
										id="tutorHours"
										name="tutorHours"
										value={watch("tutorHours")}
										placeholder="0"
										type="number"
										onChange={(e) => setValue("tutorHours", e.target.value)}
									/>
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={toggleUpdateMultiUsers}>Cancel</Button>
						<Button color="success" type="submit">Update</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</>
  	);
};

const mapStateToProps = (state) => {
  return {
	fetchAllUsers: state.fetchAllUsers,
    userDetails: state.loginAuth.userDetails,
    createStudentToLifetimePlan : state.createStudentToLifetimePlan,
    updateMultiUsers: state.updateMultiUsers,
    fetchCoaches: state.fetchCoaches,
    fetchUniversities : state.fetchUniversities,
  };
};

export default connect(mapStateToProps)(ManageUSerSettings);
