import React from 'react'

import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Form,
    Input,
    Col,
    FormGroup,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardTitle,
    CardText,
    Table,
    Modal,
    ModalBody,
    ModalHeader,
    Label,
    ModalFooter

} from "reactstrap";
import { useState, useEffect } from 'react';
const TestAttemptCard = (props) => {

    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [results, setResults] = useState(props.dataAttempt.results);
    const currentQuestion = results[currentQuestionIndex];
    const [modal, setModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        
        let questions = [];
        props.dataAttempt.results.forEach((result, index) => {
            questions.push(result.questionData);
        });
        console.log(props.dataAttempt)
        setQuestions(questions)
        

    }, []);

    const toggleModal = () => {
        
        setModal(!modal);
        
    };

    const getHighlightedText = (text, highlights) => {
        if (!highlights || highlights.length === 0) return text;

        let parts = [];
        let lastIndex = 0;

        const sortedHighlights = highlights.sort((a, b) => text.indexOf(a) - text.indexOf(b));

        sortedHighlights.forEach((highlight, index) => {
            const startIndex = text.indexOf(highlight, lastIndex);
            if (startIndex !== -1) {
                if (startIndex > lastIndex) {
                    parts.push(text.substring(lastIndex, startIndex));
                }
                parts.push(
                    <span
                        key={index}
                        style={{ backgroundColor: 'yellow', cursor: 'pointer' }}
                        onClick={() => {}}
                    >
                        {highlight}
                    </span>
                );
                lastIndex = startIndex + highlight.length;
            }
        });

        if (lastIndex < text.length) {
            parts.push(text.substring(lastIndex));
        }

        return parts;
    };

    const handleQuestionClick = (index) => {
        
    

        setCurrentQuestionIndex(index);
        setSelectedOption(results[index].selectedOption);

        toggleModal();
        
    };

  return (
    <>
        <Row className='mt-3'>
            <Col md="12" lg="6" xl="3" className='mb-3'>
                <Card className='shadow'>
                    <CardBody className='text-center'>
                        <p className='font-weight-bolder text-lg mb-2'><i class="fas fa-check-circle text-xl text-success"></i>&nbsp;&nbsp;{props.dataAttempt.score}</p>
                        <p className='mb-0 text-sm'>Answered Correctly</p>
                    </CardBody>
                </Card>
            </Col>
            <Col md="12" lg="6" xl="3" className='mb-3'>
                <Card className='shadow'>
                    <CardBody className='text-center'>
                        <p className='font-weight-bolder text-lg mb-2'><i class="fas fa-clock text-xl text-primary"></i>&nbsp;&nbsp;{props.dataAttempt.timeSpent}</p>
                        <p className='mb-0 text-sm'>Total Time Spent</p>
                    </CardBody>
                </Card></Col>
            <Col md="12" lg="6" xl="3" className='mb-3'>
                <Card className='shadow'>
                    <CardBody className='text-center'>
                        <p className='font-weight-bolder text-lg mb-2'><i class="fas fa-bookmark text-xl text-warning"></i>&nbsp;&nbsp;{props.dataAttempt.markedQuestions}</p>
                        <p className='mb-0 text-sm'>Questions Marked</p>
                    </CardBody>
                </Card>
            </Col>
            <Col md="12" lg="6" xl="3" className='mb-3'>
                <Card className='shadow'>
                    <CardBody className='text-center'>
                        <p className='font-weight-bolder text-lg mb-2'><i class="fas fa-calendar text-xl text-danger"></i>&nbsp;&nbsp;{props.dataAttempt.date}</p>
                        <p className='mb-0 text-sm'>Date</p>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        
        <Card className='mb-3 shadow'>
            <CardHeader className="font-weight-bolder text-md text-default">
                {props.subjectName} - Practice Test #{props.docId.split("practiceTest")[1]}
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="12">
                        <div className='overflow-auto' style={{maxHeight:"50vh"}}>
                            <Table className="align-items-center rounded" striped responsive>
                                <thead className='bg-default text-white'>
                                    <tr>                                        
                                        <th>Name</th>
                                        <th className='text-center'>Marked</th>
                                        <th className='text-center'>Completed</th>
                                        <th className='text-center'>Correct</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.dataAttempt.results.map((result, index) => (
                                        // <tr key={index} >
                                        <tr key={index} onClick={() => handleQuestionClick(index)}>
                                            <td role="button" className='text-primary'><i className="fas fa-info-circle"></i> Question {index + 1}</td>
                                            <td className='text-center'>{result.marked ? <i className="fas fa-bookmark text-warning"></i> : <i className="far fa-bookmark"></i>}</td>
                                            <td className='text-center'>{result.completed ? <span className='text-success font-weight-bolder'>Yes</span> : "No"}</td>
                                            <td className='text-center'>{result.correct !== null ? result.correct ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i> : <i className="fas fa-window-minimize"></i>}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        <Modal size='lg' className="modal-dialog-centered" isOpen={modal} toggle={toggleModal} >
                <ModalHeader toggle={toggleModal} className='d-flex align-items-center'>Review Answer</ModalHeader>
                <ModalBody className='border'>
                    {questions.length?
                    <Row>
                        <Col sm="12" className='text-center mb-3 mt-3'><h2 className='text-default'>Question {currentQuestionIndex + 1} of {questions.length}</h2></Col>
                        <Col sm="12">
                            {/* <p className="font-weight-300 text-default font-weight-500">
                                {getHighlightedText((questions[currentQuestionIndex].question.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].question}></img> : questions[currentQuestionIndex].question), highlight)}
                            </p> */}

                            <p className="font-weight-700 text-default">
                                {getHighlightedText((questions[currentQuestionIndex].question.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].question} alt="question" /> : questions[currentQuestionIndex].question), currentQuestion.highlights)}
                            </p>


                            {questions[currentQuestionIndex].additionalQuestionImage ? <img src={questions[currentQuestionIndex].additionalQuestionImage}></img> : ""}
                        </Col>
                        <Col sm="12">
                            <FormGroup tag="fieldset">
                                {questions[currentQuestionIndex].options.map((option, index) => (
                                    <FormGroup check className='pt-2' key={index}>
                                        <Input
                                            name="radio1"
                                            type="radio"
                                            value={option}
                                            checked={selectedOption === option}
                                            // onChange={handleOptionChange}
                                            disabled={true}
                                        />
                                        <Label check className='font-weight-bold text-default'>
                                            {String.fromCharCode(65 + index)}.&nbsp;

                                            {option.includes("https://storage.googleapis.com") ? <img src={option}></img> : option}
                                            
                                        </Label>
                                    </FormGroup>
                                ))}
                            </FormGroup>
                            
                            {currentQuestion.correct === null && (
                                <p className="text-warning font-weight-600">The correct answer is {questions[currentQuestionIndex].correct.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].correct}></img> : questions[currentQuestionIndex].correct}.</p>
                            )}

                            {currentQuestion.correct === false && (
                                <p className="text-danger font-weight-600">Incorrect! The correct answer is {questions[currentQuestionIndex].correct.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].correct}></img> : questions[currentQuestionIndex].correct}.</p>
                            )}
                            {currentQuestion.correct && <p className="text-success font-weight-600">Correct!</p>}
                            {/* {currentQuestion.explanationVisible && ( */}
                                <p className="text-default font-weight-400 font-italic">{questions[currentQuestionIndex].explanation.includes("https://storage.googleapis.com") ? <img src={questions[currentQuestionIndex].explanation}></img> : questions[currentQuestionIndex].explanation}</p>
                            {/* )} */}
                        </Col>
                    </Row> : ""
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>

    </>
  )
}

export default TestAttemptCard