import axios from "axios";
import { 
    OPEN_ACHIEVEMENT_MODAL,
    CLOSE_ACHIEVEMENT_MODAL,
    CREATE_ACHIEVEMENT_REQUEST,
    CREATE_ACHIEVEMENT_SUCCESS, 
    CREATE_ACHIEVEMENT_FAILURE,
    CREATE_ACHIEVEMENT_RESET,
    FETCH_ACHIEVEMENTS_REQUEST,
    FETCH_ACHIEVEMENTS_SUCCESS, 
    FETCH_ACHIEVEMENTS_FAILURE,
    UPDATE_ACHIEVEMENT_REQUEST,
    UPDATE_ACHIEVEMENT_SUCCESS, 
    UPDATE_ACHIEVEMENT_FAILURE,
    UPDATE_ACHIEVEMENT_RESET,
    DELETE_ACHIEVEMENT_REQUEST,
    DELETE_ACHIEVEMENT_SUCCESS, 
    DELETE_ACHIEVEMENT_FAILURE,
    DELETE_ACHIEVEMENT_RESET,
    EDIT_ACHIEVEMENT,

    UPDATE_IMPORTANT_ACHIEVEMENT_REQUEST,
    UPDATE_IMPORTANT_ACHIEVEMENT_SUCCESS, 
    UPDATE_IMPORTANT_ACHIEVEMENT_FAILURE,
    UPDATE_IMPORTANT_ACHIEVEMENT_RESET,
    NEW_ACHIEVEMENT } from "./AchievementsTypes"

export const createAchievementReset = () => {
    return {
        type: CREATE_ACHIEVEMENT_RESET,
    }
}

const createAchievementRequest = () => {
    return {
        type: CREATE_ACHIEVEMENT_REQUEST,
    }
}

const createAchievementSuccess = success => {
    return {
        type: CREATE_ACHIEVEMENT_SUCCESS,
        payload: success
    }
}

const createAchievementFailure = error => {
    return {
        type: CREATE_ACHIEVEMENT_FAILURE,
        payload : error
    }
}

export const createAchievement = (data) => {

    return (dispatch) => {

        dispatch(createAchievementRequest());
        
        
        
        axios.post('/storeAchievement', data)
        .then(response => {
            const achievement = response.data
            dispatch(createAchievementSuccess(achievement))
            dispatch(fetchAchievement({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createAchievementFailure(errorMsg))
        })
        
    }

}




const fetchAchievementRequest = () => {
    return {
        type: FETCH_ACHIEVEMENTS_REQUEST,
    }
}

const fetchAchievementSuccess = success => {
    return {
        type: FETCH_ACHIEVEMENTS_SUCCESS,
        payload: success
    }
}

const fetchAchievementFailure = error => {
    return {
        type: FETCH_ACHIEVEMENTS_FAILURE,
        payload : error
    }
}

export const fetchAchievement = (data) => {

    const fetchAchievementRoute = data.route;
    
    return (dispatch) => {
        
        dispatch(fetchAchievementRequest());
        
        axios.post('/getAchievements' + fetchAchievementRoute , data)
        .then(response => {
            const achievement = response.data
            dispatch(fetchAchievementSuccess(achievement))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchAchievementFailure(errorMsg))
        })
        
    }

}


export const updateAchievementReset = () => {
    return {
        type: UPDATE_ACHIEVEMENT_RESET,
    }
}

const updateAchievementRequest = () => {
    return {
        type: UPDATE_ACHIEVEMENT_REQUEST,
    }
}

const updateAchievementSuccess = success => {
    return {
        type: UPDATE_ACHIEVEMENT_SUCCESS,
        payload: success
    }
}

const updateAchievementFailure = error => {
    return {
        type: UPDATE_ACHIEVEMENT_FAILURE,
        payload : error
    }
}

export const updateAchievement = (data) => {

    return (dispatch) => {

        dispatch(updateAchievementRequest());
        
        
        
        axios.post('/updateAchievement', data)
        .then(response => {
            const achievement = response.data
            dispatch(updateAchievementSuccess(achievement))
            dispatch(fetchAchievement({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateAchievementFailure(errorMsg))
        })
        
    }

}



const openAchievementModal = status => {
    return {
        type: OPEN_ACHIEVEMENT_MODAL,
        payload : status
    }
}

const closeAchievementModal = status => {
    return {
        type: CLOSE_ACHIEVEMENT_MODAL,
        payload : status
    }
}

export const updateAchievementsModal = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openAchievementModal(data))

        } 
        else{

            dispatch(closeAchievementModal(data))

        } 
    }

}

export const editAchievement = data => {
    
    return {
        type: EDIT_ACHIEVEMENT,
        payload : data
    }
}

export const newAchievements = data => {
    
    return {
        type: NEW_ACHIEVEMENT,
        payload : data
    }
}



export const deleteAchievementReset = () => {
    return {
        type: DELETE_ACHIEVEMENT_RESET,
    }
}

const deleteAchievementRequest = () => {
    return {
        type: DELETE_ACHIEVEMENT_REQUEST,
    }
}

const deleteAchievementSuccess = success => {
    return {
        type: DELETE_ACHIEVEMENT_SUCCESS,
        payload: success
    }
}

const deleteAchievementFailure = error => {
    return {
        type: DELETE_ACHIEVEMENT_FAILURE,
        payload : error
    }
}

export const deleteAchievement = (data) => {

    return (dispatch) => {

        dispatch(deleteAchievementRequest());
        
        axios.post('/deleteAchievement', data)
        .then(response => {
            const achievement = response.data
            dispatch(deleteAchievementSuccess(achievement))
            dispatch(fetchAchievement({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteAchievementFailure(errorMsg))
        })
        
    }

}


export const updateImportantAchievementReset = () => {
    return {
        type: UPDATE_IMPORTANT_ACHIEVEMENT_RESET,
    }
}

const updateImportantAchievementRequest = () => {
    return {
        type: UPDATE_IMPORTANT_ACHIEVEMENT_REQUEST,
    }
}

const updateImportantAchievementSuccess = success => {
    return {
        type: UPDATE_IMPORTANT_ACHIEVEMENT_SUCCESS,
        payload: success
    }
}

const updateImportantAchievementFailure = error => {
    return {
        type: UPDATE_IMPORTANT_ACHIEVEMENT_FAILURE,
        payload : error
    }
}

export const updateImportantAchievement = (data) => {

    return (dispatch) => {

        dispatch(updateImportantAchievementRequest());
        
        
        
        axios.post('/updateImportantAchievement', data)
        .then(response => {
            const achievement = response.data
            dispatch(updateImportantAchievementSuccess(achievement))
            // dispatch(fetchAchievement({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateImportantAchievementFailure(errorMsg))
        })
        
    }

}
