import { SAVE_COVID_19_IMPACT_RESET, SAVE_COVID_19_IMPACT_REQUEST, SAVE_COVID_19_IMPACT_SUCCESS, SAVE_COVID_19_IMPACT_FAILURE, FETCH_COVID_19_IMPACT_FAILURE, FETCH_COVID_19_IMPACT_REQUEST, FETCH_COVID_19_IMPACT_SUCCESS } from './Covid19ImpactTypes'
import axios from 'axios'

const covid19ImpactRequest = () => {
    return {
        type: SAVE_COVID_19_IMPACT_REQUEST,
    }
}

export const covid19Impact = (userData) => (dispatch) => {

    dispatch(covid19ImpactRequest());
    axios.post('/postCovid19Impact', userData)
        .then(res => {
            dispatch({
                type: SAVE_COVID_19_IMPACT_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SAVE_COVID_19_IMPACT_FAILURE,
                payload: err.response.data
            })
        })
}


export const covid19ImpactReset = () => {
    return {
        type: SAVE_COVID_19_IMPACT_RESET,
    }
}


const fetchCovid19ImpactRequest = () => {
    return {
        type: FETCH_COVID_19_IMPACT_REQUEST,
    }
}

const fetchCovid19ImpactSuccess = success => {
    return {
        type: FETCH_COVID_19_IMPACT_SUCCESS,
        payload: success
    }
}

const fetchCovid19ImpactFailure = error => {
    return {
        type: FETCH_COVID_19_IMPACT_FAILURE,
        payload : error
    }
}

export const fetchCovid19Impact = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchCovid19ImpactRequest());
        
        
        axios.post('/getCovid19Impact' , data)
        .then(response => {
            const covid19Impact = response.data
            dispatch(fetchCovid19ImpactSuccess(covid19Impact))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCovid19ImpactFailure(errorMsg))
        })
        
    }

}
