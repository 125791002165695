import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  Col,
  FormGroup,
  CardBody,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CustomInput,
  Alert,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import ManageUserSettings from "components/Pages/ManageUserSettings";
import ManageStudents from "components/Pages/ManageStudents";
import ManageCoaches from "components/Pages/ManageCoaches";
import ManageAdmins from "components/Pages/ManageAdmins";
import classnames from "classnames";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import {
  fetchAllUsers, 
} from "redux/ManageUsers/ManageUsersAction";

const ManageUsers = (props) => {
  	const dispatch = useDispatch();

	const mainopt = ["Students", "Coaches", "Admin","Settings" ];
    const [mainActiveTab, setMainActiveTab] = useState("Students");
	const [students, setStudents]  = useState([]);
	const [coaches, setCoaches]  = useState([]);
	const [admins, setAdmins]  = useState([]);

    const toggleMain = (tab) => {
        if (mainActiveTab !== tab) setMainActiveTab(tab);
    };

	useEffect(() => {
        dispatch(fetchAllUsers({ route: "", subscriptionFilter: ""}));
    }, []);
	
	useEffect(() => {
		if (props.fetchAllUsers.loading) {
			Swal.fire({
				allowOutsideClick: false,
				didOpen: () => {
				Swal.showLoading();
				},
			});
		} else {
			
			if (props.fetchAllUsers.allusers.length) {
				let collect_students = []
				let collect_coaches = []
				let collect_admins = []
				props.fetchAllUsers.allusers.map((u)=>{
					console.log(u.admin)
					if(u.admin === true || u.admin == "true"){
						collect_admins.push(u)	
					}
					if(u.coach === true || u.coach == "true"){
						collect_coaches.push(u)
					}else{
						collect_students.push(u)
					}
				})
				setCoaches(collect_coaches)
				setAdmins(collect_admins)
				setStudents(collect_students)
			}else{
				setCoaches([])
				setAdmins([])
				setStudents([])
			}
			Swal.close();
			
		}
	}, [props.fetchAllUsers]);


  return (
		<>
			<Header></Header>
			<Container className="mt--7" fluid>
				<Row>
					<Col>
						<Card className="shadow">
						<CardHeader className="border-1">
								<Row>
								<Col className="mt-3 d-flex justify-content-center">
									<Nav fill pills>
									{mainopt.length > 0
										? mainopt.map((c, i) => {
											return (
											<NavItem className="ml-1" key={i}>
												<NavLink
												key={c}
												className={classnames({
													active: mainActiveTab === c,
												})}
												onClick={() => {
													toggleMain(c);
												}}
												style={{ cursor: "pointer" }}
												>
												{c}
												</NavLink>
											</NavItem>
											);
										})
										: ""}
									</Nav>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<Row>
								<Col lg="12" className="mt-3 p-2">
									<TabContent activeTab={mainActiveTab}>
										{
											mainopt.map((mc, i) => {
												return (
													<TabPane tabId={mc} key={i}>
														{mc == "Students" ? (
															<Row>
																<Col>
																	<ManageStudents item={students}/>
																</Col>
															</Row>
														) :  mc == "Coaches" ? 
														(	<Row>
																<Col>
																	<ManageCoaches item={coaches}/>	
																</Col>
															</Row>
														): mc == "Admin" ? 
														 	<Row>
																<Col>
																	<ManageAdmins item={admins}/>		
																</Col>
															</Row>
															: 
														 	<Row>
																<Col>
																	<ManageUserSettings />		
																</Col>
															</Row>
														}


													</TabPane>
												)
											})
										}
									</TabContent>
								</Col>
							</Row>
							{/* <Row className="mb-2">
								<Col md="4" className="mb-2">
									<Select
									id="filterBySubs"
									placeholder="Select Subscription"
									options={subscriptionFilter}
									styles={customDropdownWithBorder}
									onChange={(e) => handleSubscriptionFilterOnChange(e)}
									menuPortalTarget={document.body}
									/>
								</Col>
								<Col md="4">
								
									<FormGroup>
										<Select
										placeholder="Filter By Coach"
										id="filterByCoach"
										options={coachOption}
										styles={customDropdown }
										onChange={(e) => handleGetByCoach(e)}
										menuPortalTarget={document.body}
										formatOptionLabel={(v) => (
											<div className="d-flex ">
												{v.value ? (
												v.image ? (
													<img
													className="rounded-circle  mr-2 "
													style={{ width: "25px", height: "25px" }}
													src={
														"https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/" +
														v.image
													}
													alt={v.label}
													/>
												) : (
													<div
													className="rounded-circle profile-letter-all-users mr-2 "
													style={{ width: "25px", height: "25px", fontSize: '12px'}}
													>
													{v.label ? v.label.trim()[0] : ""}
													</div>
												)
												) : (
												""
												)}

												<span>{v.label}</span>
											</div>
										)}
										
										/>
									</FormGroup>
								</Col>
								<Col md="4">
									<label className="form-control-label"></label>
									<Button onClick={resetFilter}>Reset Filter</Button>
								</Col>
							</Row> */}
						
							{/* <Row>
								<Col lg="12">
									<Card className="shadow">

										<CardBody>
											<div className="float-right mb-2">
												<LifeTimePlanUsers/>
												<Button
												color="primary"
												onClick={(e) => updateMultipleUsers(e)}
												>
												Update Multiple Users
												</Button>
											</div>
											
											{ 
											
												loading ? 
													<div className="m-5">
														<Alert color="secondary" className="text-center">
															Collecting data.. Please wait while loading {" "}
															<Spinner size="sm" color="primary" />{" "}
														</Alert>
													</div>
												: data.length ? 
														<DataTable
															progressPending={loading}
															customStyles={tableHeaderstyle}
															columns={columns}
															data={filter}
															pagination
															wrap
															// selectableRows
															// fixedHeader
															selectableRowsHighlight
															highlightOnHover
															subHeader
															subHeaderComponent={
															<input
																type="text"
																className="w-50 form-control"
																placeholder="Search a keyword..."
																value={search}
																onChange={(e) => setSearch(e.target.value)}
															/>
															}
															subHeaderAlign="right"
														/>
												: 
												<Alert color="primary">No data available .</Alert>
											
											
											}
										</CardBody>
									</Card>
								</Col>
							</Row> */}
						</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	);
};

const mapStateToProps = (state) => {
  return {
    fetchAllUsers: state.fetchAllUsers,
  };
};

export default connect(mapStateToProps)(ManageUsers);
