import React, {useState, useEffect} from 'react'
import Header from 'components/Headers/Header';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    CardImg,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";
import AddTpcCourseModal from 'components/Modal/AddTpcCourseModal';
import { connect, useDispatch } from 'react-redux';
import { fetchTpcCourses, deleteTpcCourse, deleteTpcCourseReset } from 'redux/ManageTpcCourses/ManageTpcCoursesActions'
import Swal from 'sweetalert2'
import ManageTpcCoursesLessons from './ManageTpcCoursesLessons';
const ManageTpcCourses = (props) => {


    const [modalAddTpcCourseModal, setModalAddTpcCourseModal] = useState(false);
    const [tpcCoursesLists, setTpcCoursesLists] = useState([]);

    const [editCourse, setEditCourse] = useState(false);
   
    
    const toggleAddTpcCourseModal = () =>{
        setEditCourse(false)
        setModalAddTpcCourseModal(!modalAddTpcCourseModal);
        
    } 

    const dispatch = useDispatch()
    
    useEffect(() => {
        
        dispatch(fetchTpcCourses({}))

    },[props.createTpcCourse, props.updateTpcCourse, props.deleteTpcCourse], [])
    
    useEffect(() => {
        
        if(props.fetchTpcCourses.tpccourses.length){
            

            setTpcCoursesLists(props.fetchTpcCourses.tpccourses)
        }
        

    }, [props.fetchTpcCourses ])
    

    const handleEditDetails = (e, index) => {
        setModalAddTpcCourseModal(!modalAddTpcCourseModal);
        setEditCourse(index)
        
    }

    const handleDeleteDetails = (e, tpcCourseId) => {
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteTpcCourse({tpcCourseId : tpcCourseId}))
        
            }
        })

    }

    useEffect(() => {
    
        
        if(props.deleteTpcCourse.tpccourses.length && typeof props.deleteTpcCourse.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully deleted TPC course!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(deleteTpcCourseReset());
            
          })
          
        }
      
        else if(typeof props.deleteTpcCourse.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to delete TPC course!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(deleteTpcCourseReset());
            
          })    
                
        }
      
    }, [props.deleteTpcCourse])

    const [editLesson, setEditLesson] = useState({})

    const handleEditLesson = (e,courseData) => {
        e.preventDefault()
        setEditLesson(courseData)
        
    } 

    const handleBackToTpcCourses = (e) => {
        e.preventDefault()
        setEditLesson({})
    }

    return (
        <>
            <Header></Header>
            {editLesson ? <Container className="mt--7" fluid><a href="" className="text-warning">
            <Button className="shadow" color="info" onClick={(e) => handleBackToTpcCourses(e)}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back to TPC Courses</Button></a></Container> : "" }
            {Object.keys(editLesson).length === 0? <>
            
            <Container className="mt--7" fluid>
                <Row className='mb-3'>
                    <Col>
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className='float-left'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        TPC Courses
                                    </h6>
                                    <h2 className="mb-0">Your Courses</h2>
                                </div>
                                <div className='float-right'>
                                    <Button color="primary" onClick={toggleAddTpcCourseModal}>Create Course</Button>
                                </div>
                            </CardHeader>
                            {/* <CardBody>
                                
                                
                            </CardBody> */}
                        </Card>
                        
                    </Col> 
                </Row>

                <Row>
                    {   !tpcCoursesLists.length ? 
                        <Col md="12 mt-3">
                            <Card className='shadow'>
                                <CardBody className='text-center'>
                                    <h2 className='mb-0'>No courses listed.</h2>
                                </CardBody>
                            </Card>
                        </Col>
                        :
                        tpcCoursesLists.map((c, i) => {
                            return ( 
                                <Col lg="6" xl="4" md="12" key={i}  className='mb-3'>
                                    <Card className='shadow'>
                                        {c.courseThumbnail != "" ? 
                                        
                                            <CardImg
                                                alt="..."
                                                src={`https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + c.courseThumbnail+``}
                                                
                                                top
                                                style={{height : "15em", objectFit: "fill", width:"100%"}}
                                            />
                                            
                                            :

                                            <CardImg
                                                alt="..."
                                           
                                                src={require("assets/img/brand/no-image.jpg")}
                                                top
                                                style={{height : "15em", objectFit: "fill", width:"100%"}}
                                            />

                                        }
                                        <CardBody>
                                            
                                            <CardTitle className='text-truncate text-center mb-2'><h2 className='mb-0 text-default font-weight-bolder'>{c.courseTitle}</h2></CardTitle>
                                            <p className='text-center text-sm text-uppercase font-weight-bold mb-2'>Visibility : {c.visibility == "published"? <label className=' text-success mb-0'>Published</label>: <label className=' text-danger mb-0'>Draft</label>}</p>
                                            <Row>
                                                <div  className='col-12 p-1 text-center'>
                                                    <Button
                                                        color="primary"
                                                        className='btn w-100'
                                                        onClick={e => handleEditDetails(e, i)}
                                                        >
                                                        <i className="fas fa-pen"></i>&nbsp;&nbsp;Details
                                                    </Button>
                                                </div>
                                                <div  className='col-12 p-1 text-center'>
                                                    <Button
                                                        color="info"
                                                        className='btn w-100'
                                                        onClick={e => handleEditLesson(e, c)}
                                                        >
                                                        <i className="fas fa-pen"></i>&nbsp;&nbsp;Lesson
                                                    </Button>
                                                </div>
                                                <div  className='col-12 p-1 text-center'>
                                                    <Button
                                                        color="danger"
                                                        className='btn w-100'
                                                        onClick={e => handleDeleteDetails(e, c.tpcCourseId)}
                                                        >
                                                        <i className="fas fa-trash"></i>&nbsp;&nbsp;Course
                                                    </Button>
                                                </div>
                                            </Row>


                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        }) 
                    }
                </Row>
            </Container>
            </>
            : <Container className="mt-3" fluid><ManageTpcCoursesLessons courseData={editLesson}></ManageTpcCoursesLessons></Container>}
            <AddTpcCourseModal modalAddTpcCourseModal={modalAddTpcCourseModal} toggleAddTpcCourseModal={toggleAddTpcCourseModal} editCourse={editCourse} coursesData={props.fetchTpcCourses} />
        
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchTpcCourses : state.fetchTpcCourses,
        createTpcCourse : state.createTpcCourse,
        updateTpcCourse : state.updateTpcCourse,
        deleteTpcCourse : state.deleteTpcCourse,
        
    }
      
}

export default connect(mapStateToProps)(ManageTpcCourses)
