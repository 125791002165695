import {
CREATE_LTP_REQUEST,
CREATE_LTP_SUCCESS,
CREATE_LTP_FAILURE,
CREATE_LTP_RESET,
FETCH_LTP_REQUEST,
FETCH_LTP_SUCCESS,
FETCH_LTP_FAILURE,
DELETE_LTP_REQUEST,
DELETE_LTP_SUCCESS,
DELETE_LTP_FAILURE,
DELETE_LTP_RESET,

} from "./ManageLifeTimePlanTypes";

const initialCreateStudentToLifetimePlanState = {
  addstudenttoplan: [],
  loading: false,
};

export const createStudentToLifetimePlanReducer = (
  state = initialCreateStudentToLifetimePlanState,
  action
) => {
  switch (action.type) {
    case CREATE_LTP_RESET:
      return {
        loading: false,
        addstudenttoplan: [],
      };
    case CREATE_LTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LTP_SUCCESS:
      return {
        loading: false,
        addstudenttoplan: [action.payload],
      };
    case CREATE_LTP_FAILURE:
      return {
        loading: false,
        addstudenttoplan: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchItemState = {
    ltpitems : [],
    loading: false,

}

export const fetchLtpItemReducer = (state = initialFetchItemState, action) => {
    switch (action.type) {
        case FETCH_LTP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_LTP_SUCCESS:
            return {
                loading: false,
                ltpitems: action.payload
            }
        case FETCH_LTP_FAILURE:
            return {
                loading: false,
                ltpitems: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDeleteLtpItemState = {
  deleteLtpItem: [],
  loading: false,
};

export const deleteLtpItemReducer = (
  state = initialDeleteLtpItemState,
  action
) => {
  switch (action.type) {
    case DELETE_LTP_RESET:
      return {
        loading: false,
        deleteLtpItem: [],
      };
    case DELETE_LTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LTP_SUCCESS:
      return {
        loading: false,
        deleteLtpItem: [action.payload],
      };
    case DELETE_LTP_FAILURE:
      return {
        loading: false,
        deleteLtpItem: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
