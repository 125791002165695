import React, { useEffect, useState } from 'react'
import {

    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
  } from "reactstrap";

import Swal from 'sweetalert2'
import '../../../assets/css/school-card-react.css'

import { connect, useDispatch } from 'react-redux';
import { createSelectSchoolForSecondaryEssays, createSelectSchoolForSecondaryEssaysReset } from 'redux/SecondaryEssays/SecondaryEssaysActions';

const SchoolCard = (props) => {

  let [applicationDeadlineDay, setApplicationDeadlineDay] = useState(null);
  let [applicationDeadlineMonth, setApplicationDeadlineMonth] = useState(null);
  let [applicationDeadlineYear, setApplicationDeadlineYear] = useState(null);

  let [classesBeginDay, setClassesBeginDay] = useState(null);
  let [classesBeginMonth, setClassesBeginMonth] = useState(null);
  let [classesBeginYear, setClassesBeginYear] = useState(null);

  let [selectedSecondaryEssaysClass, setSelectedSecondaryEssaysClass ] = useState("");
  let [selectedSecondaryEssaysText, setSelectedSecondaryEssaysText ] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {

    let applicationDeadlineDate = new Date(props.medicalSchoolDetails.overview.priApplLatestDtText);

    setApplicationDeadlineDay(applicationDeadlineDate.getDate());
    setApplicationDeadlineMonth(applicationDeadlineDate.toLocaleString('default', { month: 'long' }));
    setApplicationDeadlineYear(applicationDeadlineDate.getFullYear());
    
    let classesBeginDate = new Date(props.medicalSchoolDetails.medSchoolInformation.startMonthYear);

    setClassesBeginDay(classesBeginDate.getDate());
    setClassesBeginMonth(classesBeginDate.toLocaleString('default', { month: 'long' }));
    setClassesBeginYear(classesBeginDate.getFullYear());


    setSelectedSecondaryEssaysClass(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "btn btn-danger disabled" : props.medicalSchoolDetails.secondaryEssays != "Y" ? "btn btn-default disabled" : props.adminUser.admin ? "btn btn-primary disabled" : "btn btn-primary" );

    setSelectedSecondaryEssaysText(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "ALREADY ADDED TO MY APPLICATION LIST" : props.medicalSchoolDetails.secondaryEssays == "Y" ?  "ADD TO MY APPLICATION LIST" : "NO AVAILABLE SECONDARY ESSAYS");


  }, [props.medicalSchoolDetails])


  const formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const schoolType = (data) => {
      if(data.countryCd == "CAN"){
        return "CANADIAN"
      }
      else if(data.schoolType == "DO"){
        return "DO"
      }
      else{
        return "MD"
      }
  }

  const onClickAddToMyApplication = (e, data) => {
    e.preventDefault();

    data['userId'] = props.userDetails.userId;
      
    dispatch(createSelectSchoolForSecondaryEssays(data));

    
    Swal.showLoading()

    setSelectedSecondaryEssaysClass("btn btn-danger disabled")
    setSelectedSecondaryEssaysText("ALREADY ADDED TO MY APPLICATION LIST")
    
  }

  const [modalViewSchoolOtherDetails, setModalViewSchoolOtherDetails] = useState(false);

  const toggleViewSchoolOtherDetails = () => {
    setModalViewSchoolOtherDetails(!modalViewSchoolOtherDetails);
  };


  const average = (numbers) => {
    const sum = numbers.reduce((acc, num) => acc + num, 0);
    return sum / numbers.length;
  }

  // Function to calculate the standard deviation of an array of numbers
  const standardDeviation = (numbers) => {
      const avg = average(numbers);
      const squareDiffs = numbers.map(num => Math.pow(num - avg, 2));
      const avgSquareDiff = average(squareDiffs);
      return Math.sqrt(avgSquareDiff);
  }


  useEffect(() => {
    
  
      if(props.createSelectSchoolForSecondaryEssays.selectschoolforsecondaryessays.length && typeof props.createSelectSchoolForSecondaryEssays.error === "undefined"){
        // dispatch(createSelectSchoolForSecondaryEssaysReset());
    

        Swal.fire({
            title: 'Success!',
            text: 'Successfully added to my application list!',
            icon : 'success',
            timer: 3000
            
          }
        ).then((result) => {
          dispatch(createSelectSchoolForSecondaryEssaysReset());
        })
        
        
        
      }
    
      else if(typeof props.createSelectSchoolForSecondaryEssays.error !== "undefined"){
        // dispatch(createSelectSchoolForSecondaryEssaysReset()); 
        Swal.fire({
            title:'Failed!',
            text:'Failed to add to my application list!',
            icon :'error',
            timer: 3000
            
          }
        ).then((result) => {
          dispatch(createSelectSchoolForSecondaryEssaysReset());
        })    
              
        
      }
    
  }, [props.createSelectSchoolForSecondaryEssays])
  
 

  
  return (
    <Row className="mb-5 ">
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
            <Card className="card-profile shadow">
              
              
              <CardHeader className='card-school-cover-img bg-primary' style={{backgroundImage: `url(https://storage.googleapis.com/pre-dental-tracker.appspot.com/school-search-images/`+props.medicalSchoolDetails.imageUrl+`)`}}>
              <Row className='pl-3 pr-3'>
                {/* <Col className="order-lg-1" lg="3">
                  <div className="card-profile-div shadow">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="school-card-img" style={{backgroundImage: `url(https://storage.googleapis.com/pre-dental-tracker.appspot.com/school-search-images/`+props.medicalSchoolDetails.crestUrl+`)`}}></div>
                      </a>
                    </div>
                  </div>
                </Col> */}
                <Col className="order-lg-2" lg="12">
                    <div className="border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <Row>
                            <Col lg="12 text-center">
                            <h1 className="text-secondary">{props.medicalSchoolDetails.overview.schoolName}</h1>
                            </Col>
                            <Col lg="12 text-center">
                            <h2 className="text-light"><i className="fa fa-map-marker-alt"></i> {props.medicalSchoolDetails.overview.country}, {props.medicalSchoolDetails.overview.state} | <span className="badge badge-pill badge-secondary" style={{fontSize:"1rem"}}>DENTAL </span> | <a  href={props.medicalSchoolDetails.admUrl} className='text-info' target='_blank'><i className="fa fa-globe"></i> Dental School Website</a></h2>
                            </Col>
                        </Row>                        
                        
                    </div>
                </Col>
              </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
              
                  <Row className="pt-3">
                      <Col lg="1"></Col>
                      
                      <Col lg="5"><h3 className="font-weight-bolder">Class size : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.total ? props.medicalSchoolDetails.overview.total : "Not Provided"}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Start month: <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolInformation.startMonthYear}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Campus setting: <span className="font-weight-normal">{props.medicalSchoolDetails.overview.publicPrivate}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Campus housing available: <span className="font-weight-normal">{props.medicalSchoolDetails.overview.campuysHousingAvailable}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      {/* <Col lg="5"><h3 className="font-weight-bolder">GPA : <span className="font-weight-normal">{props.medicalSchoolDetails.gpa.minimumGpa.overall}</span></h3></Col> */}
                      <Col lg="5"><h3 className="font-weight-bolder">DAT : <span className="font-weight-normal">{props.medicalSchoolDetails.datScore["Total Science"]}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">GPA : <span className="font-weight-normal">{props.medicalSchoolDetails.gpa.Total ? props.medicalSchoolDetails.gpa.Total : "Not Provided"}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      
                      <Col lg="5"><h3 className="font-weight-bolder">Types of institution: <span className="font-weight-normal">{props.medicalSchoolDetails.overview.campusType}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Address : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.address ? props.medicalSchoolDetails.overview.address : "Not Provided"}</span></h3></Col>
                      <Col lg="1"></Col>
                      
                  </Row>
                  <div className='text-center'>
                    <hr className="my-4" />
                    <a onClick={toggleViewSchoolOtherDetails} className="btn btn-info"> <span>View Other Details</span></a>
                    <a onClick={(e) => {onClickAddToMyApplication(e, { "schoolSearchId" : props.medicalSchoolDetails.schoolSearchId})}} className={selectedSecondaryEssaysClass}> <span>{selectedSecondaryEssaysText}</span></a>
                    {/* <a onClick={(e) => {onClickAddToMyApplication(e, { "schoolSearchId" : props.medicalSchoolDetails.schoolSearchId})}} className="btn btn-primary"> <span>ADD TO MY APPLICATION LIST</span></a> */}
                  </div>
              </CardBody>
            </Card>
          </Col>

          <Modal
            className="modal-dialog-centered"
            isOpen={modalViewSchoolOtherDetails}
            size="lg"
            toggle={toggleViewSchoolOtherDetails}
          >
            <ModalHeader toggle={toggleViewSchoolOtherDetails}>
              {props.medicalSchoolDetails.overview.schoolName}
            </ModalHeader>
            <ModalBody>
              
              <Row>
                {Object.keys(props.medicalSchoolDetails.gpa).length !== 0 ?
                  <Col md="12">
                    <label className="font-weight-bolder">GRADE POINT AVERAGE(GPA)</label>
                    <Table className="align-items-center rounded shadow ">
                        <thead className="bg-primary text-white">
                            <tr>
                              <th>Subject Matter</th>
                              <th>Mean</th>
                            </tr>
                        </thead> 
                        <tbody>
                          <tr>
                            <td>Science</td>
                            <td>{props.medicalSchoolDetails.gpa.Science}</td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td>{props.medicalSchoolDetails.gpa.Total}</td>
                          </tr>
                        </tbody>
                    </Table>
                  </Col>
                  : ""
                }
                {Object.keys(props.medicalSchoolDetails.datScore).length !== 0 ?
                  <Col md="12 mt-3">
                    <label className="font-weight-bolder">DAT SCORES</label>
                    <Table className="align-items-center rounded shadow ">
                        <thead className="bg-primary text-white">
                            <tr>
                              <th>Score Type</th>
                              <th>Mean</th>
                            </tr>
                        </thead> 
                        <tbody>
                          <tr>
                            <td>Academic Average</td>
                            <td>{props.medicalSchoolDetails.datScore["Academic Average"]}</td>
                            
                          </tr>
                          <tr>
                            <td>Perceptual Ability</td>
                            <td>{props.medicalSchoolDetails.datScore["Perceptual Ability"]}</td>
                          </tr>
                          <tr>
                            <td>Total Science</td>
                            <td>{props.medicalSchoolDetails.datScore["Total Science"]}</td>
                          </tr>
                          <tr className='bg-info font-weight-bold text-white'>
                            <td>Average</td>
                            <td>{average([parseFloat(props.medicalSchoolDetails.datScore["Academic Average"]),parseFloat(props.medicalSchoolDetails.datScore["Perceptual Ability"]),parseFloat(props.medicalSchoolDetails.datScore["Total Science"])]).toFixed(2)}</td>
                          </tr>
                          <tr className='bg-warning font-weight-bold text-white'>
                            <td>Standard Deviation</td>
                            <td>{standardDeviation([parseFloat(props.medicalSchoolDetails.datScore["Academic Average"]),parseFloat(props.medicalSchoolDetails.datScore["Perceptual Ability"]),parseFloat(props.medicalSchoolDetails.datScore["Total Science"])]).toFixed(2)}</td>
                          </tr>
                        </tbody>
                    </Table>
                    
                  </Col>
                  : ""
                }

                {Object.keys(props.medicalSchoolDetails.instateOutOfStateStudents).length !== 0 ?
                  <Col md="12 mt-3 ">
                    <label className="font-weight-bolder">PERCENTAGE OF IN-STATE VS OUT-OF-STATE STUDENTS ACCEPTED</label>
                    <Table className="align-items-center rounded shadow ">
                        <thead className="bg-primary text-white">
                            <tr >
                              <th>Place of Residence</th>
                              <th>Applicants</th>
                              <th>Interviewed</th>
                              <th>Enrolled</th>
                            </tr>
                        </thead> 
                        <tbody>
                          <tr>
                            <td>Science</td>
                            <td>{props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberEnrolled}</td>
                            <td>{props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberInterviewed}</td>
                            <td>{props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberOfApplicants}</td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td>{props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberEnrolled}</td>
                            <td>{props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberInterviewed}</td>
                            <td>{props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberOfApplicants}</td>
                          </tr>
                        </tbody>
                    </Table>
                    <Table className="align-items-center rounded shadow ">
                        <thead className="">
                            
                        </thead> 
                        <tbody>
                          <tr className='bg-info font-weight-bold text-white'>
                            <td>IN-STATE INTERVIEW RATE</td>
                            <td>OUT-OF-STATE INTERVIEW RATE</td>
                          </tr>
                          <tr>
                            <td>{((props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberInterviewed / props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberOfApplicants) * 100).toFixed(2)}</td>
                            <td>{((props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberInterviewed / props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberOfApplicants) * 100).toFixed(2)}</td>
                            
                          </tr>
                          <tr className='bg-warning font-weight-bold text-white'>
                            <td>IN-STATE ENROLLMENT RATE</td>
                            <td>OUT-OF-STATE ENROLLMENT RATE</td>
                          </tr>
                          <tr>
                            <td>{((props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberEnrolled / props.medicalSchoolDetails.instateOutOfStateStudents["In-State"].numberInterviewed) * 100).toFixed(2)}</td>
                            <td>{((props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberEnrolled / props.medicalSchoolDetails.instateOutOfStateStudents["Out-of-State"].numberInterviewed) * 100).toFixed(2)}</td>
                            
                          </tr>
                        </tbody>
                    </Table>
                  </Col>
                  : ""
                }

                {Object.keys(props.medicalSchoolDetails.combinedDegreeProgramsOffered).length !== 0 ?
                  <Col md="12 mt-3 table-responsive">
                    <label className="font-weight-bolder">COMBINED DEGREE PROGRAMS OFFERED</label>
                    <Table className="align-items-center rounded shadow">
                        <thead className="bg-primary text-white">
                           
                              <tr>
                                {Object.entries(props.medicalSchoolDetails.combinedDegreeProgramsOffered).map(([key, value]) => (
                                  <th>{key}</th>
                                ))}
                                
                              </tr>

                           
                            
                        </thead> 
                        <tbody>
                          <tr>
                            {Object.entries(props.medicalSchoolDetails.combinedDegreeProgramsOffered).map(([key, value]) => (
                                  <td>{value ? <i class="fas fa-check text-green"></i> : <i class="fas fa-window-minimize"></i>}</td>
                                ))}
                          </tr>
                        </tbody>
                    </Table>
                  </Col>
                  : ""
                }

                {Object.keys(props.medicalSchoolDetails.demographics).length !== 0 ?
                  <Col md="12 mt-3 table-responsive">
                    <label className="font-weight-bolder">DEMOGRAPHICS</label>
                    <Table className="align-items-center rounded shadow">
                        <thead className="bg-primary text-white">
                           
                          <tr>
                            <th></th>
                            <th colSpan={2} className='bg-info'>Applicants</th>
                            <th colSpan={2} className='bg-warning'>First-Time, First Year Enrollees</th>
                          </tr>
                          <tr>
                            <th>Race/Ethnicity</th>
                            <th className='bg-info'>Men</th>
                            <th className='bg-info'>Women</th>
                            <th className='bg-warning'>Men</th>
                            <th className='bg-warning'>Women</th>
                          </tr>

                           
                            
                        </thead> 
                        <tbody>
                          {Object.entries(props.medicalSchoolDetails.demographics).map(([key, value]) => (
                            key != "Total Applicants" ? <tr>
                            
                                  <td>{key}</td>
                                  <td>{props.medicalSchoolDetails.demographics[key].applicants.men}</td>
                                  <td>{props.medicalSchoolDetails.demographics[key].applicants.women}</td>
                                  <td>{props.medicalSchoolDetails.demographics[key]["First-Time, First-Year Enrollees"].men}</td>
                                  <td>{props.medicalSchoolDetails.demographics[key]["First-Time, First-Year Enrollees"].women}</td>
                            </tr> : ""
                          ))}

                          <tr>
                            
                            <td>Total Applicants</td>
                            <td>{props.medicalSchoolDetails.demographics["Total Applicants"].applicants.men}</td>
                            <td>{props.medicalSchoolDetails.demographics["Total Applicants"].applicants.women}</td>
                            <td>{props.medicalSchoolDetails.demographics["Total Applicants"]["First-Time, First-Year Enrollees"].men}</td>
                            <td>{props.medicalSchoolDetails.demographics["Total Applicants"]["First-Time, First-Year Enrollees"].women}</td>
                          </tr>
                        </tbody>
                    </Table>
                  </Col>
                  : ""
                }

                {Object.keys(props.medicalSchoolDetails.listPredentalPrograms).length !== 0  ?
                  <Col md="12 mt-3">
                    <label className="font-weight-bolder">PREDENTAL PROGRAMS</label>
                    <pre style={{whiteSpace:"pre-wrap",wordWrap:"break-word"}}>{props.medicalSchoolDetails.listPredentalPrograms.listOfPredentalPrograms}</pre>
                    <label className="font-weight-bolder text-primary">For more information, visit:</label>
                    <ul>
                      {props.medicalSchoolDetails.listPredentalPrograms.moreInformation.map((value, key) => {
                        return <li><a href={value}>{value}</a></li>
                      })}
                      
                    </ul>
                  </Col>
                  : ""
                }

                
              </Row>
              
            </ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick={toggleViewSchoolOtherDetails}>
                Done
              </Button> */}
            </ModalFooter>
          </Modal>

    </Row>
  )
}

const mapStateToProps = state => {
  return {
    userDetails : state.loginAuth.userDetails,
    createSelectSchoolForSecondaryEssays : state.createSelectSchoolForSecondaryEssays,
    adminUser : state.adminUser

  }
}

export default connect(mapStateToProps)(SchoolCard)