export const CREATE_LTP_REQUEST = 'CREATE_LTP_REQUEST';
export const CREATE_LTP_SUCCESS = 'CREATE_LTP_SUCCESS';
export const CREATE_LTP_FAILURE = 'CREATE_LTP_FAILURE';
export const CREATE_LTP_RESET = 'CREATE_LTP_RESET';

export const FETCH_LTP_REQUEST = 'FETCH_LTP_REQUEST';
export const FETCH_LTP_SUCCESS = 'FETCH_LTP_SUCCESS';
export const FETCH_LTP_FAILURE = 'FETCH_LTP_FAILURE';

export const DELETE_LTP_REQUEST = 'DELETE_LTP_REQUEST';
export const DELETE_LTP_SUCCESS = 'DELETE_LTP_SUCCESS';
export const DELETE_LTP_FAILURE = 'DELETE_LTP_FAILURE';
export const DELETE_LTP_RESET = 'DELETE_LTP_RESET';

