import React, { useState, useEffect } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";
import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { cancelSubscription, resumeSubscription, cancelSubscriptionReset, resumeSubscriptionReset, fetchDetailsSubscription, manageSubscription } from 'redux/Payments/PaymentsActions';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ManageSubscriptionModal = (props) => {

    const history = useHistory()

    let modalManageSubscription = props.modalManageSubscription;
    let toggleManageSubscription = props.toggleManageSubscription;

    const dispatch = useDispatch()

    const handleCancelSubscription = (e) =>{
        e.preventDefault();
        

        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to cancel the subscription?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(cancelSubscription({subsId : props.userDetails.subsId}));
        
            }
        })

    }

    const handleResumeSubscription = (e) =>{
        e.preventDefault();

        if(props.fetchDetailsSubscription.fetchdetailssubscription[0].status == "canceled"){
            dispatch(manageSubscription(!props.manageSubscriptionModalStatus.status));
            history.push('/client/plans')
        }
        else{
            dispatch(resumeSubscription({subsId : props.userDetails.subsId, email : props.userDetails. email}));
        }
        
        
    }


    useEffect(() => {

        fetchSubscriptionDetails()


    }, [])


    const [status , setStatus] = useState('')
    const [periodEnd , setPeriodEnd] = useState('')
    const [textPeriod , setTextPeriod] = useState('')
    const [textPrice , setTextPrice] = useState('')
    const [textPlan , setTextPlan] = useState('')

    const [lifetime , setLifetime] = useState(true)
    const fetchSubscriptionDetails = () => {

        if(props.fetchDetailsSubscription.fetchdetailssubscription.length){
            
            const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]
            
            const timestamp = subsDetails.current_period_end * 1000;  // Convert seconds to milliseconds
            const date = new Date(timestamp);

            // Format the date
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

            setPeriodEnd(formattedDate)

            if(subsDetails.plan.id == "predentalTrackerProPlanMonthly"){
                setTextPlan('Pro Plan Monthly Subscription');
                setTextPrice('$15.00 per month')
                setLifetime(false)
            }
            else if(subsDetails.plan.id == "predentalTrackerProPlanYearly"){
                setTextPlan('Pro Plan Yearly Subscription');
                setTextPrice('$150.00 per year')
                setLifetime(false)
            }
            else{
                setTextPlan('Pro Plan Lifetime Subscription');
                setTextPrice('$297 one-time')
                setLifetime(true)
            }

            if(subsDetails.status == "active"){
                
                
                if(subsDetails.cancel_at_period_end){
                    setStatus('canceled')
                    setTextPeriod('Your plan will be canceled on')
                }
                else{
                    setStatus('active')
                    setTextPeriod('Your next bill is scheduled for')
                }

            }
            else{
                const date = new Date();
                const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                

                // if(secondsSinceEpoch > subsDetails.cancel_at_period_end){
                //     setStatus('canceled')
                //     setTextPeriod('Your plan was canceled on')
                // }
                // else{
                    
                //     setStatus('canceled')
                //     setTextPeriod('Your plan will be canceled on')
                    
                // }

                if(subsDetails.cancel_at_period_end){
                    if(secondsSinceEpoch > subsDetails.canceled_at){
                        setStatus('canceled')
                        setTextPeriod('Your plan was canceled on')
                    }
                    else{
                        setStatus('canceled')
                        setTextPeriod('Your plan will be canceled on')
                    }
                  }
                  else{
                    setStatus('canceled')
                    setTextPeriod('Your plan was canceled on')
                  }
                
            }


        }
        
    }

    useEffect(() => {
        fetchSubscriptionDetails()
    }, [props.fetchDetailsSubscription])
    

    useEffect(() => {
      

        if(props.cancelSubscription.loading){

            Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
                
              }
            });
        
        }
        else{

            if(props.cancelSubscription.cancelsubscription.length && typeof props.cancelSubscription.error === "undefined"){
                
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully cancel subscription!',
                    icon : 'success',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    dispatch(fetchDetailsSubscription({ subsId : props.cancelSubscription.cancelsubscription[0].id }))
                    dispatch(cancelSubscriptionReset());
                })
            
            }
        
            else if(typeof props.cancelSubscription.error !== "undefined"){
    
                Swal.fire({
                    title:'Failed!',
                    text:'Failed to cancel subscription!',
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(cancelSubscriptionReset());
                })    
                    
            
            }
        }

    }, [props.cancelSubscription])

    useEffect(() => {
      

        if(props.resumeSubscription.loading){
            
            Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
                
              }
            });
        
        }
        else{

            if(props.resumeSubscription.resumesubscription.length && typeof props.resumeSubscription.error === "undefined"){
                
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully resume subscription!',
                    icon : 'success',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    dispatch(fetchDetailsSubscription({ subsId : props.resumeSubscription.resumesubscription[0].id }))
                    dispatch(resumeSubscriptionReset());
                })
            
            }
        
            else if(typeof props.resumeSubscription.error !== "undefined"){
    
                Swal.fire({
                    title:'Failed!',
                    text:'Failed to resume subscription!',
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(resumeSubscriptionReset());
                })    
                    
            
            }
        }

    }, [props.resumeSubscription])
    
    
    

    return (
        <div>
        
            <Modal className="modal-dialog-centered" size="lg" isOpen={modalManageSubscription}  toggle={toggleManageSubscription}>
                
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Manage Subscription</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            <h6 className="heading-small text-muted mb-4">
                                Current Plan
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <Badge color={status == "active" ? "success" : "default"} className='mb-2'>{status}</Badge>
                                        <h2>{textPlan}</h2>
                                        <h1 className='mb-2'>{textPrice}</h1>
                                        <p>{textPeriod} {periodEnd}</p>
                                        {!lifetime ? 
                                        <button className={status == "active" ? "btn btn-danger" : "btn btn-primary"} onClick={e => status == "active" ? handleCancelSubscription(e) : handleResumeSubscription(e)}>{status == "active" ? "Cancel" : "Renew"} Subscription</button>
                                        :""
                                        }
                                    </Col>
                                </Row>
                            </div>  
                            {/* <hr className="my-4" />    
                            <h6 className="heading-small text-muted mb-4">
                                Payment Method
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        
                                        <h2>Test</h2>
                                        <h1>Test</h1>
                                    </Col>
                                </Row>
                            </div>             
                            <hr className="my-4" />    
                            <h6 className="heading-small text-muted mb-4">
                                Billing Information
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        
                                        <h2>Test</h2>
                                        <h1>Test</h1>
                                    </Col>
                                </Row>
                            </div>       
                            <hr className="my-4" />    
                            <h6 className="heading-small text-muted mb-4">
                                Invoice History
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        
                                        <h2>Test</h2>
                                        <h1>Test</h1>
                                    </Col>
                                </Row>
                            </div>                             */}
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                   
                </ModalFooter>
                
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
      userDetails : state.loginAuth.userDetails,
      fetchDetailsSubscription : state.fetchDetailsSubscription,
      cancelSubscription : state.cancelSubscription,
      resumeSubscription : state.resumeSubscription,
      manageSubscriptionModalStatus : state.manageSubscriptionModalStatus,
    }
}

export default connect(mapStateToProps)(ManageSubscriptionModal)